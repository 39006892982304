import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import clsx from 'clsx';

import { IGuestModalProps } from './GuestProfileModal.types';
import Modal from '../Modal/Modal';
import ModalContent from '../../atoms/ModalContent/ModalContent';
import PhoneNumberInput from '../PhoneNumberInput/PhoneNumberInput';
import { TGuestProfileFormValues } from '../../../../types/IGuestFormValues';
import { GuestProfileFormSchema } from '../../../../schema/FormsSchemas';
import Input from '../Input/Input';
import {
  reduxStatus,
  RESERVATION_LANGUAGES,
} from '../../../../constants/constants';
import ReservationLanguageSelector from '../ReservationLanguageSelector/ReservationLanguageSelector';
import TextArea from '../TextArea/TextArea';
import ButtonWithIcon from '../ButtonWithIcon/ButtonWithIcon';
import TrashIcon from '../../../../assets/icons/TrashIcon';
import Button from '../Button/Button';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import {
  createClientThunk,
  updateClientThunk,
} from '../../../../redux/thunks/guestBookThunk';
import { TCreateClient } from '../../../../types/GuestBook';
import InputLabel from '../../atoms/InputLabel/InputLabel';
import TagsDropdown from '../../atoms/TagsDropdown/TagsDropdown';

const GuestProfileModal: FC<IGuestModalProps> = ({
  visible,
  isEdit,
  onClose,
  showDeleteClientModal,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.user.user);

  const singleGuest = useAppSelector((state) => state.guestBook.singleGuest);

  const createGuestStatus = useAppSelector(
    (state) => state.guestBook.createGuestStatus,
  );

  const updateGuestStatus = useAppSelector(
    (state) => state.guestBook.updateGuestStatus,
  );

  const globalTags = useAppSelector((state) => state.settings.tags);

  const {
    control,
    handleSubmit,
    clearErrors,
    reset,
    setValue,
    formState: { errors },
  } = useForm<TGuestProfileFormValues>({
    resolver: zodResolver(GuestProfileFormSchema),
  });

  const onSubmit: SubmitHandler<TGuestProfileFormValues> = ({
    name,
    notes,
    phone,
    prefferedLanguage,
    companyName,
    email,
    position,
    tags,
  }) => {
    if (!user) return;
    const data: TCreateClient = {
      firstName: name.split(' ')[0],
      lastName: name.split(' ')[1],
      phoneNumber: phone,
      tags,
      additionalInfo: notes,
      company: companyName,
      email,
      language: prefferedLanguage.value,
      position,
      callback: onClose,
    };

    if (isEdit && singleGuest) {
      const editData = { id: singleGuest.id, ...data };
      dispatch(updateClientThunk(editData));
    } else {
      dispatch(createClientThunk(data));
    }
  };

  useEffect(() => {
    if (!visible) {
      clearErrors();
      reset();
    }
  }, [visible, clearErrors, reset]);

  useEffect(() => {
    if (isEdit && singleGuest && visible) {
      setValue('name', `${singleGuest.firstName} ${singleGuest.lastName}`);
      setValue('phone', singleGuest.phoneNumber);
      if (singleGuest.email) {
        setValue('email', singleGuest.email);
      }
      if (singleGuest.position) {
        setValue('position', singleGuest.position);
      }
      if (singleGuest.company) {
        setValue('companyName', singleGuest.company);
      }
      if (singleGuest.language) {
        const lang = RESERVATION_LANGUAGES.find(
          (language) => language.value === singleGuest.language,
        );
        if (lang) setValue('prefferedLanguage', lang);
      }
      if (singleGuest.additionalInfo) {
        setValue('notes', singleGuest.additionalInfo);
      }
      if (singleGuest.tags.length > 0) {
        setValue('tags', singleGuest.tags);
      }
    }
  }, [visible, singleGuest, isEdit, setValue]);

  return (
    <Modal
      title={isEdit ? t('messages.editProfile') : t('messages.addProfile')}
      visible={visible}
      onClose={onClose}
    >
      <form
        className="flex max-w-[744px] flex-col gap-2 pt-4"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <ModalContent>
          <Controller
            control={control}
            name="name"
            defaultValue=""
            render={({ field: { value, name, onChange } }) => (
              <Input
                name={name}
                type="text"
                variant="secondary"
                labelText={t('messages.nameAndSurname')}
                placeholder={t('messages.typeNameAndSurnameOfClient')}
                value={value}
                onChange={onChange}
                error={errors.name?.message}
                maxLength={256}
              />
            )}
          />
          <div className="flex flex-col gap-4 md:flex-1 md:flex-row md:gap-4">
            <Controller
              control={control}
              name="phone"
              defaultValue=""
              render={({ field: { value, onChange, name } }) => (
                <div className="min-h-[100px] w-full">
                  <PhoneNumberInput
                    name={name}
                    labelText={t('messages.phoneNumber')}
                    placeholder={t('messages.typePhoneNumber')}
                    value={value}
                    onChange={onChange}
                    error={errors.phone?.message}
                  />
                </div>
              )}
            />
            <div className="flex gap-2 md:min-w-[338px]">
              <Controller
                control={control}
                name="email"
                defaultValue=""
                render={({ field: { value, name, onChange } }) => (
                  <Input
                    name={name}
                    type="text"
                    variant="secondary"
                    labelText={t('messages.emailOptional')}
                    placeholder={t('messages.typeEmailAddress')}
                    value={value}
                    onChange={onChange}
                    error={errors.email?.message}
                    maxLength={256}
                  />
                )}
              />
            </div>
          </div>
          <div className="flex flex-col gap-4 md:flex-1 md:flex-row md:gap-4">
            <Controller
              control={control}
              name="position"
              defaultValue=""
              render={({ field: { value, onChange, name } }) => (
                <div className="min-h-[100px] w-full">
                  <Input
                    name={name}
                    type="text"
                    variant="secondary"
                    labelText={t('messages.professionOptional')}
                    placeholder={t('messages.typeProfession')}
                    value={value}
                    onChange={onChange}
                    error={errors.position?.message}
                    maxLength={256}
                  />
                </div>
              )}
            />
            <Controller
              control={control}
              name="companyName"
              defaultValue=""
              render={({ field: { value, onChange, name } }) => (
                <div className="min-h-[100px] w-full">
                  <Input
                    name={name}
                    type="text"
                    variant="secondary"
                    labelText={t('messages.companyNameOptional')}
                    placeholder={t('messages.typeCompanyName')}
                    value={value}
                    onChange={onChange}
                    error={errors.companyName?.message}
                    maxLength={256}
                  />
                </div>
              )}
            />
          </div>
          <Controller
            control={control}
            name="tags"
            defaultValue={[]}
            render={({ field: { value, name, onChange } }) => (
              <div className="mb-6 flex h-auto flex-col">
                <InputLabel
                  text={t('messages.tagsOptional')}
                  htmlFor={name}
                  variant="secondary"
                />
                <div className="flex flex-col gap-2">
                  <TagsDropdown
                    options={globalTags}
                    selectedOptions={value}
                    onChange={onChange}
                  />
                </div>
              </div>
            )}
          />
          <Controller
            control={control}
            name="prefferedLanguage"
            defaultValue={RESERVATION_LANGUAGES[0]}
            render={({ field: { value, onChange } }) => (
              <div className="flex min-h-[100px] flex-col gap-2">
                <span className="text-sm text-white">
                  {t('messages.preferredLanguageOfTheBooker')}
                </span>
                <ReservationLanguageSelector
                  handleLanguageChange={onChange}
                  selectedLanguage={value}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name="notes"
            defaultValue=""
            render={({ field: { value, name, onChange } }) => (
              <div className="min-h-[170px]">
                <TextArea
                  name={name}
                  variant="secondary"
                  labelText={t('messages.notesOptional')}
                  placeholder={t('messages.typeNotes')}
                  value={value}
                  onChange={onChange}
                  height={3}
                  maxLength={500}
                />
              </div>
            )}
          />
        </ModalContent>
        <div
          className={clsx(
            'mt-2 flex gap-4',
            isEdit ? 'justify-between' : 'justify-end',
          )}
        >
          {isEdit && (
            <ButtonWithIcon
              type="button"
              text={t('messages.deleteProfile')}
              variant="secondary"
              icon={TrashIcon}
              isDeleteButton
              onClick={showDeleteClientModal}
            />
          )}
          <Button
            type="submit"
            text={t('messages.saveChanges')}
            variant="secondary"
            isLoading={
              isEdit
                ? updateGuestStatus === reduxStatus.loading
                : createGuestStatus === reduxStatus.loading
            }
            isDisabled={
              updateGuestStatus === reduxStatus.loading ||
              createGuestStatus === reduxStatus.loading
            }
          />
        </div>
      </form>
    </Modal>
  );
};

export default GuestProfileModal;

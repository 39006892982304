/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */

import { get, isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useSortBy, useTable, HeaderGroup } from 'react-table';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import FilterColumnIcon from '../../../../assets/icons/FilterColumnIcon';
import { ITabletTableProps } from '../Table/Table.types';

const TableTablet = ({
  columns,
  data = [],
  setSortField,
  setSortDirection,
  isTheadDisabled,
  columnWidth = 110,
}: ITabletTableProps) => {
  const { getTableProps, headerGroups } = useTable(
    {
      columns,
      data,
      manualSortBy: true,
    } as any,
    useSortBy,
  );

  const actualSortedColumn = headerGroups[0]?.headers?.find(
    ({ isSorted }: any) => isSorted === true,
  );

  const { t } = useTranslation();

  const accessorFieldThatHaveToAvoid = [
    'description',
    'discount',
    'shifts',
    'isOpen',
    'interested',
    'used',
    'status',
  ];

  useEffect(() => {
    if (!setSortField || !setSortDirection) return;

    if (actualSortedColumn && 'isSortedDesc' in actualSortedColumn) {
      if (accessorFieldThatHaveToAvoid.includes(actualSortedColumn?.id)) return;
      setSortField(actualSortedColumn.id);
      if (actualSortedColumn?.isSortedDesc) {
        setSortDirection('desc');
      } else {
        setSortDirection('asc');
      }
    } else {
      setSortDirection('');
      setSortField('');
    }

    // eslint-disable-next-line
  }, [
    actualSortedColumn,
    //  eslint-disable-next-line
    // @ts-ignore
    actualSortedColumn?.isSortedDesc,
    setSortDirection,
    setSortField,
  ]);

  return (
    <div className="absolute flex w-full flex-col">
      {!isTheadDisabled && (
        <table
          {...getTableProps()}
          className="sticky top-0 h-max w-full border-separate border-spacing-0 bg-brand-50 text-left text-sm"
        >
          <thead className="">
            {headerGroups.map((headerGroup: HeaderGroup, index1: number) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                key={`${headerGroup.id}-${index1}`}
              >
                {headerGroup.headers.map((column: any, index2: number) => {
                  return (
                    <th
                      className={clsx(
                        'z-[1] overflow-hidden whitespace-nowrap border-b-[1px] border-solid border-brand-700 bg-brand-50 py-2 uppercase text-brand-1700',
                        !accessorFieldThatHaveToAvoid.includes(column.id)
                          ? 'cursor-pointer'
                          : 'no-cursor-th',
                      )}
                      key={`${column.id}-${index2}`}
                      {...(data.length &&
                        column.getHeaderProps(
                          column.getSortByToggleProps({
                            title: !accessorFieldThatHaveToAvoid.includes(
                              column.id,
                            )
                              ? t('messages.clickToSortSelectedColumn')
                              : '',
                          }),
                        ))}
                    >
                      <div className="flex items-center">
                        <div className="mt-auto">{column.render('Header')}</div>
                        <div className={clsx('ml-2 flex')}>
                          {!accessorFieldThatHaveToAvoid.includes(
                            column.id,
                          ) && <FilterColumnIcon />}
                        </div>
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
        </table>
      )}
      <div className="mt-6">
        {!isEmpty(data) && (
          <div className="flex w-full flex-col gap-4">
            {data.map((row: any, index: number) => {
              const firstElement = columns[0];
              const remainingElements = columns.slice(1);
              const firstHalf = remainingElements.slice(0, 3);
              const secondHalf = remainingElements.slice(
                3,
                remainingElements.length,
              );

              return (
                <div
                  key={`${row.id}-${index}`}
                  className="flex flex-col gap-1 border-b-[1px] border-solid border-brand-700 pb-4"
                >
                  <div className="flex w-full items-center gap-1 pr-4">
                    <span className="block w-full truncate">
                      {get(row, firstElement.accessor)}
                    </span>
                  </div>

                  <div className="mt-2 flex">
                    <div className="flex w-1/2 flex-col gap-2 pr-4">
                      {firstHalf.map((col) => (
                        <div
                          className="flex items-center gap-1"
                          key={`${row.id}-${col.accessor}`}
                        >
                          <span
                            className={clsx(
                              'text-xs font-semibold uppercase text-brand-1700',
                            )}
                            style={{ minWidth: `${columnWidth}px` }}
                          >
                            {col.Header}
                          </span>
                          <span className="block w-full truncate">
                            {get(row, col.accessor)}
                          </span>
                        </div>
                      ))}
                    </div>

                    <div className="flex w-1/2 flex-col gap-1 border-l border-brand-700 pl-4">
                      {secondHalf.map((col) => (
                        <div
                          className="flex items-center gap-1"
                          key={`${row.id}-${col.accessor}`}
                        >
                          <span
                            className={clsx(
                              'text-xs font-semibold uppercase text-brand-1700',
                            )}
                            style={{ minWidth: `${columnWidth}px` }}
                          >
                            {col.Header}
                          </span>
                          <span className="block w-full truncate">
                            {get(row, col.accessor)}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default TableTablet;

import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useForm,
  Controller,
  SubmitHandler,
  FormProvider,
} from 'react-hook-form';
import { TimeField } from '@mui/x-date-pickers';
import { addDays, format, isAfter, isBefore } from 'date-fns';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';

import Modal from '../../molecules/Modal/Modal';
import { IEventModalProps } from './EventModal.types';
import ImageUpload from '../../molecules/ImageUpload/ImageUpload';
import Input from '../../molecules/Input/Input';
import ButtonWithIcon from '../../molecules/ButtonWithIcon/ButtonWithIcon';
import Button from '../../molecules/Button/Button';
import TrashIcon from '../../../../assets/icons/TrashIcon';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import {
  INITIAL_TIMEZONE,
  EVENT_CATEGORIES,
  reduxStatus,
  RECURRENCE_PERIODS,
  DEFAULT_WEEEK_DAYS,
  daysOfWeek,
  DAYS_OF_WEEK,
  MONTHLY_RECCURENCE_OPTIONS,
  MONTHS,
} from '../../../../constants/constants';
import {
  areArraysEqual,
  getZonedTimeFromIsoDate,
} from '../../../../functions/functions';
import InputLabel from '../../atoms/InputLabel/InputLabel';
import InputDate from '../../molecules/InputDate/InputDate';
import {
  IEventFormValues,
  TEventFormValues,
} from '../../../../types/IEventFormValues';
import {
  clearEventStartDate,
  clearRecurrenceData,
  setCurrentEventId,
  setEventStartDate,
} from '../../../../redux/features/eventSlice';
import { ICreateOrUpdateEventThunk } from '../../../../types/Event';
import {
  createEventThunk,
  updateEventThunk,
} from '../../../../redux/thunks/eventThunk';
import TextArea from '../../molecules/TextArea/TextArea';
import { urlRegex } from '../../../../constants/regex';
import InputDropdown from '../../atoms/InputDropdown/InputDropdown';
import ModalContent from '../../atoms/ModalContent/ModalContent';
import SimpleDropdown from '../../atoms/SimpleDropdown/SimpleDropdown';
import useModal from '../../../../hooks/useModal';
import RecurrenceModal from '../../molecules/RecurrenceModal/RecurrenceModal';
import DropdownMenuDefaultList from '../../molecules/DropdownMenuDefaultList/DropdownMenuDefaultList';
import { EventFormSchema } from '../../../../schema/FormsSchemas';

const EventModal: FC<IEventModalProps> = ({
  visible,
  onClose,
  isEdit,
  showDeleteEventModal,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { pathname } = useLocation();

  const {
    visible: isRecurrenceModalVisible,
    showModal: showRecurrenceModal,
    hideModal: hideRecurrenceModal,
  } = useModal();

  const [selectedPeriod, setSelectedPeriod] = useState<string>('');

  const user = useAppSelector((state) => state.user.user);

  const placeTimezone = useAppSelector((state) => state.settings.timezone);

  const createEventStatus = useAppSelector(
    (state) => state.event.createEventStatus,
  );

  const updateEventStatus = useAppSelector(
    (state) => state.event.updateEventStatus,
  );

  const singleEvent = useAppSelector((state) => state.event.event);

  const deleteEventStatus = useAppSelector(
    (store) => store.event.deleteEventStatus,
  );

  const contractStartDate = useAppSelector(
    (state) => state.settings.contractStartDate,
  );

  const contractEndDate = useAppSelector(
    (state) => state.settings.contractEndDate,
  );

  const recurrenceData = useAppSelector((state) => state.event.recurrenceData);

  const eventStartDate = useAppSelector((state) => state.event.eventStartDate);

  useEffect(() => {
    if (deleteEventStatus === reduxStatus.success) {
      onClose();
    }
  }, [deleteEventStatus, onClose]);

  const methods = useForm<IEventFormValues>();

  const {
    control,
    handleSubmit,
    clearErrors,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = methods;

  const watchedStartDate = watch('startDate');
  const watchedEndDate = watch('endDate');
  const watchedStartTime = watch('startTime');
  const watchedEndTime = watch('endTime');

  const today = new Date();

  const minDate: Date =
    contractStartDate && isBefore(today, new Date(contractStartDate))
      ? new Date(contractStartDate)
      : today;
  const maxDate: Date = useMemo(
    () => (contractEndDate && new Date(contractEndDate)) || today,
    [contractEndDate],
  );

  const datePickerWatchedStartDate = watchedStartDate;

  const handleShowRecurrenceModal = (period: string) => {
    showRecurrenceModal();
    setSelectedPeriod(period);
  };

  const removePrefixFromTheLink = (value: string) => {
    if (value.startsWith('https://')) {
      return value.substring(8);
    }
    if (value.startsWith('http://')) {
      return value.substring(7);
    }
    return value;
  };

  const handleShowDeleteModal = () => {
    if (!singleEvent) return;
    dispatch(setCurrentEventId(singleEvent.id));
    showDeleteEventModal();
  };

  const validateDateTime = (): boolean => {
    if (
      !watchedEndTime ||
      !watchedStartTime ||
      !watchedEndDate ||
      !watchedStartDate
    ) {
      return true;
    }

    const startDateTime = new Date(
      `${format(watchedStartDate, 'yyyy-MM-dd')} ${format(
        watchedStartTime,
        'HH:mm:ss',
      )}`,
    );
    const endDateTime = new Date(
      `${format(watchedEndDate, 'yyyy-MM-dd')} ${format(
        watchedEndTime,
        'HH:mm:ss',
      )}`,
    );

    if (endDateTime < startDateTime) {
      toast.error(t('messages.invalidEndTime'));
      return false;
    }
    return true;
  };

  const onSubmit: SubmitHandler<IEventFormValues> = ({
    name,
    description,
    image,
    startDate,
    startTime,
    endDate,
    endTime,
    recurrenceType,
    category,
    ticketUrl,
  }) => {
    if (!user?.placeId) return;
    if (!validateDateTime()) return;

    const input: ICreateOrUpdateEventThunk = {
      ...(isEdit && { id: singleEvent?.id }),
      placeId: user.placeId,
      name,
      description,
      image,
      oldImage: isEdit ? singleEvent?.image : undefined,
      startDate:
        recurrenceData && recurrenceType !== 'doesNotRepeat'
          ? eventStartDate
          : startDate,
      startTime,
      endDate,
      endTime,
      isRecurring:
        recurrenceType === 'doesNotRepeat' ? false : !!recurrenceData,
      recurrenceType:
        recurrenceType !== 'doesNotRepeat'
          ? recurrenceData?.recurrenceType
          : null,
      interval:
        recurrenceType !== 'doesNotRepeat' ? recurrenceData?.interval : 1,
      endRecurrenceDate:
        recurrenceType !== 'doesNotRepeat'
          ? recurrenceData?.recurrenceEndDate
          : null,
      daysOfWeek:
        recurrenceType !== 'doesNotRepeat' ? recurrenceData?.daysOfWeek : [],
      dayOfWeek:
        recurrenceType !== 'doesNotRepeat' ? recurrenceData?.dayOfWeek : null,
      weekOfMonth:
        recurrenceType !== 'doesNotRepeat' ? recurrenceData?.weekOfMonth : null,
      dayOfMonth:
        recurrenceType !== 'doesNotRepeat' ? recurrenceData?.dayOfMonth : null,
      monthOfYear:
        recurrenceType !== 'doesNotRepeat' ? recurrenceData?.monthOfYear : null,
      category,
      ticketUrl: ticketUrl !== '' ? `https://${ticketUrl}` : ticketUrl,
      timezone: placeTimezone || INITIAL_TIMEZONE,
      path: pathname,
      callback: () => {
        onClose();
      },
    };

    if (isEdit && singleEvent) {
      dispatch(updateEventThunk(input));
    } else if (image instanceof File) {
      dispatch(createEventThunk(input));
    }
  };

  useEffect(() => {
    if (visible && recurrenceData) {
      dispatch(clearRecurrenceData());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, visible]);

  useEffect(() => {
    clearErrors();
    reset();
    dispatch(clearEventStartDate());
  }, [visible, clearErrors, reset, setValue, dispatch]);

  useEffect(() => {
    if (isEdit && singleEvent && visible) {
      setValue('image', singleEvent.image);
      setValue('name', singleEvent.name);
      setValue(
        'recurrenceType',
        singleEvent.recurrenceType || RECURRENCE_PERIODS[0],
      );
      setValue('category', singleEvent.category || '');
      setValue(
        'ticketUrl',
        singleEvent.ticketUrl
          ? removePrefixFromTheLink(singleEvent.ticketUrl)
          : '',
      );
      setValue(
        'description',
        singleEvent.description ? singleEvent.description : '',
      );
      setValue(
        'startDate',
        getZonedTimeFromIsoDate(singleEvent.startDate, placeTimezone),
      );
      setValue(
        'startTime',
        getZonedTimeFromIsoDate(singleEvent.startDate, placeTimezone),
      );
      if (singleEvent.endDate) {
        setValue(
          'endDate',
          getZonedTimeFromIsoDate(singleEvent.endDate, placeTimezone),
        );
      }
      if (singleEvent.endDate) {
        setValue(
          'endTime',
          getZonedTimeFromIsoDate(singleEvent.endDate, placeTimezone),
        );
      }
      dispatch(
        setEventStartDate(
          getZonedTimeFromIsoDate(singleEvent.startDate, placeTimezone),
        ),
      );
    }
  }, [visible, isEdit, singleEvent, placeTimezone, setValue, dispatch]);

  useEffect(() => {
    if (!watchedEndDate || !watchedStartDate || !maxDate) return;
    if (
      isAfter(
        watchedStartDate.setHours(0, 0, 0, 0),
        watchedEndDate.setHours(0, 0, 0, 0),
      ) &&
      !isAfter(watchedStartDate, maxDate)
    ) {
      const oneWeekLater = addDays(watchedStartDate, 7);

      setValue(
        'endDate',
        !isAfter(oneWeekLater, maxDate) ? oneWeekLater : maxDate,
      );
    }
  }, [watchedEndDate, watchedStartDate, maxDate, setValue]);

  useEffect(() => {
    setValue('startDate', eventStartDate);
  }, [eventStartDate, setValue, getValues]);

  const onStartDateChange = (date: Date) => {
    dispatch(setEventStartDate(date));
  };

  const getTimeFieldClassNames = (field: 'start' | 'end') => {
    if (field === 'start') {
      if (errors.startTime?.message && watchedStartDate) {
        return 'error-input clock-time-input';
      }

      return 'time-input clock-time-input';
    }

    if (errors.endTime?.message && watchedEndDate) {
      return 'error-input clock-time-input';
    }
    if (watchedEndDate) {
      return 'time-input clock-time-input';
    }
    return 'disabled-clock-time-input';
  };

  const getRecurrenceCopy = (period: string): string => {
    const formattedStartDate = eventStartDate
      ? format(eventStartDate, 'dd.MM.yyyy')
      : '';

    const formattedEndDate = recurrenceData?.recurrenceEndDate
      ? format(recurrenceData.recurrenceEndDate, 'dd.MM.yyyy')
      : '';

    const interval = recurrenceData?.interval ?? 1;

    switch (period) {
      case 'doesNotRepeat':
        return t('messages.recurrences.doesNotRepeat');
      case 'daily': {
        if (interval === 1) {
          return t('messages.recurrences.daily.default', {
            startDate: formattedStartDate,
            endDate: formattedEndDate,
          });
        }

        return t('messages.recurrences.daily.everyFewDays', {
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          interval,
        });
      }
      case 'weekly': {
        const selectedDaysOfWeekNumbers: number[] = (
          recurrenceData?.daysOfWeek ?? []
        ).map((day) => Number(day));

        const isDefaultWeekdays = areArraysEqual(
          selectedDaysOfWeekNumbers,
          DEFAULT_WEEEK_DAYS,
        );

        if (!isDefaultWeekdays || interval !== 1) {
          const dayLabelMap = daysOfWeek.reduce<Record<number, string>>(
            (acc, day) => {
              acc[day.value] = t(`messages.${day.label}`);
              return acc;
            },
            {},
          );

          const selectedWeekDaysNames =
            selectedDaysOfWeekNumbers
              .map((day) => dayLabelMap[day])
              .filter(Boolean)
              .join(', ') || '';

          if (interval === 1) {
            return t('messages.recurrences.weekly.default', {
              startDate: formattedStartDate,
              endDate: formattedEndDate,
              weekDays: selectedWeekDaysNames.toLocaleLowerCase(),
            });
          }

          return t('messages.recurrences.weekly.everyFewWeeks', {
            startDate: formattedStartDate,
            endDate: formattedEndDate,
            interval,
            weekDays: selectedWeekDaysNames.toLocaleLowerCase(),
          });
        }

        return t('messages.recurrences.weekdays', {
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        });
      }
      case 'monthly': {
        const weekOfMonthLabel = (weekOfMonth: number) => {
          const option = MONTHLY_RECCURENCE_OPTIONS.find(
            (opt) => Number(opt.value) === weekOfMonth,
          );
          return option ? t(`messages.${option.label}`) : '';
        };

        if (recurrenceData?.dayOfMonth) {
          if (interval === 1) {
            return t('messages.recurrences.monthly.dayOfMonth', {
              startDate: formattedStartDate,
              endDate: formattedEndDate,
              monthDay: recurrenceData.dayOfMonth,
            });
          }
          return t('messages.recurrences.monthly.dayOfMonthWithInterval', {
            startDate: formattedStartDate,
            endDate: formattedEndDate,
            interval,
            monthDay: recurrenceData.dayOfMonth,
          });
        }

        if (
          recurrenceData?.weekOfMonth != null &&
          recurrenceData?.dayOfWeek != null
        ) {
          const weekDayLabel = t(
            `messages.${DAYS_OF_WEEK[Number(recurrenceData.dayOfWeek)]}`,
          ).toLocaleLowerCase();
          const numberWeekOfMonth = Number(recurrenceData?.weekOfMonth);

          if (interval === 1) {
            return t('messages.recurrences.monthly.dayOfWeek', {
              startDate: formattedStartDate,
              endDate: formattedEndDate,
              ordinalNumber:
                weekOfMonthLabel(numberWeekOfMonth).toLocaleLowerCase(),
              weekDay: weekDayLabel,
            });
          }

          return t('messages.recurrences.monthly.dayOfWeekInterval', {
            startDate: formattedStartDate,
            endDate: formattedEndDate,
            interval,
            ordinalNumber:
              weekOfMonthLabel(numberWeekOfMonth).toLocaleLowerCase(),
            weekDay: weekDayLabel,
          });
        }

        return '';
      }

      case 'yearly': {
        const weekOfMonthLabel = (weekOfMonth: number) => {
          const option = MONTHLY_RECCURENCE_OPTIONS.find(
            (opt) => Number(opt.value) === weekOfMonth,
          );
          return option ? t(`messages.${option.label}`) : '';
        };

        const monthLabel = t(
          `messages.${MONTHS[Number(recurrenceData?.monthOfYear) - 1]}`,
        ).toLocaleLowerCase();

        if (recurrenceData?.dayOfMonth && recurrenceData?.monthOfYear) {
          if (interval === 1) {
            return t('messages.recurrences.yearly.dayOfMonth', {
              day: recurrenceData.dayOfMonth,
              month: monthLabel,
            });
          }

          return t('messages.recurrences.yearly.dayOfMonthWithInterval', {
            day: recurrenceData.dayOfMonth,
            month: monthLabel,
            interval,
          });
        }

        if (
          recurrenceData?.weekOfMonth != null &&
          recurrenceData?.dayOfWeek != null &&
          recurrenceData?.monthOfYear
        ) {
          const weekDayLabel = t(
            `messages.${DAYS_OF_WEEK[Number(recurrenceData.dayOfWeek)]}`,
          ).toLocaleLowerCase();
          const numberWeekOfMonth = Number(recurrenceData?.weekOfMonth);

          if (interval === 1) {
            return t('messages.recurrences.yearly.dayOfWeek', {
              ordinalNumber:
                weekOfMonthLabel(numberWeekOfMonth).toLocaleLowerCase(),
              weekDay: weekDayLabel,
              month: monthLabel,
            });
          }

          return t('messages.recurrences.yearly.dayOfWeekInterval', {
            ordinalNumber:
              weekOfMonthLabel(numberWeekOfMonth).toLocaleLowerCase(),
            weekDay: weekDayLabel,
            month: monthLabel,
            interval,
          });
        }

        return '';
      }
      default:
        return '';
    }
  };

  return (
    <Modal
      title={isEdit ? t('messages.editEvent') : t('messages.addEvent')}
      visible={visible}
      onClose={onClose}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <FormProvider {...methods}>
        <form
          className="flex max-w-[744px] flex-col gap-2 pt-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <ModalContent>
            <Controller
              control={control}
              name="image"
              defaultValue=""
              rules={{
                required: {
                  value: true,
                  message: t('errorMessages.thisFieldIsRequired'),
                },
              }}
              render={({ field: { value, name, onChange } }) => (
                <ImageUpload
                  name={name}
                  labelText={t('messages.image')}
                  placeholder={t('messages.noFileSelected')}
                  value={value}
                  onChange={onChange}
                  maxFileSize={20}
                  error={errors.image?.message}
                  isImagePreviewHorizontal
                  aspect="16/9"
                />
              )}
            />
            <div className="mt-4">
              <Controller
                control={control}
                name="name"
                defaultValue=""
                rules={{
                  required: {
                    value: true,
                    message: t('errorMessages.thisFieldIsRequired'),
                  },
                }}
                render={({ field: { value, name, onChange } }) => (
                  <Input
                    name={name}
                    type="text"
                    variant="secondary"
                    labelText={t('messages.name')}
                    placeholder={t('messages.typeNameOfEvent')}
                    value={value}
                    onChange={onChange}
                    error={errors.name?.message}
                    maxLength={100}
                  />
                )}
              />
            </div>
            <div className="md:h-[100px] md:min-h-[100px] md:pb-3.5">
              <div className="mb-4 flex w-full flex-col items-start justify-between gap-4 md:mb-0 md:flex-row">
                <Controller
                  control={control}
                  name="startDate"
                  rules={{
                    required: {
                      value: true,
                      message: t('errorMessages.thisFieldIsRequired'),
                    },
                  }}
                  render={({ field: { value } }) => (
                    <InputDate
                      name="startDate"
                      labelText={t('messages.startDate')}
                      value={value}
                      onChange={(date) => {
                        onStartDateChange(date);
                      }}
                      placeholder={t('messages.typeDate')}
                      error={errors.startDate?.message}
                      minDate={minDate}
                      maxDate={maxDate}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="startTime"
                  rules={{
                    required: {
                      value: true,
                      message: t('errorMessages.thisFieldIsRequired'),
                    },
                  }}
                  render={({ field: { value, onChange } }) => (
                    <div className="flex w-full flex-1 flex-col">
                      <InputLabel
                        text={t('messages.startTime')}
                        variant="secondary"
                        htmlFor="startTime"
                      />
                      <TimeField
                        className={getTimeFieldClassNames('start')}
                        value={value}
                        onChange={onChange}
                      />
                      {errors.startTime?.message && watchedStartDate && (
                        <span className="mt-1 text-sm text-red-600">
                          {errors.startTime?.message}
                        </span>
                      )}
                    </div>
                  )}
                />
              </div>
            </div>
            <div className="md:h-[100px] md:min-h-[100px] md:pb-3.5">
              <div className="mb-4 flex w-full flex-col items-start justify-between gap-4 md:mb-0 md:flex-row">
                <Controller
                  control={control}
                  name="endDate"
                  rules={{
                    required: {
                      value: true,
                      message: t('errorMessages.thisFieldIsRequired'),
                    },
                  }}
                  render={({ field: { value, onChange } }) => (
                    <InputDate
                      name="endDate"
                      labelText={t('messages.endDate')}
                      value={value}
                      onChange={onChange}
                      placeholder={t('messages.typeDate')}
                      error={errors.endDate?.message}
                      minDate={watchedStartDate}
                      maxDate={maxDate}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="endTime"
                  rules={{
                    required: {
                      value: true,
                      message: t('errorMessages.thisFieldIsRequired'),
                    },
                  }}
                  render={({ field: { value, onChange } }) => (
                    <div className="flex w-full flex-1 flex-col">
                      <InputLabel
                        text={t('messages.endTime')}
                        variant="secondary"
                        htmlFor="endTime"
                      />
                      <TimeField
                        className={getTimeFieldClassNames('end')}
                        value={value}
                        onChange={onChange}
                      />
                      {errors.endTime?.message && watchedEndDate && (
                        <span className="mt-1 text-sm text-red-600">
                          {errors.endTime?.message}
                        </span>
                      )}
                    </div>
                  )}
                />
              </div>
            </div>
            <Controller
              control={control}
              name="recurrenceType"
              defaultValue={RECURRENCE_PERIODS[0]}
              rules={{
                required: {
                  value: true,
                  message: t('errorMessages.thisFieldIsRequired'),
                },
              }}
              render={({ field: { value, name, onChange } }) => (
                <div className="flex min-h-[100px] flex-col">
                  <InputLabel
                    text={t('messages.recurrence')}
                    htmlFor={name}
                    variant="secondary"
                  />
                  <div className="flex flex-col gap-2">
                    <SimpleDropdown
                      placeholder={t('messages.doesNotRepeat')}
                      selectedValue={value}
                      onChange={(val) => {
                        if (val === 'doesNotRepeat') {
                          onChange(val);
                        } else {
                          handleShowRecurrenceModal(val);
                        }
                      }}
                      formatLabel={getRecurrenceCopy}
                      options={RECURRENCE_PERIODS.map((period) => ({
                        label: t(`messages.${period}`),
                        value: period,
                      }))}
                      menuPortalTarget={document.body}
                      error={errors.recurrenceType?.message}
                    >
                      <DropdownMenuDefaultList />
                    </SimpleDropdown>
                  </div>
                </div>
              )}
            />
            <Controller
              control={control}
              name="category"
              defaultValue={isEdit ? singleEvent?.category : ''}
              rules={{
                required: {
                  value: true,
                  message: t('errorMessages.thisFieldIsRequired'),
                },
              }}
              render={({ field: { value, name, onChange } }) => (
                <div className="flex min-h-[100px] flex-col">
                  <InputLabel
                    text={t('messages.eventCategory')}
                    htmlFor={name}
                    variant="secondary"
                  />
                  <div className="flex flex-col gap-2">
                    <InputDropdown
                      placeholder={t('messages.chooseCategory')}
                      value={value}
                      options={EVENT_CATEGORIES.map((category) => ({
                        label: t(`messages.${category}`),
                        onClick: () => onChange(category),
                        value: category,
                      }))}
                      error={errors.category?.message}
                      variant="modal"
                      isTranslation
                      initialValueReload
                    />
                  </div>
                </div>
              )}
            />
            <Controller
              control={control}
              name="ticketUrl"
              defaultValue=""
              rules={{
                pattern: {
                  value: urlRegex,
                  message: t('errorMessages.invalidTicketUrl'),
                },
              }}
              render={({ field: { value, name, onChange } }) => (
                <Input
                  name={name}
                  type="text"
                  variant="tertiary"
                  labelText={t('messages.ticketsOptional')}
                  placeholder={t('messages.typeUrlAddress')}
                  value={value}
                  onChange={(e) => {
                    const newValue = removePrefixFromTheLink(e.target.value);
                    onChange(newValue);
                  }}
                  error={errors.ticketUrl?.message}
                  maxLength={255}
                  prefixLabel="https://"
                />
              )}
            />
            <Controller
              control={control}
              name="description"
              defaultValue=""
              render={({ field: { value, name, onChange } }) => (
                <TextArea
                  name={name}
                  variant="secondary"
                  labelText={t('messages.informationsAboutEvent')}
                  placeholder={t('messages.typeInformationsAboutThisEvent')}
                  value={value}
                  onChange={onChange}
                  maxLength={1000}
                  height={3}
                />
              )}
            />
          </ModalContent>

          <div className="mt-2 flex justify-end gap-4">
            {isEdit && (
              <ButtonWithIcon
                type="button"
                text={t('messages.delete')}
                variant="secondary"
                icon={TrashIcon}
                isDeleteButton
                onClick={handleShowDeleteModal}
              />
            )}
            <Button
              type="submit"
              text={isEdit ? t('messages.editEvent') : t('messages.addEvent')}
              variant="secondary"
              isLoading={
                isEdit
                  ? updateEventStatus === reduxStatus.loading
                  : createEventStatus === reduxStatus.loading
              }
              isDisabled={
                updateEventStatus === reduxStatus.loading ||
                createEventStatus === reduxStatus.loading
              }
            />
          </div>
        </form>
        <RecurrenceModal
          isEdit={isEdit}
          visible={isRecurrenceModalVisible}
          onClose={hideRecurrenceModal}
          selectedPeriod={selectedPeriod}
        />
      </FormProvider>
    </Modal>
  );
};

export default EventModal;

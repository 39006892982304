import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { FC } from 'react';

import EmailIcon from '../../../../assets/icons/EmailIcon';
import HandsetIcon from '../../../../assets/icons/HandsetIcon';
import PenIcon from '../../../../assets/icons/PenIcon';
import TrashIcon from '../../../../assets/icons/TrashIcon';
import ButtonWithIcon from '../ButtonWithIcon/ButtonWithIcon';
import useModal from '../../../../hooks/useModal';
import GuestProfileModal from '../GuestProfileModal/GuestProfileModal';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { deleteClientThunk } from '../../../../redux/thunks/guestBookThunk';
import { resetDeleteGuestStatus } from '../../../../redux/features/GuestBookSlice';
import { routes } from '../../../../constants/constants';
import { IGuestDetailsContactProps } from './GuestDetailsContact.types';

const GuestDetailsContact: FC<IGuestDetailsContactProps> = ({
  phoneNumber,
  email,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { guestId } = useParams();

  const {
    visible: isEditGuestModalVisible,
    showModal: showEditGuestModal,
    hideModal: hideEditGuestModal,
  } = useModal();

  const {
    visible: isDeleteClientModalVisible,
    showModal: showDeleteClientModal,
    hideModal: hideDeleteClientModal,
  } = useModal();

  const deleteGuestStatus = useAppSelector(
    (state) => state.guestBook.deleteGuestStatus,
  );

  const handleDeleteClient = () => {
    if (!guestId) return;
    dispatch(
      deleteClientThunk({
        id: guestId,
        callback: () => navigate(routes.guestBook),
      }),
    );
  };

  const handleCloseDeleteCouponModal = () => {
    hideDeleteClientModal();
    dispatch(resetDeleteGuestStatus());
  };

  const handleCallToClient = () => {
    window.location.href = `tel:${phoneNumber}`;
  };

  const handleEmailToClient = () => {
    if (!email) return;
    window.location.href = `mailto:${email}`;
  };

  return (
    <>
      <GuestProfileModal
        isEdit
        visible={isEditGuestModalVisible}
        onClose={hideEditGuestModal}
        showDeleteClientModal={showDeleteClientModal}
      />
      <ConfirmModal
        visible={isDeleteClientModalVisible}
        onClose={handleCloseDeleteCouponModal}
        questionText={t('messages.areYouSureYouWantToDeleteThisItem')}
        confirmText={t('messages.itemDeletedSuccessfully')}
        confirmAction={handleDeleteClient}
        loadingStatus={deleteGuestStatus}
      />
      {/* CONTACT */}
      <div className="flex w-full flex-col gap-6 xl:flex-row">
        <div className="flex w-full flex-col gap-4 rounded-xs border border-brand-700 p-6">
          <span className="text-lg font-semibold">{t('messages.contact')}</span>
          <div className="flex flex-col gap-4 sm:flex-row">
            <ButtonWithIcon
              type="button"
              text={t('messages.supportModule.call')}
              variant="primary"
              icon={HandsetIcon}
              onClick={handleCallToClient}
              isFullWidthOnDesktop
              isFullWidthOnMobile
            />
            <ButtonWithIcon
              type="button"
              text={t('messages.sendEmail')}
              variant="primary"
              icon={EmailIcon}
              onClick={handleEmailToClient}
              isDisabled={!email}
              isFullWidthOnDesktop
              isFullWidthOnMobile
            />
          </div>
        </div>
        <div className="flex w-full flex-col gap-4 rounded-xs border border-brand-700 p-6">
          <span className="text-lg font-semibold">
            {t('messages.profileManagement')}
          </span>
          <div className="flex flex-col gap-4 sm:flex-row">
            <ButtonWithIcon
              type="button"
              text={t('messages.edit')}
              variant="primary"
              icon={PenIcon}
              onClick={showEditGuestModal}
              isFullWidthOnDesktop
              isFullWidthOnMobile
            />
            <ButtonWithIcon
              type="button"
              text={t('messages.delete')}
              variant="primary"
              icon={TrashIcon}
              onClick={showDeleteClientModal}
              isFullWidthOnDesktop
              isFullWidthOnMobile
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default GuestDetailsContact;

import { z } from 'zod';

export const OfferSchema = z.object({
  id: z.string(),
  name: z.string(),
  published: z.boolean(),
  photo: z.string().optional(),
  categories: z.array(z.string()).optional(),
});

export const BasicOfferSchema = z.object({
  id: z.string(),
  name: z.string(),
  published: z.boolean(),
});

export const OfferCategorySchema = z.object({
  id: z.string(),
  name: z.string(),
  totalProducts: z.number(),
});

export const ListOffersResponseSchema = z.object({
  successCode: z.string(),
  data: z.any(),
});

export const UpdateOfferResponseSchema = z.object({
  successCode: z.string(),
  data: z.object({
    offers: z.array(OfferSchema),
  }),
});

export const UpdateOfferCategoriesResponseSchema = z.object({
  successCode: z.string(),
  data: z.object({
    categories: z.array(z.object({ id: z.string(), name: z.string() })),
  }),
});

export const ListOfferCategoriesSchema = z.object({
  successCode: z.string(),
  data: z.object({
    isOfferPublished: z.boolean(),
    offerCategories: z.array(
      z.object({
        id: z.string(),
        name: z.string(),
        totalProducts: z.number(),
      }),
    ),
  }),
});

export const GetOfferBasicInformationResponseSchema = z.object({
  successCode: z.string(),
  data: z.object({
    offer: OfferSchema,
  }),
});

export const SetOfferStatusSchema = z.object({
  successCode: z.string(),
  data: z.object({
    isOfferPublished: z.boolean(),
  }),
});

export const ListProductsSchema = z.object({
  successCode: z.string(),
  data: z.array(
    z.object({
      id: z.string(),
      description: z.string(),
      image: z.string(),
      name: z.string(),
      offerCategoryId: z.string(),
      placeId: z.string(),
      price: z.string(),
      quantity: z.string().optional().nullable(),
      unit: z.string().optional().nullable(),
    }),
  ),
});

export const ChangeOrderOfProductsResponseSchema = z.object({
  successCode: z.string(),
  data: z.literal(true),
});

export const CreateProductSchema = z.object({
  successCode: z.string(),
  data: z.object({
    createdAt: z.string(),
    description: z.string().optional(),
    image: z.string().optional(),
    name: z.string(),
    offerCategoryId: z.string(),
    placeId: z.string(),
    price: z.string(),
    quantity: z.string().optional(),
    unit: z.string().optional(),
    updatedAt: z.string(),
    id: z.string(),
  }),
});

export const UpdateProductSchema = z.object({
  successCode: z.string(),
  data: z.object({
    createdAt: z.string(),
    description: z.string().optional(),
    image: z.string().optional(),
    name: z.string(),
    offerCategoryId: z.string(),
    placeId: z.string(),
    price: z.string(),
    quantity: z.string().optional(),
    unit: z.string().optional(),
    updatedAt: z.string(),
    id: z.string(),
  }),
});

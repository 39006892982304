import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IOfferProduct, IOfferSliceState } from '../../types/Offer';
import {
  changeOrderOfOfferCategoriesThunk,
  changeOrderOfOffersThunk,
  changeOrderOfProductsThunk,
  createOfferProductThunk,
  createOrUpdateOfferCategoriesThunk,
  createOrUpdateOfferThunk,
  deleteOfferCategoryThunk,
  deleteOfferProductThunk,
  deleteOfferThunk,
  getOfferBasicInformationThunk,
  listOfferCategoriesThunk,
  listOffersThunk,
  listProductsThunk,
  setOfferStatusThunk,
  updateOfferProductThunk,
} from '../thunks/offerThunk';

const initialState: IOfferSliceState = {
  offerCategories: null,
  offerProducts: null,
  currentOfferCategoryId: null,
  currentOfferProduct: null,
  category: null,
  isOfferPublished: false,
  listOfferCategoriesStatus: null,
  getProductStatus: null,
  setOfferStatusStatus: null,
  createOfferCategoryStatus: null,
  updateOfferCategoryStatus: null,
  deleteOfferCategoryStatus: null,
  createOfferProductStatus: null,
  updateOfferProductStatus: null,
  deleteOfferProductStatus: null,
  listProductsStatus: null,
  fetchedOfferCategories: null,
  listOffersStatus: null,
  offers: null,
  updateOffersStatus: null,
  deleteOfferStatus: null,
  offer: null,
  basicOfferInformations: null,
};

export const offerSlice = createSlice({
  name: 'offer',
  initialState,
  reducers: {
    clearOffers: () => initialState,
    setCurrentOfferCategoryId: (state, { payload }) => {
      state.currentOfferCategoryId = payload;
      state.category =
        state.offerCategories?.find((category) => category.id === payload) ||
        null;
    },
    setCurrentOfferProduct: (
      state,
      { payload }: PayloadAction<IOfferProduct | null>,
    ) => {
      state.currentOfferProduct = payload;
    },
    resetDeleteOfferStatus: (state) => {
      state.deleteOfferStatus = null;
    },
    resetDeleteOfferProductStatus: (state) => {
      state.deleteOfferProductStatus = null;
    },
    resetDeleteOfferCategoryStatus: (state) => {
      state.deleteOfferCategoryStatus = null;
    },
    resetCurrentOfferCategoryId: (state) => {
      state.currentOfferCategoryId = null;
    },
    setFetchedOfferCategories: (state, { payload }) => {
      state.fetchedOfferCategories = [
        ...(state.fetchedOfferCategories || []),
        payload,
      ];
    },
    setOffer: (state, { payload }) => {
      state.offer = payload;
    },
    setBasicOfferInfomations: (state, { payload }) => {
      state.basicOfferInformations = payload;
    },
  },
  extraReducers: (builder) => {
    // listOffers
    builder.addCase(listOffersThunk.pending, (state) => {
      state.listOffersStatus = 'loading';
    });
    builder.addCase(listOffersThunk.fulfilled, (state, { payload }) => {
      if (!payload) return;
      state.offers = payload;
      state.listOffersStatus = 'success';
    });
    builder.addCase(listOffersThunk.rejected, (state) => {
      state.listOffersStatus = 'failed';
    });

    // updateOffers
    builder.addCase(createOrUpdateOfferThunk.pending, (state) => {
      state.updateOffersStatus = 'loading';
    });
    builder.addCase(
      createOrUpdateOfferThunk.fulfilled,
      (state, { payload }) => {
        if (!payload) return;
        state.offers = payload;
        state.updateOffersStatus = 'success';
      },
    );
    builder.addCase(createOrUpdateOfferThunk.rejected, (state) => {
      state.updateOffersStatus = 'failed';
    });

    // changeOrderOfOffers
    builder.addCase(changeOrderOfOffersThunk.pending, (state) => {
      state.updateOffersStatus = 'loading';
    });
    builder.addCase(
      changeOrderOfOffersThunk.fulfilled,
      (state, { payload }) => {
        if (!payload) return;
        state.offers = payload;
        state.updateOffersStatus = 'success';
      },
    );
    builder.addCase(changeOrderOfOffersThunk.rejected, (state) => {
      state.updateOffersStatus = 'failed';
    });

    // deleteOffer
    builder.addCase(deleteOfferThunk.pending, (state) => {
      state.deleteOfferStatus = 'loading';
    });
    builder.addCase(deleteOfferThunk.fulfilled, (state, { payload }) => {
      if (!payload) return;
      state.offers = payload;
      state.deleteOfferStatus = 'success';
    });
    builder.addCase(deleteOfferThunk.rejected, (state) => {
      state.deleteOfferStatus = 'failed';
    });

    // createOrUpdateOfferCategories
    builder.addCase(createOrUpdateOfferCategoriesThunk.pending, (state) => {
      state.updateOfferCategoryStatus = 'loading';
    });
    builder.addCase(
      createOrUpdateOfferCategoriesThunk.fulfilled,
      (state, { payload }) => {
        if (!payload) return;
        state.offerCategories = payload;
        state.updateOfferCategoryStatus = 'success';
      },
    );
    builder.addCase(createOrUpdateOfferCategoriesThunk.rejected, (state) => {
      state.updateOfferCategoryStatus = 'failed';
    });

    // changeOrderOfOfferCategories
    builder.addCase(changeOrderOfOfferCategoriesThunk.pending, (state) => {
      state.updateOfferCategoryStatus = 'loading';
    });
    builder.addCase(
      changeOrderOfOfferCategoriesThunk.fulfilled,
      (state, { payload }) => {
        state.offerCategories = payload;
        state.updateOfferCategoryStatus = 'success';
      },
    );
    builder.addCase(changeOrderOfOfferCategoriesThunk.rejected, (state) => {
      state.updateOfferCategoryStatus = 'failed';
    });

    // deleteOfferCategory
    builder.addCase(deleteOfferCategoryThunk.pending, (state) => {
      state.deleteOfferCategoryStatus = 'loading';
    });
    builder.addCase(
      deleteOfferCategoryThunk.fulfilled,
      (state, { payload }) => {
        state.offerCategories = payload;
        state.deleteOfferCategoryStatus = 'success';
      },
    );
    builder.addCase(deleteOfferCategoryThunk.rejected, (state) => {
      state.deleteOfferCategoryStatus = 'failed';
    });

    // listOfferCategories
    builder.addCase(listOfferCategoriesThunk.pending, (state) => {
      state.listOfferCategoriesStatus = 'loading';
    });
    builder.addCase(
      listOfferCategoriesThunk.fulfilled,
      (state, { payload }) => {
        state.offerCategories = payload.offerCategories;
        state.isOfferPublished = payload.isOfferPublished;

        state.listOfferCategoriesStatus = 'success';
      },
    );
    builder.addCase(listOfferCategoriesThunk.rejected, (state) => {
      state.listOfferCategoriesStatus = 'failed';
    });

    // getOfferBasicInformation
    builder.addCase(getOfferBasicInformationThunk.pending, (state) => {
      state.setOfferStatusStatus = 'loading';
    });
    builder.addCase(
      getOfferBasicInformationThunk.fulfilled,
      (state, { payload }) => {
        state.basicOfferInformations = payload;
        state.setOfferStatusStatus = 'success';
      },
    );
    builder.addCase(getOfferBasicInformationThunk.rejected, (state) => {
      state.setOfferStatusStatus = 'failed';
    });

    // setOfferStatus
    builder.addCase(setOfferStatusThunk.pending, (state) => {
      state.setOfferStatusStatus = 'loading';
    });
    builder.addCase(setOfferStatusThunk.fulfilled, (state, { payload }) => {
      if (!state.basicOfferInformations) return;
      state.basicOfferInformations.published = !!payload;
      state.setOfferStatusStatus = 'success';
    });
    builder.addCase(setOfferStatusThunk.rejected, (state) => {
      state.setOfferStatusStatus = 'failed';
    });

    // listProducts
    builder.addCase(listProductsThunk.pending, (state) => {
      state.listProductsStatus = 'loading';
    });
    builder.addCase(listProductsThunk.fulfilled, (state, { payload }) => {
      if (!state.offerProducts) {
        state.offerProducts = [];
      }
      const newProducts = payload;
      const existingProductIds = state.offerProducts.map(
        (product) => product.id,
      );
      const filteredNewProducts = newProducts.filter(
        (product) => !existingProductIds.includes(product.id),
      );
      state.offerProducts = [...state.offerProducts, ...filteredNewProducts];

      state.listProductsStatus = 'success';
    });
    builder.addCase(listProductsThunk.rejected, (state) => {
      state.listProductsStatus = 'failed';
    });

    builder.addCase(
      changeOrderOfProductsThunk.fulfilled,
      (state, { payload }) => {
        if (!state.offerProducts) {
          state.offerProducts = [];
        }
        const newProducts = payload;
        const existingsProducts = state.offerProducts.filter(
          (offerProduct) =>
            !newProducts.some(
              (newProduct) => newProduct.id === offerProduct.id,
            ),
        );
        const filteredNewProducts = [...existingsProducts, ...newProducts];
        state.offerProducts = filteredNewProducts;
        state.listProductsStatus = 'success';
      },
    );

    // createOfferProduct
    builder.addCase(createOfferProductThunk.pending, (state) => {
      state.createOfferProductStatus = 'loading';
    });
    builder.addCase(createOfferProductThunk.fulfilled, (state, { payload }) => {
      if (!payload || !state.offerCategories) return;

      state.offerProducts?.push(payload);
      state.createOfferProductStatus = 'success';
      state.currentOfferCategoryId = payload.offerCategoryId;

      const offerCategory = state.offerCategories.find(
        (category) => category.id === payload.offerCategoryId,
      );
      if (offerCategory) {
        offerCategory.totalProducts += 1;
      }
    });
    builder.addCase(createOfferProductThunk.rejected, (state) => {
      state.createOfferProductStatus = 'failed';
    });

    // updateOfferProduct
    builder.addCase(updateOfferProductThunk.pending, (state) => {
      state.updateOfferProductStatus = 'loading';
    });
    builder.addCase(updateOfferProductThunk.fulfilled, (state, { payload }) => {
      if (!payload || !state.offerProducts) return;

      state.offerProducts = state.offerProducts.map((product) => {
        if (product.id === payload.id) {
          return {
            ...product,
            image: payload.image ?? product.image,
            description: payload.description ?? product.description,
            price: payload.price ?? product.price,
            quantity: payload.quantity ?? product.quantity,
            unit: payload.unit ?? product.unit,
            name: payload.name ?? product.name,
          };
        }
        return product;
      });
      state.updateOfferProductStatus = 'success';
    });
    builder.addCase(updateOfferProductThunk.rejected, (state) => {
      state.updateOfferProductStatus = 'failed';
    });

    // deleteOfferProduct
    builder.addCase(deleteOfferProductThunk.pending, (state) => {
      state.deleteOfferProductStatus = 'loading';
    });
    builder.addCase(deleteOfferProductThunk.fulfilled, (state, { payload }) => {
      if (!payload || !state.offerProducts) return;

      if (state.offerCategories) {
        state.offerCategories = state.offerCategories.map((item) => {
          if (item.id === payload.categoryId) {
            return { ...item, totalProducts: item.totalProducts - 1 };
          }
          return item;
        });
      }

      state.offerProducts = state.offerProducts.filter(
        (product) => product.id !== payload.productId,
      );
      state.deleteOfferProductStatus = 'success';
    });
    builder.addCase(deleteOfferProductThunk.rejected, (state) => {
      state.deleteOfferProductStatus = 'failed';
    });
  },
});

export const {
  clearOffers,
  setCurrentOfferCategoryId,
  setCurrentOfferProduct,
  resetDeleteOfferProductStatus,
  resetDeleteOfferCategoryStatus,
  resetCurrentOfferCategoryId,
  setFetchedOfferCategories,
  setOffer,
  setBasicOfferInfomations,
  resetDeleteOfferStatus,
} = offerSlice.actions;

export default offerSlice.reducer;

import React, { SVGProps } from 'react';

const GuestBookIcon = ({ className }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.375 17.6243V2.37435H7.50646C7.73938 1.90643 8.07111 1.50477 8.50167 1.16935C8.93222 0.833793 9.43167 0.666016 10 0.666016C10.5683 0.666016 11.0678 0.833793 11.4983 1.16935C11.9289 1.50477 12.2606 1.90643 12.4935 2.37435H17.625V17.6243H2.375ZM10 3.47539C10.1667 3.47539 10.309 3.41636 10.4271 3.29831C10.5451 3.18025 10.6042 3.03789 10.6042 2.87122C10.6042 2.70456 10.5451 2.5622 10.4271 2.44414C10.309 2.32608 10.1667 2.26706 10 2.26706C9.83333 2.26706 9.69097 2.32608 9.57292 2.44414C9.45486 2.5622 9.39583 2.70456 9.39583 2.87122C9.39583 3.03789 9.45486 3.18025 9.57292 3.29831C9.69097 3.41636 9.83333 3.47539 10 3.47539ZM10 11.4898C10.8442 11.4898 11.5622 11.1939 12.154 10.6021C12.7458 10.0103 13.0417 9.29227 13.0417 8.4481C13.0417 7.60393 12.7458 6.88595 12.154 6.29414C11.5622 5.70234 10.8442 5.40643 10 5.40643C9.15583 5.40643 8.43785 5.70234 7.84604 6.29414C7.25424 6.88595 6.95833 7.60393 6.95833 8.4481C6.95833 9.29227 7.25424 10.0103 7.84604 10.6021C8.43785 11.1939 9.15583 11.4898 10 11.4898ZM4.16667 15.8327H15.8333V14.8262C15.0556 14.0762 14.1771 13.4859 13.1979 13.0554C12.2188 12.6248 11.1528 12.4096 10 12.4096C8.84722 12.4096 7.78125 12.6248 6.80208 13.0554C5.82292 13.4859 4.94444 14.0762 4.16667 14.8262V15.8327Z"
        fill="white"
        className={className}
      />
    </svg>
  );
};

export default GuestBookIcon;

import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import PrimaryHeader from '../../UI/atoms/PrimaryHeader/PrimaryHeader';
import ButtonWithIcon from '../../UI/molecules/ButtonWithIcon/ButtonWithIcon';
import HandsetIcon from '../../../assets/icons/HandsetIcon';
import Input from '../../UI/molecules/Input/Input';
import TextArea from '../../UI/molecules/TextArea/TextArea';
import Button from '../../UI/molecules/Button/Button';
import useModal from '../../../hooks/useModal';
import SupportPhoneModal from '../../UI/molecules/SupportPhoneModal/SupportPhoneModal';
import { createSupportTicketThunk } from '../../../redux/thunks/supportThunk';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import { reduxStatus } from '../../../constants/constants';
import FileUpload from '../../UI/molecules/FileUpload/FileUpload';
import { ISupportFormData } from '../../../types/Support';

const SupportPage = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const {
    visible: isSupportPhoneModalVisible,
    showModal: showSupportPhoneModal,
    hideModal: hideSupportPhoneModal,
  } = useModal();

  const methods = useForm<ISupportFormData>();

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = methods;

  const createSupportTicketStatus = useAppSelector(
    (state) => state.support.createSupportTicketStatus,
  );

  const onSubmit: SubmitHandler<ISupportFormData> = ({
    summary,
    description,
    files,
  }) => {
    dispatch(
      createSupportTicketThunk({
        summary,
        description,
        files,
      }),
    );
  };

  useEffect(() => {
    if (createSupportTicketStatus === 'success') {
      reset();
    }
  }, [createSupportTicketStatus, reset]);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...methods}>
      <section className="flex h-full w-full flex-col">
        <SupportPhoneModal
          visible={isSupportPhoneModalVisible}
          onClose={hideSupportPhoneModal}
        />
        <div className="mb-6 flex flex-row items-center justify-between gap-4">
          <PrimaryHeader text={t('messages.supportModule.support')} />
          <div className="flex items-center gap-4 text-sm xs:text-base">
            <ButtonWithIcon
              type="button"
              text={t('messages.supportModule.contactUs')}
              variant="primary"
              icon={HandsetIcon}
              onClick={showSupportPhoneModal}
            />
          </div>
        </div>
        <div className="flex-1 overflow-y-auto">
          <div>{t('messages.supportModule.welcomeMessage')}</div>
          <div className=" mt-6 rounded-xs border border-brand-700 p-6">
            <span className="mb-4 block text-lg font-bold">
              {t('messages.supportModule.submitProblem')}
            </span>
            <form className="overflow-y-auto" onSubmit={handleSubmit(onSubmit)}>
              <Controller
                control={control}
                name="summary"
                defaultValue=""
                rules={{
                  required: {
                    value: true,
                    message: t('errorMessages.thisFieldIsRequired'),
                  },
                }}
                render={({ field: { value, name, onChange } }) => (
                  <Input
                    name={name}
                    type="text"
                    variant="secondary"
                    labelText={t('messages.supportModule.problemSubject')}
                    placeholder={t('messages.supportModule.typeProblemSubject')}
                    value={value}
                    onChange={onChange}
                    error={errors.summary?.message}
                  />
                )}
              />
              <Controller
                control={control}
                name="files"
                render={({ field: { value, name, onChange } }) => (
                  <FileUpload
                    name={name}
                    labelText={t('messages.supportModule.attachments')}
                    placeholder={t('messages.noFileSelected')}
                    value={value}
                    onChange={onChange}
                    maxFileSize={20}
                  />
                )}
              />
              <Controller
                control={control}
                name="description"
                defaultValue=""
                rules={{
                  required: {
                    value: true,
                    message: t('errorMessages.thisFieldIsRequired'),
                  },
                }}
                render={({ field: { value, name, onChange } }) => (
                  <TextArea
                    name={name}
                    variant="flexible"
                    labelText={t('messages.supportModule.problemDescription')}
                    placeholder={t(
                      'messages.supportModule.typeProblemDescription',
                    )}
                    value={value}
                    onChange={onChange}
                    height={6}
                    maxLength={255}
                    error={errors.description?.message}
                  />
                )}
              />
              <div className="mt-6 flex justify-end">
                <Button
                  type="submit"
                  text={t('messages.supportModule.submitRequest')}
                  variant="secondary"
                  isLoading={createSupportTicketStatus === reduxStatus.loading}
                />
              </div>
            </form>
          </div>
        </div>
      </section>
    </FormProvider>
  );
};

export default SupportPage;

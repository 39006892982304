import React, { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

import { IAutocompleteDropdownProps } from './AutocompleteDropdown.types';
import Spinner from '../Spinner/Spinner';

const AutocompleteDropdown: FC<IAutocompleteDropdownProps> = ({
  wrapperRef,
  isOpenRule,
  list,
  isLoading,
  renderItem,
}) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const [dropdownPosition, setDropdownPosition] = useState<{
    top: number;
    left: number;
    width: number;
  }>({ top: 0, left: 0, width: 0 });

  useEffect(() => {
    const handleClick = (event: any) => {
      if (wrapperRef.current && wrapperRef.current.contains(event.target)) {
        setIsOpen(true);
      }
    };

    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [wrapperRef]);

  const updateDropdownPosition = () => {
    if (wrapperRef.current && dropdownRef.current) {
      const parentRect = wrapperRef.current.getBoundingClientRect();
      const dropdownHeight = dropdownRef.current.offsetHeight;

      const scrollContainer: Window | HTMLElement = window;

      const containerHeight =
        scrollContainer instanceof Window
          ? scrollContainer.innerHeight
          : (scrollContainer as HTMLElement).clientHeight;

      const spaceBelow = containerHeight - parentRect.bottom;
      const spaceAbove = parentRect.top;

      const MARGIN_ERROR = 20;

      const openDownwards =
        spaceBelow >= dropdownHeight + MARGIN_ERROR ||
        spaceBelow >= spaceAbove + MARGIN_ERROR;

      let top;
      if (openDownwards) {
        top = parentRect.bottom;
      } else {
        top = parentRect.top - dropdownHeight;
      }

      const left =
        parentRect.left +
        (scrollContainer instanceof Window
          ? scrollContainer.scrollX
          : (scrollContainer as HTMLElement).scrollLeft);

      setDropdownPosition({
        top:
          top +
          (scrollContainer instanceof Window
            ? scrollContainer.scrollY
            : (scrollContainer as HTMLElement).scrollTop),
        left,
        width: parentRect.width,
      });
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node) &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      window.addEventListener('mousedown', handleClickOutside);
    } else {
      window.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  useLayoutEffect(() => {
    const handleScrollResize = () => {
      if (isOpen && isOpenRule) {
        updateDropdownPosition();
      }
    };

    if (isOpen && isOpenRule) {
      updateDropdownPosition();
      window.addEventListener('resize', handleScrollResize);
      window.addEventListener('scroll', handleScrollResize, true);
    }

    return () => {
      window.removeEventListener('resize', handleScrollResize);
      window.removeEventListener('scroll', handleScrollResize, true);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, isOpenRule]);

  return (
    isOpen &&
    isOpenRule &&
    createPortal(
      <div
        ref={dropdownRef}
        className="absolute z-[400] flex max-h-[234px] flex-col gap-4 overflow-y-auto overflow-x-hidden rounded-xs border bg-brand-50 p-5"
        style={{
          top: dropdownPosition.top,
          left: dropdownPosition.left,
          width: dropdownPosition.width,
        }}
      >
        <span className="text-sm text-brand-1700">
          {t('messages.guestBook')}
        </span>
        {isLoading && <Spinner />}
        {!isLoading &&
          list.map((item) => renderItem(item, () => setIsOpen(false)))}
      </div>,
      document.body,
    )
  );
};

export default AutocompleteDropdown;

import { SVGProps } from 'react';

const CloseIcon = ({ className }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={20} height={21} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.25 16.545L3.997 15.25l4.729-4.75-4.73-4.792L5.25 4.413l4.77 4.792 4.73-4.792 1.253 1.295-4.729 4.792 4.73 4.75-1.254 1.295-4.73-4.792-4.77 4.792z"
        fill="#fff"
        className={className}
      />
    </svg>
  );
};

export default CloseIcon;

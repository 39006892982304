import { FC } from 'react';

import { IDataEmptyStateProps } from './DataEmptyState.types';
import SecondaryHeader from '../SecondaryHeader/SecondaryHeader';

const DataEmptyState: FC<IDataEmptyStateProps> = ({ title, description }) => {
  return (
    <section className="flex h-full w-full flex-col items-center justify-center">
      <SecondaryHeader text={title} isLineBreak />
      <p className="max-w-[512px] pb-6 pt-4 text-center text-brand-1700">
        {description}
      </p>
    </section>
  );
};

export default DataEmptyState;

/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from 'react';
import { useTable } from 'react-table';
import { isEmpty } from 'lodash';
import clsx from 'clsx';

import { IReservationHistoryTableProps } from './ReservationHistoryTable.types';

const ReservationHistoryTable: FC<IReservationHistoryTableProps> = ({
  columns = [],
  data = [],
  numberOfColumns,
  years,
}) => {
  const { getTableProps, getTableBodyProps, rows, prepareRow } = useTable({
    columns,
    data,
    manualSortBy: true,
  } as any);

  let previousYear: number | null = null;

  return (
    <table
      {...getTableProps()}
      className="relative h-max w-full border-separate border-spacing-0 text-left text-sm"
    >
      {!isEmpty(data) && (
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            const currentYear = years[i];
            const showYear = currentYear !== previousYear;
            previousYear = currentYear;

            return (
              // eslint-disable-next-line react/no-array-index-key
              <React.Fragment key={i}>
                {showYear && (
                  <tr>
                    <td
                      colSpan={numberOfColumns}
                      className={clsx(
                        'pb-6 text-lg font-semibold',
                        i > 0 && 'pt-6',
                      )}
                    >
                      {currentYear}
                    </td>
                  </tr>
                )}
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell: any) => (
                    <td
                      className={clsx(
                        'h-[52px] max-w-[200px] py-2',
                        cell.column.paddingTable &&
                          `w-0 p-[${cell.column.paddingTable}]`,
                        i + 1 === rows.length
                          ? 'border-none'
                          : 'border-b-[1px] border-solid border-brand-700',
                      )}
                      style={{
                        width: `calc((100vw - 140px) / ${numberOfColumns})`,
                      }}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      )}
    </table>
  );
};

export default ReservationHistoryTable;

import React, { FC } from 'react';
import clsx from 'clsx';

import { IDropdownArrowButtonProps } from './DropdownArrowButton.types';
import ArrowIcon from '../../../../assets/icons/ArrowIcon';

const DropdownArrowButton: FC<IDropdownArrowButtonProps> = ({
  onClick,
  isOpen,
}) => {
  return (
    <button
      type="button"
      className="flex h-[42px] min-h-[42px] w-[42px] min-w-[42px] select-none items-center justify-center rounded-xs border-2 border-brand-700 md:hidden md:flex-grow-0"
      onClick={onClick}
    >
      <span
        className={clsx(
          'duration-100 ease-in-out',
          isOpen ? 'rotate-0' : 'rotate-180',
        )}
      >
        <ArrowIcon />
      </span>
    </button>
  );
};

export default DropdownArrowButton;

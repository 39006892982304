import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowRightIcon } from '@mui/x-date-pickers';
import { toast } from 'react-toastify';

import PrimaryHeader from '../../atoms/PrimaryHeader/PrimaryHeader';
import ButtonWithIcon from '../ButtonWithIcon/ButtonWithIcon';
import PlusIcon from '../../../../assets/icons/PlusIcon';
import CopyIcon from '../../../../assets/icons/CopyIcon';
import Button from '../Button/Button';
import { IGuestDetailsHeaderProps } from './GuestDetailsHeader.types';
import { routes } from '../../../../constants/constants';
import ReservationModal from '../../organisms/ReservationModal/ReservationModal';
import useModal from '../../../../hooks/useModal';

const GuestDetailsHeader: FC<IGuestDetailsHeaderProps> = ({
  id,
  name,
  phoneNumber,
  company,
  email,
  position,
}) => {
  const { t } = useTranslation();

  const location = useLocation();

  const navigate = useNavigate();

  const isDetailsPage = location.pathname.includes(
    `/${routes.guestBookDetails}`,
  );
  const isReservationsPage = location.pathname.includes(
    `/${routes.guestBookBookingHistory}`,
  );

  const handleNavigateToDetailsPage = () => {
    if (isDetailsPage) return;
    navigate(`${routes.guestBook}/${id}/${routes.guestBookDetails}`);
  };

  const handleNavigateToReservationsPage = () => {
    if (isReservationsPage) return;
    navigate(`${routes.guestBook}/${id}/${routes.guestBookBookingHistory}`);
  };

  const {
    visible: isReservationModalVisible,
    showModal: showReservationModal,
    hideModal: hideReservationModal,
  } = useModal();

  const reservationClientData = { name, phoneNumber, email };

  const handleCopyCoClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    if (text === phoneNumber) {
      toast(t('messages.phoneNumberCopySuccess'));
    } else if (text === email) {
      toast(t('messages.emailCopySuccess'));
    }
  };

  return (
    <>
      <ReservationModal
        visible={isReservationModalVisible}
        onClose={hideReservationModal}
        clientData={reservationClientData}
        disableWalkin
      />
      <div className="flex w-full items-center">
        <span
          className="cursor-pointer text-sm font-medium text-brand-1700 hover:text-white"
          onClick={() => navigate(routes.guestBook)}
        >
          {t('messages.guestBook')}
        </span>

        <span className="xl:mx-4">
          <ArrowRightIcon />
        </span>
        <span className="text-sm font-medium">{name}</span>
      </div>

      <div className="mt-6 flex justify-between">
        <PrimaryHeader text={name} />
        <ButtonWithIcon
          type="button"
          text={t('messages.addReservation')}
          variant="primary"
          icon={PlusIcon}
          onClick={showReservationModal}
          isJustIconOnMobile
        />
      </div>

      <div className="mt-4 flex flex-wrap items-center gap-6 text-sm md:text-base">
        {(position || company) && (
          <span className="text-brand-1700">
            {position}
            {company && `, ${company}`}
          </span>
        )}
        <span className="flex gap-2">
          {phoneNumber}
          <button
            type="button"
            onClick={() => handleCopyCoClipboard(phoneNumber)}
          >
            <CopyIcon />
          </button>
        </span>
        {email && (
          <span className="flex gap-2">
            {email}
            <button type="button" onClick={() => handleCopyCoClipboard(email)}>
              <CopyIcon />
            </button>
          </span>
        )}
      </div>

      <div className="mt-6 flex gap-4 text-sm">
        <Button
          type="button"
          text={t('messages.guestData')}
          variant={isDetailsPage ? 'secondary' : 'tertiary'}
          onClick={handleNavigateToDetailsPage}
        />
        <Button
          type="button"
          text={t('messages.reservationHistory')}
          variant={isReservationsPage ? 'secondary' : 'tertiary'}
          onClick={handleNavigateToReservationsPage}
        />
      </div>
    </>
  );
};

export default GuestDetailsHeader;

import { useState, useRef, useEffect, useLayoutEffect } from 'react';
import { Flowbite, Datepicker } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { format, isAfter, isBefore } from 'date-fns';
import { toast } from 'react-toastify';
import { createPortal } from 'react-dom';

import { IInputDateProps } from './InputDate.types';
import InputLabel from '../../atoms/InputLabel/InputLabel';
import InputErrorMessage from '../../atoms/InputErrorMessage/InputErrorMessage';
import { customFlowBiteTheme } from '../../../../constants/flowbiteCustomTheme';
import { useDetectOutsideClick } from '../../../../hooks/useDetectOutsideClick';
import InputArea from '../../atoms/InputArea/InputArea';
import CollapseDateButton from '../../atoms/CollapseDateButton/CollapseDateButton';
import Button from '../Button/Button';
import i18n from '../../../../configuration/i18n';

const InputDate = ({
  name,
  labelText,
  placeholder,
  value,
  onChange,
  error,
  isDisabled,
  isCollapse = false,
  inputSubText = '',
  shortFormatYear = false,
  onClear,
  minDate,
  maxDate,
  disableYears,
  menuPortalTarget,
  alignTo = 'left',
}: IInputDateProps) => {
  const datePickerRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  const [showPicker, setShowPicker] = useState<boolean>(false);

  const [monthChange, setMonthChange] = useState<boolean>(false);

  const today = new Date();

  const [pickerPosition, setPickerPosition] = useState<{
    top: number;
    left: number;
  }>({ top: 0, left: 0 });

  const handleDateFormat = () => {
    if (!value) return '';
    if (disableYears) return format(Number(value), 'dd.MM');
    if (shortFormatYear) return format(Number(value), 'dd.MM.yy');
    return format(Number(value), 'dd.MM.yyyy');
  };

  const formatedDate = handleDateFormat();

  const handleInputClick = () => {
    setShowPicker(!showPicker);
  };

  const handleInputClose = () => {
    setShowPicker(false);
  };

  const handleDateChange = (selectedDate: Date) => {
    onChange(selectedDate);
    handleInputClose();
  };

  const handleClear = () => {
    if (onClear) {
      onClear();
    }
    handleInputClose();
  };

  const handleSetTodaysDate = () => {
    if (minDate && isBefore(today, minDate)) {
      toast.error(t('errorMessages.cantPickThisDate'));
    } else if (maxDate && isAfter(today, maxDate)) {
      toast.error(t('errorMessages.cantPickThisDate'));
    } else {
      handleDateChange(today);
    }
  };

  useDetectOutsideClick(datePickerRef, handleInputClose);

  const updateDropdownPosition = () => {
    if (wrapperRef.current && datePickerRef.current) {
      const parentRect = wrapperRef.current.getBoundingClientRect();
      const dropdownHeight = datePickerRef.current.offsetHeight;
      const dropdownWidth = datePickerRef.current.offsetWidth;

      const containerHeight = window.innerHeight;

      const spaceBelow = containerHeight - parentRect.bottom;
      const spaceAbove = parentRect.top;

      const MARGIN_ERROR = 20;

      const openDownwards =
        spaceBelow >= dropdownHeight + MARGIN_ERROR ||
        spaceBelow >= spaceAbove + MARGIN_ERROR;

      let top;
      if (openDownwards) {
        top = parentRect.bottom + 20;
      } else {
        top = parentRect.top - dropdownHeight - 20;
      }

      let left;

      if (window.innerWidth <= 500) {
        // center datepicker if user opens it on mobile device
        left = (window.innerWidth - dropdownWidth) / 2;
      } else if (alignTo === 'right') {
        left = parentRect.right - dropdownWidth + window.scrollX;
      } else {
        left = parentRect.left + window.scrollX;
      }

      setPickerPosition({
        top: top + window.scrollY,
        left,
      });
    }
  };

  useLayoutEffect(() => {
    const handleScrollResize = () => {
      if (showPicker) {
        updateDropdownPosition();
      }
    };

    if (showPicker) {
      updateDropdownPosition();
      window.addEventListener('resize', handleScrollResize);
      window.addEventListener('scroll', handleScrollResize, true);
    }

    return () => {
      window.removeEventListener('resize', handleScrollResize);
      window.removeEventListener('scroll', handleScrollResize, true);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPicker]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node) &&
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target as Node)
      ) {
        handleInputClose();
      }
    };

    if (showPicker) {
      window.addEventListener('mousedown', handleClickOutside);
    } else {
      window.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showPicker]);

  const handleModifyMonthHeader = () => {
    const currentMonthButton = document.querySelector('.flowbite-month-button');
    if (currentMonthButton?.textContent) {
      const buttonTextWithoutYear =
        currentMonthButton.textContent.split(' ')[0];
      currentMonthButton.textContent = buttonTextWithoutYear;
      currentMonthButton.classList.add('pointer-events-none');
    }
  };

  useEffect(() => {
    if (disableYears) {
      handleModifyMonthHeader();
    }
  }, [showPicker, monthChange, disableYears]);

  return (
    <div className="flex w-full flex-1 flex-col">
      {labelText && (
        <InputLabel
          text={labelText}
          htmlFor={name}
          variant="secondary"
          isDisabled={isDisabled}
        />
      )}
      <div
        ref={wrapperRef}
        className={clsx('relative', isDisabled && 'pointer-events-none')}
      >
        {isCollapse ? (
          <CollapseDateButton
            handleClick={handleInputClick}
            subText={inputSubText}
            text={formatedDate}
            showPicker={showPicker}
          />
        ) : (
          <InputArea
            isReadOnly
            isDisabled={isDisabled}
            isDateInput
            name={name}
            type="text"
            variant="secondary"
            placeholder={placeholder || t('messages.pickStartDate')}
            value={formatedDate}
            isError={!!error}
            onChange={(e) => onChange(new Date(e.target.value))}
            onClick={handleInputClick}
          />
        )}
        {showPicker &&
          createPortal(
            <div
              ref={datePickerRef}
              onClick={() => setMonthChange(!monthChange)}
              className="absolute z-[400] rounded-xs border-[1px] border-solid border-brand-700 bg-brand-50 p-3"
              style={{
                top: pickerPosition.top,
                left: pickerPosition.left,
              }}
            >
              <Flowbite
                theme={{
                  dark: true,
                  theme: customFlowBiteTheme,
                }}
              >
                <Datepicker
                  inline
                  language={i18n.language}
                  weekStart={2}
                  defaultDate={value}
                  onSelectedDateChanged={(e) => handleDateChange(e)}
                  minDate={minDate ?? undefined}
                  maxDate={maxDate ?? undefined}
                />
              </Flowbite>
              <div className="flex gap-4">
                {onClear && (
                  <Button
                    type="button"
                    variant="octonary"
                    text={t('messages.clear')}
                    onClick={handleClear}
                  />
                )}
                <Button
                  type="button"
                  variant="septenary"
                  text={t('messages.today')}
                  onClick={handleSetTodaysDate}
                />
              </div>
            </div>,
            menuPortalTarget || document.body,
          )}
      </div>
      {!!error && <InputErrorMessage error={error} />}
    </div>
  );
};

export default InputDate;

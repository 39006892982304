import clsx from 'clsx';

import { ITableProps } from './Table.types';

const SixSeatedRectangleTable = ({
  primaryColor = '#3F394D',
  secondaryColor = '#B3ADC1',
  disabled,
}: ITableProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={141}
      height={72}
      fill="none"
      className={clsx(disabled && 'opacity-50')}
    >
      <rect
        width={24}
        height={8}
        x={26.438}
        y={64}
        fill={secondaryColor}
        rx={1.778}
      />
      <rect
        width={24}
        height={8}
        x={58.438}
        y={64}
        fill={secondaryColor}
        rx={1.778}
      />
      <rect
        width={24}
        height={8}
        x={90.438}
        y={64}
        fill={secondaryColor}
        rx={1.778}
      />
      <rect width={24} height={8} x={26.438} fill={secondaryColor} rx={1.778} />
      <rect width={24} height={8} x={58.438} fill={secondaryColor} rx={1.778} />
      <rect width={24} height={8} x={90.438} fill={secondaryColor} rx={1.778} />
      <rect
        width={138}
        height={64}
        x={1.438}
        y={4}
        fill={primaryColor}
        stroke={secondaryColor}
        strokeWidth={2}
        rx={8}
      />
    </svg>
  );
};

export default SixSeatedRectangleTable;

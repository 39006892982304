import { FC } from 'react';

import { IConversationStartDate } from './ConversationStartDate.types';

const ConversationStartDate: FC<IConversationStartDate> = ({ date }) => {
  return (
    <p className="w-full text-center text-xs font-semibold text-brand-1700">
      {date}
    </p>
  );
};

export default ConversationStartDate;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { AxiosError } from 'axios';

import {
  IUpdatePlaceThunk,
  ICreateEmployeeThunk,
  IUpdateGalleryThunk,
  IUpdateEmployeeThunk,
} from '../../types/Place';
import {
  ISetAvailabilityStatusThunk,
  ISetPaymentMethodThunk,
  IUpdateFirstStepsToFalseThunk,
  IUpdateHoursThunk,
  IUpdateTableStatusThunk,
} from '../../types/Settings';
import i18n from '../../configuration/i18n';
import { REST_API_URLS, apiErrorMessages } from '../../constants/constants';
import { axiosMiddleware } from '../../configuration/axiosMiddleware';
import {
  GetCurrentSmsSchema,
  GetPlaceBaseSettingsResponseSchema,
  PlaceSchema,
} from '../../schema/PlaceSchemas';
import {
  getBunnyImage,
  getFileCdnFromStorageLink,
  removeImageFromStorage,
  uploadPhoto,
} from '../../functions/storage';
import {
  IDeleteEmployeeThunk,
  IGetEmployeeThunk,
  IListEmployeesThunk,
} from '../../types/Employee';
import {
  CreateEmployeeSchema,
  DeleteEmployeeSchema,
  GetEmployeeSchema,
  ListEmployeesSchema,
  UpdateEmployeeSchema,
} from '../../schema/EmployeeSchemas';
import { deepEqual, handleForbiddenError } from '../../functions/functions';
import { ImageCompressionError } from '../../types/Storage';
import { errorSchema } from '../../schema/ErrorSchemas';
import { IAddTagsThunk } from '../../types/GuestBook';
import { UpdateTagsResponseSchema } from '../../schema/GuestBookSchemas';

export const setSpacePlanStatusThunk = createAsyncThunk(
  'spacePlan/setSpacePlanStatus',
  async (
    { isSpacePlanPublished }: ISetAvailabilityStatusThunk,
    { rejectWithValue },
  ) => {
    try {
      await axiosMiddleware({
        url: REST_API_URLS.setSpacePlanStatus,
        method: 'PATCH',
        data: {
          isSpacePlanPublished,
        },
      });

      return isSpacePlanPublished;
    } catch (error) {
      handleForbiddenError(
        error,
        t('errorMessages.somethingWentWrongDuringPublishingSpacePlan'),
      );
      return rejectWithValue(error);
    }
  },
);

export const updateFirstStepsToFalseThunk = createAsyncThunk(
  'settings/updateFirstStepsToFalse',
  async (
    { placeId, callback }: IUpdateFirstStepsToFalseThunk,
    { rejectWithValue },
  ) => {
    try {
      await axiosMiddleware({
        url: REST_API_URLS.updatePlace,
        method: 'PATCH',
        data: {
          placeId,
          isFirstSteps: false,
          isDraft: false,
        },
      });

      if (callback) callback();

      return { firstSteps: false };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getPlaceBaseSettingsThunk = createAsyncThunk(
  'settings/getPlaceBaseSettings',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosMiddleware({
        url: REST_API_URLS.getPlaceBaseSettings,
        method: 'GET',
      });

      const validatedData = GetPlaceBaseSettingsResponseSchema.parse(response);

      const { data } = validatedData;

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getPlaceForSettingsThunk = createAsyncThunk(
  'settings/getPlaceForSettings',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosMiddleware({
        url: REST_API_URLS.getPlaceForSettings,
        method: 'GET',
      });

      const { data } = response;

      const validatedData = PlaceSchema.parse(data);

      const place = {
        ...validatedData,
        logo: validatedData.logo
          ? getBunnyImage(validatedData.logo, '300', '300')
          : null,
      };

      return place;
    } catch (error) {
      toast(t('errorMessages.somethingWentWrongDuringFetchingPlace'));
      return rejectWithValue(error);
    }
  },
);

export const updatePlaceThunk = createAsyncThunk(
  'settings/updatePlace',
  async (
    { input, oldImageUrl, path, callback }: IUpdatePlaceThunk,
    { rejectWithValue },
  ) => {
    try {
      const { logo } = input;
      let photoUrl: string | null = oldImageUrl;

      if (logo instanceof File) {
        if (oldImageUrl !== '') {
          removeImageFromStorage(oldImageUrl, 'settings');
        }
        const uploadedImage = await uploadPhoto(logo, path);
        photoUrl = uploadedImage.photoUrl;
      }
      if (logo === null) photoUrl = null;

      const preparedInput = {
        ...input,
        logo: photoUrl ? getFileCdnFromStorageLink(photoUrl) : null,
      };

      await axiosMiddleware({
        url: REST_API_URLS.updatePlace,
        method: 'PATCH',
        data: preparedInput,
      });

      callback();

      const preparedOutput = {
        ...preparedInput,
        formattedAddress: `${preparedInput.street}, ${preparedInput.zipCode} ${preparedInput.city}`,
        logo: photoUrl ? getBunnyImage(photoUrl, '300', '300') : null,
      };

      return preparedOutput;
    } catch (error) {
      if (error instanceof ImageCompressionError) {
        toast.error(
          t('errorMessages.somethingWentWrongDuringCompressingImage'),
        );
        return rejectWithValue(ImageCompressionError);
      }
      if (error instanceof AxiosError) {
        const parsedError = errorSchema.safeParse(error.response?.data);

        if (!parsedError.success) {
          toast.error(t('errorMessages.somethingWentWrongDuringUpdatingPlace'));
          return rejectWithValue(error);
        }

        const { errorCode } = parsedError.data;
        if (errorCode === 'ENCODED_NAME_TAKEN') {
          toast(t('errorMessages.encodedNameTaken'));
        } else if (errorCode === 'ADDRESS_INCORRECT') {
          toast(t('errorMessages.addressNotFound'));
        }
      } else {
        toast(t('errorMessages.somethingWentWrongDuringUpdatingPlace'));
      }
      return rejectWithValue(error);
    }
  },
);

export const listEmployeesThunk = createAsyncThunk(
  'settings/listEmployees',
  async ({ placeId }: IListEmployeesThunk, { rejectWithValue }) => {
    try {
      const response = await axiosMiddleware({
        url: REST_API_URLS.listEmployees,
        method: 'GET',
        params: { placeId },
      });

      const validatedData = ListEmployeesSchema.parse(response);

      return validatedData.data;
    } catch (error) {
      toast(t('errorMessages.somethingWentWrongDuringFetchingEmployees'));
      return rejectWithValue(error);
    }
  },
);

export const createEmployeeThunk = createAsyncThunk(
  'settings/createEmployee',
  async ({ input, callback }: ICreateEmployeeThunk, { rejectWithValue }) => {
    try {
      const response = await axiosMiddleware({
        url: REST_API_URLS.createEmployee,
        method: 'POST',
        data: input,
      });

      const validatedData = CreateEmployeeSchema.parse(response);

      if (callback) callback();

      return validatedData.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        const errorData = error.response?.data;
        const parsedError = errorSchema.parse(errorData);
        const { errorCode } = parsedError;

        if (
          errorCode === apiErrorMessages.USER_EXISTS ||
          errorCode === apiErrorMessages.EMAIL_TAKEN
        ) {
          toast(t('errorMessages.employeeWithThisEmailAlreadyExist'));
        }
      } else {
        toast(t('errorMessages.somethingWentWrongDuringCreatingEmployee'));
      }
      return rejectWithValue(error);
    }
  },
);

export const getEmployeeThunk = createAsyncThunk(
  'settings/getEmployee',
  async ({ firebaseId }: IGetEmployeeThunk, { rejectWithValue }) => {
    try {
      const response = await axiosMiddleware({
        url: REST_API_URLS.getEmployee(firebaseId),
        method: 'GET',
      });

      const validatedData = GetEmployeeSchema.parse(response);

      return validatedData.data;
    } catch (error) {
      toast(t('errorMessages.somethingWentWrongDuringFetchingEmployee'));
      return rejectWithValue(error);
    }
  },
);

export const updateEmployeeThunk = createAsyncThunk(
  'settings/updateEmployee',
  async (
    { firebaseId, input, callback }: IUpdateEmployeeThunk,
    { rejectWithValue },
  ) => {
    const preparedInput = {
      ...input,
      firebaseId,
    };

    try {
      const response = await axiosMiddleware({
        url: REST_API_URLS.updateEmployee,
        method: 'PATCH',
        data: preparedInput,
      });

      UpdateEmployeeSchema.parse(response);

      if (callback) callback();

      return input;
    } catch (error) {
      if (error instanceof AxiosError) {
        const errorData = error.response?.data;
        const parsedError = errorSchema.parse(errorData);
        const { errorCode } = parsedError;

        if (
          errorCode === apiErrorMessages.USER_EXISTS ||
          errorCode === apiErrorMessages.EMAIL_TAKEN
        ) {
          toast(t('errorMessages.employeeWithThisEmailAlreadyExist'));
        }
      } else {
        toast(t('errorMessages.somethingWentWrongDuringUpdatingEmployee'));
      }
      return rejectWithValue(error);
    }
  },
);

export const deleteEmployeeThunk = createAsyncThunk(
  'settings/deleteEmployee',
  async (
    { firebaseId, placeId }: IDeleteEmployeeThunk,
    { rejectWithValue },
  ) => {
    try {
      const response = await axiosMiddleware({
        url: REST_API_URLS.deleteEmployee,
        method: 'DELETE',
        data: { firebaseId, placeId },
      });

      DeleteEmployeeSchema.parse(response);
    } catch (error) {
      toast(t('errorMessages.somethingWentWrongDuringDeletingEmployee'));
      return rejectWithValue(error);
    }
  },
);

export const updateGalleryThunk = createAsyncThunk(
  'schedule/updateGallery',
  async (
    {
      placeId,
      photos,
      toDelete,
      sortedArray,
      imagesBeforeUpdate,
      callback,
    }: IUpdateGalleryThunk,
    { rejectWithValue },
  ) => {
    try {
      let newSortedArray = sortedArray;

      if (toDelete) {
        await Promise.all(
          toDelete.map(async (image) => {
            await removeImageFromStorage(image, 'gallery');
          }),
        );

        newSortedArray = sortedArray.filter((url) => !toDelete.includes(url));
      }

      await Promise.all(
        photos.map(async (photo) => {
          if (photo.image instanceof File) {
            const uploadedImage = await uploadPhoto(photo.image, '/gallery');
            const { photoUrl } = uploadedImage;

            newSortedArray = newSortedArray.map((item) =>
              item === photo.uuid ? photoUrl : item,
            );
          }
        }),
      );

      const attachedPhotos = newSortedArray.map((url) => {
        if (url.includes('gallery%2F') && url.includes('?alt=media')) {
          return getFileCdnFromStorageLink(url);
        }
        return url;
      });

      if (!deepEqual(attachedPhotos, imagesBeforeUpdate)) {
        await axiosMiddleware({
          url: REST_API_URLS.updatePlace,
          method: 'PATCH',
          data: {
            placeId,
            gallery: attachedPhotos,
          },
        });
      }

      callback();

      return attachedPhotos;
    } catch (error) {
      toast.error(i18n.t('errorMessages.somethingWentWrong'));
      return rejectWithValue(error);
    }
  },
);

export const setPaymentMethodThunk = createAsyncThunk(
  'settings/setPaymentMethod',
  async (
    { placeId, paymentMethods, callback }: ISetPaymentMethodThunk,
    { rejectWithValue },
  ) => {
    try {
      await axiosMiddleware({
        url: REST_API_URLS.updatePlace,
        method: 'PATCH',
        data: {
          placeId,
          paymentMethods,
        },
      });

      callback();

      return paymentMethods;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateTableStatusThunk = createAsyncThunk(
  'settings/updateTableStatusThunk',
  async (
    { placeId, tableStatuses, callback }: IUpdateTableStatusThunk,
    { rejectWithValue },
  ) => {
    try {
      await axiosMiddleware({
        url: REST_API_URLS.updatePlace,
        method: 'PATCH',
        data: {
          placeId,
          tableStatuses,
        },
      });

      callback();

      return tableStatuses;
    } catch (error) {
      toast.error(
        i18n.t('errorMessages.somethingWentWrongDuringUpdatingStatuses'),
      );
      return rejectWithValue(error);
    }
  },
);

export const getCurrentSmsLimitThunk = createAsyncThunk(
  'settings/getCurrentSmsLimit',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosMiddleware({
        url: REST_API_URLS.getCurrentSmsLimit,
        method: 'GET',
      });

      const validatedData = GetCurrentSmsSchema.parse(response);

      return validatedData.data;
    } catch (error) {
      toast(t('errorMessages.somethingWentWrongDuringFetchingCurrentSms'));
      return rejectWithValue(error);
    }
  },
);

export const updateOpeningHoursThunk = createAsyncThunk(
  'settings/updateOpeningHours',
  async ({ placeId, openingHours, callback }: IUpdateHoursThunk) => {
    try {
      await axiosMiddleware({
        url: REST_API_URLS.updatePlace,
        method: 'PATCH',
        data: {
          placeId,
          openingHours,
        },
      });

      if (callback) callback();

      return openingHours;
    } catch (error) {
      toast.error(i18n.t('errorMessages.somethingWentWrong'));
    }
  },
);

export const updateTagsThunk = createAsyncThunk(
  'guestBook/updateTagsThunk',
  async ({ tags, callback }: IAddTagsThunk, { rejectWithValue }) => {
    try {
      const response = await axiosMiddleware({
        url: `${REST_API_URLS.updateTags}`,
        method: 'PATCH',
        data: { tags },
      });

      const validatedResponse = UpdateTagsResponseSchema.parse(response);

      if (callback) callback();
      return validatedResponse.data;
    } catch (error) {
      toast.error(t('errorMessages.somethingWentWrongDuringUpdatingTags'));
      return rejectWithValue(error);
    }
  },
);

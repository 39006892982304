import clsx from 'clsx';

import { ITableProps } from './Table.types';

const FourSeatedCircleTable = ({
  primaryColor = '#3F394D',
  secondaryColor = '#B3ADC1',
  disabled,
}: ITableProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={77}
      height={77}
      fill="none"
      className={clsx(disabled && 'opacity-50')}
    >
      <path
        fill={primaryColor}
        stroke={secondaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M4.277 36.278c0-17.673 14.327-32 32-32v0c17.673 0 32 14.327 32 32v0c0 17.673-14.327 32-32 32v0c-17.673 0-32-14.327-32-32"
      />
      <path
        fill={secondaryColor}
        fillRule="evenodd"
        d="M38.527 68.882a33 33 0 0 1-6.075-.188 32.2 32.2 0 0 1-10.768-3.322 4.056 4.056 0 0 0 2.329 5.435A36.4 36.4 0 0 0 36.5 73c4.379 0 8.586-.773 12.487-2.193a4.056 4.056 0 0 0 2.329-5.435 32.3 32.3 0 0 1-12.79 3.51m26.845-17.566q.115.05.237.095a4.056 4.056 0 0 0 5.198-2.424A36.4 36.4 0 0 0 73 36.5c0-4.379-.773-8.586-2.193-12.487a4.056 4.056 0 0 0-5.435-2.329A32.3 32.3 0 0 1 68.944 36.5a33 33 0 0 1-.25 4.048 32.2 32.2 0 0 1-3.322 10.768M51.316 7.628q.05-.116.095-.237a4.056 4.056 0 0 0-2.424-5.198A36.4 36.4 0 0 0 36.5 0c-4.379 0-8.586.773-12.487 2.193a4.056 4.056 0 0 0-2.329 5.435A32.3 32.3 0 0 1 36.5 4.056a33 33 0 0 1 4.048.25 32.2 32.2 0 0 1 10.768 3.322M7.628 21.684a4.056 4.056 0 0 0-5.435 2.329A36.4 36.4 0 0 0 0 36.5c0 4.379.773 8.586 2.193 12.487a4.056 4.056 0 0 0 5.435 2.329A32.3 32.3 0 0 1 4.056 36.5a33 33 0 0 1 .25-4.048 32.2 32.2 0 0 1 3.322-10.768"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default FourSeatedCircleTable;

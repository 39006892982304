import { FC } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { IMobileSideMenuProps } from './MobileSideMenu.types';
import {
  routes,
  sideMenuOptions,
  userRoles,
} from '../../../../constants/constants';
import SideMenuLink from '../../molecules/SideMenuLink/SideMenuLink';
import CloseMobileSideMenuIcon from '../../../../assets/icons/CloseMobileSideMenuIcon';
import UserInfoBox from '../../atoms/UserInfoBox/UserInfoBox';
import { useAppSelector } from '../../../../hooks/useRedux';
import LanguageDropdown from '../../atoms/LanguageDropdown/LanguageDropdown';

const MobileSideMenu: FC<IMobileSideMenuProps> = ({ isOpen, hideMenu }) => {
  const { t } = useTranslation();

  const user = useAppSelector((state) => state.user.user);

  const isChatAvailable = useAppSelector(
    (state) => state.settings.isChatAvailable,
  );

  const isAnyMessageUnseen = useAppSelector(
    (state) => state.reservation.isAnyMessageUnseen,
  );

  const isEmployee = user?.role === userRoles.employee;

  const isEmployeeOrManager =
    user?.role === 'EMPLOYEE' || user?.role === 'MANAGER';

  const isRedDotVisible = (route: string) => {
    if (route === routes.reservations && isChatAvailable) {
      return isAnyMessageUnseen;
    }
    return false;
  };

  return (
    <>
      <div
        className={clsx(
          'absolute left-0 top-0 z-[99] h-screen w-screen bg-black/75 xl:hidden',
          isOpen ? 'translate-x-0' : '-translate-x-full',
        )}
      />
      <nav
        className={clsx(
          'absolute left-0 top-0 z-[100] flex h-full w-64 flex-col justify-between overflow-y-auto border-r bg-brand-50 duration-300 xl:hidden',
          isOpen ? 'translate-x-0' : '-translate-x-full',
        )}
      >
        <div className="flex flex-col pb-3 pt-4">
          <div className="flex items-center justify-between">
            <button type="button" onClick={hideMenu} className="pb-4 pl-4 pt-6">
              <CloseMobileSideMenuIcon />
            </button>
            <div>
              <LanguageDropdown isMobile />
            </div>
          </div>
          {sideMenuOptions.map((section, index) => (
            <div
              key={section[0].route}
              className={clsx('py-3', index > 0 && 'border-t border-brand-700')}
            >
              <div className="px-3">
                {section.map((option) => {
                  const availabilityRouteForEmployee =
                    option.route === routes.availabilitySpacePlan && isEmployee
                      ? routes.availabilitySchedule
                      : option.route;

                  const isSettings = option.route === routes.settings;

                  if (
                    !isEmployeeOrManager ||
                    (isEmployeeOrManager && !isSettings)
                  ) {
                    return (
                      <SideMenuLink
                        key={option.route}
                        route={availabilityRouteForEmployee}
                        text={t(`messages.${option.label}`)}
                        icon={option.icon}
                        isMenuOpen={isOpen}
                        callback={hideMenu}
                        showRedDot={isRedDotVisible(option.route)}
                      />
                    );
                  }
                })}
              </div>
            </div>
          ))}
        </div>
        <div className="flex flex-col gap-6 px-3">
          {/* <FunzyProSidebarBox /> */}
          <UserInfoBox />
        </div>
      </nav>
    </>
  );
};

export default MobileSideMenu;

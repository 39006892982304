import clsx from 'clsx';

import { ITableProps } from './Table.types';

const OneSeatedCircleTable = ({
  primaryColor = '#3F394D',
  secondaryColor = '#B3ADC1',
  disabled,
}: ITableProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={64}
      height={70}
      fill="none"
      className={clsx(disabled && 'opacity-50')}
    >
      <path
        fill={primaryColor}
        stroke={secondaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M32 69C14.88 69 1 55.12 1 38S14.88 7 32 7s31 13.88 31 31-13.88 31-31 31"
      />
      <path
        fill={secondaryColor}
        fillRule="evenodd"
        d="M14.546 12a8 8 0 0 1-.189-.523c-1.114-3.499.464-7.367 3.524-8.641A36.6 36.6 0 0 1 32 0c4.949 0 9.707.999 14.12 2.836 3.06 1.274 4.637 5.142 3.523 8.64q-.085.27-.189.524a34 34 0 0 0-2.626-1.544q-1.335-.706-2.726-1.285a31.7 31.7 0 0 0-6.226-1.874A31 31 0 0 0 32 6.742a31.4 31.4 0 0 0-5.876.555 31.7 31.7 0 0 0-6.226 1.874q-1.392.58-2.726 1.285A34 34 0 0 0 14.546 12"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default OneSeatedCircleTable;

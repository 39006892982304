import { addMinutes, isAfter } from 'date-fns';

import { TReservation, TTableReservationStatus } from '../types/Reservation';
import EightSeatedCircleTable from '../assets/icons/tables/EightSeatedCircleTable';
import EightSeatedRectangleTable from '../assets/icons/tables/EightSeatedRectangleTable';
import FourSeatedCircleTable from '../assets/icons/tables/FourSeatedCircleTable';
import FourSeatedRectangleTable from '../assets/icons/tables/FourSeatedRectangleTable';
import FourSeatedSquareTable from '../assets/icons/tables/FourSeatedSquareTable';
import OneSeatedCircleTable from '../assets/icons/tables/OneSeatedCircleTable';
import OneSeatedSquareTable from '../assets/icons/tables/OneSeatedSquareTable';
import SixSeatedRectangleTable from '../assets/icons/tables/SixSeatedRectangleTable';
import TenSeatedRectangleTable from '../assets/icons/tables/TenSeatedRectangleTable';
import TwoSeatedCircleTable from '../assets/icons/tables/TwoSeatedCircleTable';
import TwoSeatedRectangleTable from '../assets/icons/tables/TwoSeatedRectangleTable';
import TwoSeatedSquareTable from '../assets/icons/tables/TwoSeatedSquareTable';
import { TTable, TTableShape } from '../types/SpacePlan';

export const getTableImage = (shape: TTableShape, imageSeats: number) => {
  switch (shape) {
    case 'CIRCLE':
      switch (imageSeats) {
        case 1:
          return OneSeatedCircleTable;
        case 2:
          return TwoSeatedCircleTable;
        case 4:
          return FourSeatedCircleTable;
        case 8:
          return EightSeatedCircleTable;
        default:
          return OneSeatedCircleTable;
      }
    case 'SQUARE':
      switch (imageSeats) {
        case 1:
          return OneSeatedSquareTable;
        case 2:
          return TwoSeatedSquareTable;
        case 4:
          return FourSeatedSquareTable;
        default:
          return OneSeatedSquareTable;
      }
    case 'RECTANGLE':
      switch (imageSeats) {
        case 2:
          return TwoSeatedRectangleTable;
        case 4:
          return FourSeatedRectangleTable;
        case 6:
          return SixSeatedRectangleTable;
        case 8:
          return EightSeatedRectangleTable;
        case 10:
          return TenSeatedRectangleTable;
        default:
          return TwoSeatedRectangleTable;
      }
    default:
      return OneSeatedCircleTable;
  }
};

export const getTableSpaceWidth = (shape: TTableShape, imageSeats: number) => {
  if (shape === 'CIRCLE' || shape === 'SQUARE') {
    return 76;
  }
  if (shape === 'RECTANGLE') {
    switch (imageSeats) {
      case 2:
        return 80;
      case 4:
        return 110;
      case 6:
        return 185;
      case 8:
        return 210;
      case 10:
        return 240;
      default:
        return 76;
    }
  }

  return 0;
};

export const getTableSpaceHeight = (shape: TTableShape) => {
  if (shape === 'SQUARE' || shape === 'CIRCLE') {
    return 76;
  }
  if (shape === 'RECTANGLE') {
    return 100;
  }

  return 0;
};

export const getTableScale = (shape: TTableShape, imageSeats: number) => {
  if (shape === 'CIRCLE' || shape === 'SQUARE') {
    return 1;
  }
  if (shape === 'RECTANGLE') {
    switch (imageSeats) {
      case 2:
        return 1.125;
      case 4:
        return 1.25;
      case 6:
        return 1.25;
      case 8:
        return 1.4;
      case 10:
        return 1.4;
      default:
        return 1;
    }
  }
};

export const getTableLabelPosition = (
  rotation: number,
): {
  top?: 0;
  left?: 0;
  right?: 0;
  bottom?: 0;
} => {
  if (rotation >= 0 && rotation < 90) {
    return {
      top: 0,
      left: 0,
    };
  }
  if (rotation >= 90 && rotation < 180) {
    return {
      bottom: 0,
      left: 0,
    };
  }
  if (rotation >= 180 && rotation < 270) {
    return {
      bottom: 0,
      right: 0,
    };
  }
  if (rotation >= 270 && rotation < 360) {
    return {
      top: 0,
      right: 0,
    };
  }
  return {
    top: 0,
    left: 0,
  };
};

export const getWalkInIconPosition = (
  rotation: number,
): {
  top?: 2;
  left?: 0;
  right?: 0;
  bottom?: 2;
} => {
  if (rotation >= 0 && rotation < 90) {
    return {
      bottom: 2,
      right: 0,
    };
  }
  if (rotation >= 90 && rotation < 180) {
    return {
      top: 2,
      right: 0,
    };
  }
  if (rotation >= 180 && rotation < 270) {
    return {
      top: 2,
      left: 0,
    };
  }
  if (rotation >= 270 && rotation < 360) {
    return {
      bottom: 2,
      left: 0,
    };
  }
  return {
    bottom: 2,
    right: 0,
  };
};

export const getTableReservationInfoPosition = (
  rotation: number,
): {
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
  transform: string;
} => {
  if (rotation >= 0 && rotation < 90) {
    return {
      top: '50%',
      left: '50%',
      transform: `translateX(-50%) translateY(-50%) rotate(-${rotation}deg)`,
    };
  }
  if (rotation >= 90 && rotation < 180) {
    return {
      bottom: '50%',
      left: '50%',
      transform: `translateX(-50%) translateY(50%) rotate(-${rotation}deg)`,
    };
  }
  if (rotation >= 180 && rotation < 270) {
    return {
      bottom: '50%',
      right: '50%',
      transform: `translateX(50%) translateY(50%) rotate(-${rotation}deg)`,
    };
  }
  if (rotation >= 270 && rotation < 360) {
    return {
      top: '50%',
      right: '50%',
      transform: `translateX(50%) translateY(-50%) rotate(-${rotation}deg)`,
    };
  }
  return {
    top: '50%',
    left: '50%',
    transform: `translateX(-50%) translateY(-50%) rotate(-${rotation}deg)`,
  };
};

export const getTableResevationStatus = (
  status: TReservation['status'],
  startDate: string,
): TTableReservationStatus => {
  if (status === 'NEW') {
    // check if start date is five minutes in the past
    if (isAfter(new Date(), addMinutes(new Date(startDate), 5))) {
      return 'late';
    }

    return 'noSmsConfirmation';
  }
  if (status === 'STARTED') {
    return 'started';
  }
  if (status === 'FINISHED') {
    return 'past';
  }
  if (status === 'CANCELLED') {
    return 'cancelled';
  }
  if (status === 'SMS_CONFIRMED') {
    // check if start date is five minutes in the past
    if (isAfter(new Date(), addMinutes(new Date(startDate), 5))) {
      return 'late';
    }
    return 'smsConfirmed';
  }
  if (status === 'DELAYED') {
    return 'late';
  }

  return 'noSmsConfirmation';
};

export const calculateCenterOfGravity = (tables: TTable[]) => {
  let sumX = 0;
  let sumY = 0;
  const n = tables.length;

  if (n === 0) return { x: 0, y: 0 };

  tables.forEach((table) => {
    sumX += table.positionX;
    sumY += table.positionY;
  });

  const centerX = sumX / n;
  const centerY = sumY / n;

  return { x: centerX, y: centerY };
};

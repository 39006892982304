import clsx from 'clsx';

import { IBadgeProps } from './Badge.types';

const Badge = ({
  children,
  color,
  isReservationList,
}: IBadgeProps): JSX.Element => {
  return (
    <div
      style={{
        backgroundColor: color,
      }}
      className={clsx(
        'flex w-fit items-center justify-center rounded-full px-2 py-1 text-xs font-medium',
        isReservationList &&
          'w-fit leading-[18px] xl:px-[10px] xl:py-1 xl:text-sm xl:leading-[21px]',
      )}
    >
      {children}
    </div>
  );
};

export default Badge;

import { useTranslation } from 'react-i18next';

import SendIcon from '../../../../assets/icons/SendIcon';
import { IChatInput } from '../../../../types/Chat';

const ChatInput = ({ newMessage, setNewMessage, handleSubmit }: IChatInput) => {
  const { t } = useTranslation();

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (newMessage.trim() !== '') {
        handleSubmit(e);
      }
    }
  };

  return (
    <div className="relative w-full">
      <input
        type="text"
        className="h-[42px] w-full rounded-xs border border-brand-700 bg-transparent px-4 py-3 pr-10 text-base font-normal text-white outline-none placeholder:text-brand-1700"
        placeholder={t('messages.writeMessage')}
        value={newMessage}
        onChange={(e) => setNewMessage(e.target.value)}
        onKeyDown={handleKeyDown}
        maxLength={1000}
      />
      <div
        className="absolute right-3.5 top-0 flex h-full items-center"
        onClick={handleSubmit}
      >
        <SendIcon />
      </div>
    </div>
  );
};

export default ChatInput;

import React from 'react';
import { useTranslation } from 'react-i18next';

import useMobile from '../../../../hooks/useIsMobile';
import MoreIcon from '../../../../assets/icons/MoreIcon';

const GuestDetailsVisitsSkeleton = () => {
  const { t } = useTranslation();

  const isMobile = useMobile();

  return (
    <>
      <div className="flex animate-pulse gap-2 text-center md:gap-4">
        <div className="flex flex-1 flex-col items-center gap-1 rounded-xs bg-brand-100 p-3 md:px-6 md:py-4">
          <span className="h-14 w-14 rounded-xs bg-brand-700" />
          <span className="h-5 w-44 rounded-xs bg-brand-300" />
        </div>
        <div className="flex flex-1 flex-col items-center gap-1 rounded-xs bg-brand-100 p-3 md:px-6 md:py-4">
          <span className="h-14 w-14 rounded-xs bg-brand-700" />
          <span className="h-5 w-44 rounded-xs bg-brand-300" />
        </div>
        <div className="flex flex-1 flex-col items-center gap-1 rounded-xs bg-brand-100 p-3 md:px-6 md:py-4">
          <span className="h-14 w-14 rounded-xs bg-brand-700" />
          <span className="h-5 w-44 rounded-xs bg-brand-300" />
        </div>
      </div>
      {/* LAST AND FIRST VISIT */}
      <div className="flex flex-col gap-6 sm:flex-row">
        <div className="flex flex-1 flex-col gap-4 rounded-xs border border-brand-700 p-6">
          <span className="text-lg font-semibold">
            {t('messages.lastVisit')}
          </span>
          <span className="h-6 w-44 animate-pulse rounded-xs bg-brand-300" />
        </div>
        <div className="flex flex-1 flex-col gap-4 rounded-xs border border-brand-700 p-6">
          <span className="w-full text-lg font-semibold">
            {t('messages.firstVisit')}
          </span>
          <span className="h-6 w-44 animate-pulse rounded-xs bg-brand-300" />
        </div>
      </div>

      {/* NEXT VISIT */}
      <div className="flex flex-col rounded-sm border border-brand-700 p-6">
        <span className="text-lg font-semibold">{t('messages.nextVisit')}</span>

        <div className="mt-6 flex justify-between">
          <div className="flex w-full items-center gap-4 md:justify-between">
            <span className="h-8 w-28 animate-pulse rounded-xs bg-brand-700" />
            <span className="h-5 w-24 animate-pulse rounded-xs bg-brand-300" />
            {!isMobile && (
              <>
                <span className="h-5 w-24 animate-pulse rounded-xs bg-brand-300" />
                <span className="h-5 w-24 animate-pulse rounded-xs bg-brand-300" />
                <span className="h-8 w-28 animate-pulse rounded-full bg-brand-700" />
              </>
            )}
          </div>
          <div className="relative flex gap-4 md:ml-8">
            <MoreIcon className="fill-brand-700" />
          </div>
        </div>

        {isMobile && (
          <div className="mt-6 flex justify-between text-xs font-semibold text-brand-1700">
            <div className="flex flex-col gap-1 uppercase">
              <span className="flex h-[26px] items-center">
                {t('messages.table')}
              </span>
              <span className="flex h-[26px] items-center">
                {t('messages.numberOfPeople')}
              </span>
              <span className="flex h-[26px] items-center">
                {t('messages.status')}
              </span>
            </div>
            <div className="flex flex-col gap-2 uppercase">
              <span className="h-5 w-24 animate-pulse rounded-xs bg-brand-300" />
              <span className="h-5 w-24 animate-pulse rounded-xs bg-brand-300" />
              <span className="h-6 w-28 animate-pulse rounded-full bg-brand-700" />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default GuestDetailsVisitsSkeleton;

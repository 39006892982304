import { SVGProps } from 'react';

const EmailIcon = ({ className }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.41602 16.875V3.125H18.5827V16.875H1.41602ZM9.99935 10.944L16.791 6.70188V4.91667L9.99935 9.16667L3.20768 4.91667V6.70188L9.99935 10.944Z"
        fill="white"
        className={className}
      />
    </svg>
  );
};

export default EmailIcon;

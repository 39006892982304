import clsx from 'clsx';

import { ITableProps } from './Table.types';

const FourSeatedSquareTable = ({
  primaryColor = '#3F394D',
  secondaryColor = '#B3ADC1',
  disabled,
}: ITableProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={73}
      height={72}
      fill="none"
      className={clsx(disabled && 'opacity-50')}
    >
      <rect width={24} height={8} x={24.438} fill={secondaryColor} rx={2} />
      <rect
        width={8}
        height={24}
        x={0.438}
        y={24}
        fill={secondaryColor}
        rx={2}
      />
      <rect
        width={8}
        height={24}
        x={64.438}
        y={24}
        fill={secondaryColor}
        rx={2}
      />
      <rect
        width={24}
        height={8}
        x={24.438}
        y={64}
        fill={secondaryColor}
        rx={2}
      />
      <rect
        width={64}
        height={64}
        x={4.438}
        y={4}
        fill={primaryColor}
        stroke={secondaryColor}
        strokeWidth={2}
        rx={8}
      />
    </svg>
  );
};

export default FourSeatedSquareTable;

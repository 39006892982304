import z from 'zod';

import { ReservationTableItemSchema } from './ReservationSchemas';

export const ListMessagesSchema = z.object({
  successCode: z.string(),
  data: z.array(
    z.object({
      createdAt: z.string(),
      id: z.string(),
      isAdminSeen: z.boolean(),
      isUserSeen: z.boolean(),
      message: z.string(),
      placeId: z.string(),
      reservationId: z.string(),
      sendByAdmin: z.boolean(),
      updatedAt: z.string(),
      userId: z.string(),
    }),
  ),
});

export const SendMessageSchema = z.object({
  successCode: z.string(),
  data: z.object({
    createdAt: z.string(),
    id: z.string(),
    isAdminSeen: z.boolean(),
    isUserSeen: z.boolean(),
    message: z.string(),
    placeId: z.string(),
    reservationId: z.string(),
    sendByAdmin: z.boolean(),
    updatedAt: z.string(),
    userId: z.string(),
  }),
});

export const MarkMessagesAsSeenSchema = z.object({
  successCode: z.string(),
  data: z.boolean(),
});

export const ChatReservationSchema = z.object({
  id: z.string(),
  ReservationTable: z.array(ReservationTableItemSchema),
  fullName: z.string(),
  dateStart: z.string(),
  createdAt: z.string(),
  countPerson: z.number(),
});

import { ArrowRightIcon } from '@mui/x-date-pickers';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ButtonWithIcon from '../../molecules/ButtonWithIcon/ButtonWithIcon';
import PlusIcon from '../../../../assets/icons/PlusIcon';
import CopyIcon from '../../../../assets/icons/CopyIcon';
import Button from '../../molecules/Button/Button';

const GuestDetailsHeaderSkeleton = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex w-full items-center">
        <span className="cursor-pointer text-sm font-medium text-brand-1700 hover:text-white">
          {t('messages.guestBook')}
        </span>

        <span className="xl:mx-4">
          <ArrowRightIcon />
        </span>
        <span className="h-5 w-24 animate-pulse rounded-xs bg-brand-700 text-sm font-medium" />
      </div>

      <div className="mt-6 flex justify-between">
        <span className="h-12 w-48 animate-pulse rounded-xs bg-brand-700" />
        <ButtonWithIcon
          type="button"
          text={t('messages.addReservation')}
          variant="primary"
          icon={PlusIcon}
          isJustIconOnMobile
          isDisabled
        />
      </div>

      <div className="mt-4 flex flex-wrap items-center gap-6 text-sm md:text-base">
        <span className="h-5 w-[300px] animate-pulse rounded-xs bg-brand-300" />
        <span className="flex gap-2">
          <span className="h-5 w-[200px] animate-pulse rounded-xs bg-brand-300" />

          <CopyIcon fill="#1a1720" />
        </span>
        <span className="flex gap-2">
          <span className="h-5 w-[200px] animate-pulse rounded-xs bg-brand-300" />
          <CopyIcon fill="#1a1720" />
        </span>
      </div>

      <div className="mt-6 flex gap-4 text-sm">
        <Button
          type="button"
          text={t('messages.guestData')}
          variant="tertiary"
          isDisabled
          //   variant={isDetailsPage ? 'secondary' : 'tertiary'}
          //   onClick={handleNavigateToDetailsPage}
        />
        <Button
          type="button"
          text={t('messages.reservationHistory')}
          variant="tertiary"
          isDisabled
          //   variant={isReservationsPage ? 'secondary' : 'tertiary'}
          //   onClick={handleNavigateToReservationsPage}
        />
      </div>
    </>
  );
};

export default GuestDetailsHeaderSkeleton;

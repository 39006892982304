import { useTranslation } from 'react-i18next';

import { IChatReservationConfirmation } from '../../../../types/Chat';
import { getDateLabel } from '../../../../functions/functions';

const ChatReservationConfirmation = ({
  hour,
  countPerson,
  date,
  userFullName,
}: IChatReservationConfirmation) => {
  const { t } = useTranslation();

  const dateLabel = getDateLabel(date);

  return (
    <div className="flex justify-center">
      <p className="flex w-fit items-center rounded-[9px] border-2 border-brand-700 p-[10px] text-sm font-medium text-brand-1700 md:h-10 md:px-4 md:py-[14px] md:text-center md:text-sm">
        {t('messages.reservationConfirmation', {
          hour,
          countPerson,
          dateLabel,
          userFullName,
        })}
      </p>
    </div>
  );
};

export default ChatReservationConfirmation;

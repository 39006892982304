import clsx from 'clsx';

import { ITableProps } from './Table.types';

const EightSeatedRectangleTable = ({
  primaryColor = '#3F394D',
  secondaryColor = '#B3ADC1',
  disabled,
}: ITableProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={139}
      height={56}
      fill="none"
      className={clsx(disabled && 'opacity-50')}
    >
      <rect
        width={24}
        height={8}
        x={9.438}
        y={48}
        fill={secondaryColor}
        rx={1.778}
      />
      <rect
        width={24}
        height={8}
        x={41.438}
        y={48}
        fill={secondaryColor}
        rx={1.778}
      />
      <rect
        width={24}
        height={8}
        x={73.438}
        y={48}
        fill={secondaryColor}
        rx={1.778}
      />
      <rect width={24} height={8} x={9.438} fill={secondaryColor} rx={1.778} />
      <rect width={24} height={8} x={41.438} fill={secondaryColor} rx={1.778} />
      <rect width={24} height={8} x={73.438} fill={secondaryColor} rx={1.778} />
      <rect
        width={24}
        height={8}
        x={105.438}
        fill={secondaryColor}
        rx={1.778}
      />
      <rect
        width={24}
        height={8}
        x={105.438}
        y={48}
        fill={secondaryColor}
        rx={1.778}
      />
      <rect
        width={137}
        height={48}
        x={1}
        y={4}
        fill={primaryColor}
        stroke={secondaryColor}
        strokeWidth={2}
        rx={8}
      />
    </svg>
  );
};

export default EightSeatedRectangleTable;

import InputArea from '../InputArea/InputArea';
import { IInputSearchProps } from './InputSearch.types';

const InputSearch = ({
  name,
  placeholder,
  value,
  variant,
  onChange,
  isSearchInputUnrolls,
}: IInputSearchProps) => {
  return (
    <InputArea
      placeholder={placeholder}
      type="text"
      name={name}
      value={value}
      variant={variant}
      onChange={onChange}
      isError={false}
      isSearchInput
      isSearchInputUnrolls={isSearchInputUnrolls}
    />
  );
};

export default InputSearch;

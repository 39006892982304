/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import TableMobile from '../../UI/atoms/TableMobile/TableMobile';
import TableTablet from '../../UI/atoms/TableTablet/TableTablet';
import Table from '../../UI/atoms/Table/Table';
import PaginationSelectorCount from '../../UI/molecules/PaginationSelectorCount/PaginationSelectorCount';
import PaginationButtons from '../../UI/atoms/PaginationButtons/PaginationButtons';
import { BREAKPOINTS, reduxStatus, routes } from '../../../constants/constants';
import useMobile from '../../../hooks/useIsMobile';
import PenIcon from '../../../assets/icons/PenIcon';
import TableRowText from '../../UI/atoms/TableRowText/TableRowText';
import TrashIcon from '../../../assets/icons/TrashIcon';
import { IDropdownOption } from '../../UI/atoms/MoreOptionsDropdown/MoreOptionsDropdown.types';
import TableRowName from '../../UI/atoms/TableRowName/TableRowName';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import MoreOptionsDropdown from '../../UI/atoms/MoreOptionsDropdown/MoreOptionsDropdown';
import TableRowRoundedTag from '../../UI/atoms/TableRowRoundedTag/TableRowRoundedTag';
import PrimaryHeader from '../../UI/atoms/PrimaryHeader/PrimaryHeader';
import SearchBar from '../../UI/molecules/SearchBar/SearchBar';
import ButtonWithIcon from '../../UI/molecules/ButtonWithIcon/ButtonWithIcon';
import PlusIcon from '../../../assets/icons/PlusIcon';
import useModal from '../../../hooks/useModal';
import GuestProfileModal from '../../UI/molecules/GuestProfileModal/GuestProfileModal';
import {
  deleteClientThunk,
  getClientThunk,
  listClientsThunk,
  listClientsTotalThunk,
  searchClientsThunk,
  searchClientsTotalThunk,
} from '../../../redux/thunks/guestBookThunk';
import { TListClient } from '../../../types/GuestBook';
import {
  resetDeleteGuestStatus,
  setSingleGuest,
} from '../../../redux/features/GuestBookSlice';
import ConfirmModal from '../../UI/molecules/ConfirmModal/ConfirmModal';
import { getZonedTimeFromIsoDateAndFormat } from '../../../functions/functions';
import PageEmptyState from '../../UI/molecules/PageEmptyState/PageEmptyState';
import useIsBreakpoint from '../../../hooks/useIsBreakpoint';
import DataEmptyState from '../../UI/atoms/DataEmptyState/DataEmptyState';

const GuestBookPage = () => {
  const { t } = useTranslation();

  const isMobile = useMobile();

  const isTablet = useIsBreakpoint(BREAKPOINTS.xlToXxl);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.user.user);

  const placeTimezone = useAppSelector((state) => state.settings.timezone);

  const guestsList = useAppSelector((state) => state.guestBook.guestsList);

  const total = useAppSelector((state) => state.guestBook.guestsListTotal);

  const searchTotal = useAppSelector((state) => state.guestBook.searchTotal);

  const singleGuest = useAppSelector((state) => state.guestBook.singleGuest);

  const deleteGuestStatus = useAppSelector(
    (state) => state.guestBook.deleteGuestStatus,
  );

  const listClientsStatus = useAppSelector(
    (state) => state.guestBook.listClientsStatus,
  );
  const listClientsTotalStatus = useAppSelector(
    (state) => state.guestBook.listClientsTotalStatus,
  );

  const isLoading =
    listClientsStatus === reduxStatus.loading ||
    listClientsTotalStatus === reduxStatus.loading;

  const isEmptyState = !isLoading && total === 0;

  const isEmployee = user?.role === 'EMPLOYEE';

  const [isSearchActive, setIsSearchActive] = useState<boolean>(false);

  const [searchInput, setSearchInput] = useState<string>('');

  const [currentPage, setCurrentPage] = useState<number>(0);

  const [pageLimit, setPageLimit] = useState<number>(10);

  const [sortedField, setSortedField] = useState<string>('');

  const [sortedDirection, setSortedDirection] = useState<string>('');

  const avoidedFieldsWhenSorting = [
    'tags',
    'lastVisit',
    'nextVisit',
    'firstVisit',
    'upcomingReservations',
    ...(isSearchActive ? ['guestPhoneNumber', 'guestEmail', 'guestName'] : []),
  ];

  const {
    visible: isGuestModalVisible,
    showModal: showGuestModal,
    hideModal: hideGuestModal,
  } = useModal();

  const {
    visible: isEditGuestModalVisible,
    showModal: showEditGuestModal,
    hideModal: hideEditGuestModal,
  } = useModal();

  const {
    visible: isDeleteClientModalVisible,
    showModal: showDeleteClientModal,
    hideModal: hideDeleteClientModal,
  } = useModal();

  const handleSearch = (query: string) => {
    if (query !== searchInput) {
      setCurrentPage(0);
      setSearchInput(query);
    }
  };

  const handleSetPageLimit = (limit: number) => {
    setPageLimit(limit);
    setCurrentPage(0);
  };

  const nextPage = () => {
    if (
      (currentPage + 1) * pageLimit <
      (isSearchActive ? searchTotal : total)
    ) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleDeleteClient = () => {
    if (!user?.placeId || !singleGuest) return;
    dispatch(
      deleteClientThunk({
        id: singleGuest.id,
        callback: hideEditGuestModal,
      }),
    );
  };

  const handleCloseDeleteCouponModal = () => {
    hideDeleteClientModal();
    dispatch(resetDeleteGuestStatus());
  };

  const listGuestsData = useMemo(() => {
    if (isEmpty(guestsList)) return;

    const handleEdit = (client: TListClient) => {
      if (!user) return;
      dispatch(getClientThunk(client.id));
      showEditGuestModal();
    };

    const handleDeleteModal = (client: TListClient) => {
      dispatch(setSingleGuest(client));
      showDeleteClientModal();
    };

    return guestsList.slice(0, pageLimit).map((guest: TListClient) => {
      const {
        id,
        email,
        firstReservation,
        firstVisit,
        lastReservationDate,
        incomingReservations,
        nextVisit,
        firstName,
        lastName,
        phoneNumber,
        tags,
      } = guest;

      const dropdownOptions: IDropdownOption[] = [
        {
          label: t('messages.preview'),
          onClick: () => navigate(`${routes.guestBook}/${id}/details`),
        },
        {
          label: t('messages.edit'),
          icon: PenIcon,
          onClick: () => handleEdit(guest),
        },
        {
          label: t('messages.delete'),
          icon: TrashIcon,
          onClick: () => handleDeleteModal(guest),
          isDelete: true,
        },
      ];

      return {
        ...guest,
        guestName: (
          <div
            className="flex w-full cursor-pointer justify-between"
            onClick={() => navigate(`${routes.guestBook}/${id}/details`)}
          >
            <TableRowName key={guest.id} text={`${firstName} ${lastName}`} />
            {isTablet && !isEmployee && (
              <MoreOptionsDropdown options={dropdownOptions} />
            )}
          </div>
        ),
        guestPhoneNumber: <TableRowText text={phoneNumber} />,
        guestEmail: <TableRowText text={email || '-'} />,
        tags: <TableRowText text={tags.length > 0 ? tags.join(', ') : '-'} />,
        lastVisit: (
          <TableRowText
            text={
              lastReservationDate
                ? getZonedTimeFromIsoDateAndFormat(
                    lastReservationDate,
                    placeTimezone,
                  )
                : '-'
            }
          />
        ),
        nextVisit: (
          <>
            {nextVisit ? (
              <TableRowRoundedTag>
                {getZonedTimeFromIsoDateAndFormat(nextVisit, placeTimezone)}
              </TableRowRoundedTag>
            ) : (
              '-'
            )}
          </>
        ),
        firstVisit: (
          <TableRowText
            text={
              firstVisit
                ? getZonedTimeFromIsoDateAndFormat(firstVisit, placeTimezone)
                : '-'
            }
          />
        ),
        upcomingReservations: (
          <div className="flex items-center justify-between gap-4 truncate">
            <TableRowText text={incomingReservations.toString()} />
            {!isTablet && !isEmployee && (
              <MoreOptionsDropdown options={dropdownOptions} />
            )}
          </div>
        ),
      };
    });
  }, [
    dispatch,
    guestsList,
    isEmployee,
    isTablet,
    navigate,
    pageLimit,
    placeTimezone,
    showDeleteClientModal,
    showEditGuestModal,
    t,
    user,
  ]);

  const listGuestsSkeleton = useMemo(() => {
    return [...Array(5)].map(() => {
      return {
        guestName: (
          <span className="flex h-6 w-36 animate-pulse rounded-xs bg-brand-700" />
        ),
        guestPhoneNumber: (
          <span className="flex h-5 w-28 animate-pulse rounded-xs bg-brand-300" />
        ),
        guestEmail: (
          <span className="flex h-5 w-32 animate-pulse rounded-xs bg-brand-300" />
        ),
        tags: (
          <span className="flex h-5 w-40 animate-pulse rounded-xs bg-brand-300" />
        ),
        lastVisit: (
          <span className="flex h-5 w-24 animate-pulse rounded-xs bg-brand-300" />
        ),
        nextVisit: (
          <span className="flex h-6 w-20 animate-pulse rounded-full bg-brand-700" />
        ),
        firstVisit: (
          <span className="flex h-5 w-24 animate-pulse rounded-xs bg-brand-300" />
        ),
        upcomingReservations: (
          <span className="flex h-5 w-5 animate-pulse rounded-xs bg-brand-300" />
        ),
      };
    });
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: t('messages.name'),
        accessor: 'guestName',
      },
      {
        Header: t('messages.phoneNumber'),
        accessor: 'guestPhoneNumber',
      },
      {
        Header: t('messages.email'),
        accessor: 'guestEmail',
      },
      {
        Header: t('messages.tags'),
        accessor: 'tags',
      },
      {
        Header: t('messages.lastVisit'),
        accessor: 'lastVisit',
      },
      {
        Header: t('messages.nextVisit'),
        accessor: 'nextVisit',
      },
      {
        Header: t('messages.firstVisit'),
        accessor: 'firstVisit',
      },
      {
        Header: t('messages.upcomingRes'),
        accessor: 'upcomingReservations',
      },
    ],

    [t],
  );

  useEffect(() => {
    if (searchInput !== '') {
      setIsSearchActive(true);
    } else {
      setIsSearchActive(false);
    }
  }, [searchInput]);

  useEffect(() => {
    if (isSearchActive) {
      dispatch(
        searchClientsThunk({
          limit: pageLimit,
          offset: currentPage * pageLimit,
          email: searchInput,
          name: searchInput,
          phoneNumber: searchInput,
          tag: searchInput,
        }),
      );
    }
  }, [currentPage, dispatch, isSearchActive, pageLimit, searchInput]);

  useEffect(() => {
    if (isSearchActive) {
      dispatch(
        searchClientsTotalThunk({
          email: searchInput,
          name: searchInput,
          phoneNumber: searchInput,
          tag: searchInput,
        }),
      );
    }
  }, [dispatch, isSearchActive, searchInput]);

  useEffect(() => {
    if (!isSearchActive) {
      dispatch(
        listClientsThunk({
          limit: pageLimit,
          offset: currentPage * pageLimit,
          sortBy: sortedField,
          order: sortedDirection,
        }),
      );
    }
  }, [
    currentPage,
    dispatch,
    isSearchActive,
    pageLimit,
    sortedDirection,
    sortedField,
  ]);

  useEffect(() => {
    if (!isSearchActive) {
      dispatch(listClientsTotalThunk());
    }
  }, [dispatch, isSearchActive]);
  return (
    <div className="flex h-full w-full flex-col">
      <GuestProfileModal
        visible={isGuestModalVisible}
        onClose={hideGuestModal}
        showDeleteClientModal={showDeleteClientModal}
      />
      <GuestProfileModal
        isEdit
        visible={isEditGuestModalVisible}
        onClose={hideEditGuestModal}
        showDeleteClientModal={showDeleteClientModal}
      />
      <ConfirmModal
        visible={isDeleteClientModalVisible}
        onClose={handleCloseDeleteCouponModal}
        questionText={t('messages.areYouSureYouWantToDeleteThisItem')}
        confirmText={t('messages.itemDeletedSuccessfully')}
        confirmAction={handleDeleteClient}
        loadingStatus={deleteGuestStatus}
      />

      <div className="flex w-full flex-col gap-6 md:min-h-[42px] md:flex-row md:justify-between">
        <div className="flex w-full justify-between gap-4">
          <PrimaryHeader text={t('messages.guestBook')} />
          {isMobile && !isEmployee && !isEmptyState && (
            <ButtonWithIcon
              type="button"
              text={t('messages.addGuest')}
              variant="primary"
              icon={PlusIcon}
              onClick={showGuestModal}
              isJustIconOnMobile
            />
          )}
        </div>
        {!isEmptyState && (
          <div className="flex gap-4">
            <SearchBar
              onSearch={handleSearch}
              placeholder={t('messages.searchGuest')}
              isSearchInputUnrolls
            />
            {!isMobile && !isEmployee && (
              <ButtonWithIcon
                type="button"
                text={t('messages.addGuest')}
                variant="primary"
                icon={PlusIcon}
                onClick={showGuestModal}
              />
            )}
          </div>
        )}
      </div>
      {isEmptyState ? (
        <PageEmptyState
          headerText={t('messages.yourGuestBookIsStillEmpty')}
          paragraphText={t('messages.profilesWillBeAutomaticallyGenerated')}
          buttonText={t('messages.addGuest')}
          onButtonClick={showGuestModal}
          isButtonDisabled={isEmployee}
        />
      ) : (
        <>
          {isSearchActive && searchTotal === 0 && !isLoading ? (
            <DataEmptyState
              title={t('messages.noResultsMatchYourRequirements')}
              description={t('messages.weCouldntFindWhatYouAreLookingFor')}
            />
          ) : (
            <div className="relative mt-6 flex h-[calc(100%-100px)] w-full overflow-auto pr-2">
              {isMobile && (
                <TableMobile
                  columns={columns}
                  data={isLoading ? listGuestsSkeleton : listGuestsData}
                  variant="coupon"
                />
              )}
              {isTablet && !isMobile && (
                <TableTablet
                  columns={columns}
                  data={isLoading ? listGuestsSkeleton : listGuestsData}
                  setSortField={() => {}}
                  setSortDirection={() => {}}
                  isTheadDisabled
                  columnWidth={140}
                />
              )}
              {!isTablet && (
                <Table
                  columns={columns}
                  data={isLoading ? listGuestsSkeleton : listGuestsData}
                  setSortField={setSortedField}
                  setSortDirection={setSortedDirection}
                  numberOfColumns={columns.length}
                  avoidedFieldsWhenSorting={avoidedFieldsWhenSorting}
                />
              )}
            </div>
          )}
          <footer className="flex justify-between border-t border-brand-700 pt-4">
            <PaginationSelectorCount
              total={isSearchActive ? searchTotal : total}
              pageLimit={pageLimit}
              setPageLimit={handleSetPageLimit}
              currentPage={currentPage}
            />
            <PaginationButtons
              isLoading={isLoading}
              nextPage={nextPage}
              prevPage={prevPage}
              hasNextPage={
                (currentPage + 1) * pageLimit <
                (isSearchActive ? searchTotal : total)
              }
              hasPreviousPage={currentPage > 0}
            />
          </footer>
        </>
      )}
    </div>
  );
};

export default GuestBookPage;

import { SVGProps } from 'react';

const HandsetIcon = ({ className }: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={17} height={16} fill="none">
    <path
      fill="#fff"
      className={className}
      d="M14.688 15.75q-2.345 0-4.815-1.185T5.26 11.239Q3.128 9.097 1.94 6.615T.75 1.813V.25h5.141l.801 4.378-2.436 2.41q.473.818 1.05 1.57.575.752 1.208 1.394.616.63 1.332 1.17.717.537 1.545 1.01l2.526-2.46 4.333.929v5.099z"
    />
  </svg>
);
export default HandsetIcon;

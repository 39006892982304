import clsx from 'clsx';

import { ITableProps } from './Table.types';

const TwoSeatedCircleTable = ({
  primaryColor = '#3F394D',
  secondaryColor = '#B3ADC1',
  disabled,
}: ITableProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={66}
      height={72}
      fill="none"
      className={clsx(disabled && 'opacity-50')}
    >
      <path
        fill={secondaryColor}
        fillRule="evenodd"
        d="M35.782 66.752a36 36 0 0 0 2.768-.319 35.4 35.4 0 0 0 5.88-1.429 35 35 0 0 0 5.055-2.158q.096.194.178.399c1.052 2.669-.438 5.62-3.328 6.592A41.8 41.8 0 0 1 33 72a41.8 41.8 0 0 1-13.335-2.163c-2.89-.972-4.38-3.923-3.328-6.592q.081-.205.178-.4a34.4 34.4 0 0 0 5.055 2.16 35.4 35.4 0 0 0 5.88 1.428 36 36 0 0 0 5.55.424q1.406 0 2.782-.105M16.515 9.154a5 5 0 0 1-.178-.399c-1.052-2.669.438-5.62 3.328-6.592A41.8 41.8 0 0 1 33 0c4.674 0 9.167.762 13.335 2.163 2.89.972 4.38 3.923 3.328 6.592q-.081.205-.178.4a34.4 34.4 0 0 0-5.055-2.16 35.4 35.4 0 0 0-5.88-1.428A36 36 0 0 0 33 5.143a36.7 36.7 0 0 0-5.55.424 35.4 35.4 0 0 0-5.88 1.429 35 35 0 0 0-5.055 2.158"
        clipRule="evenodd"
      />
      <path
        fill={primaryColor}
        stroke={secondaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M1 36C1 18.327 15.327 4 33 4s32 14.327 32 32-14.327 32-32 32S1 53.673 1 36"
      />
    </svg>
  );
};

export default TwoSeatedCircleTable;

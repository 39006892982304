import { useTranslation } from 'react-i18next';

import ButtonWithIcon from '../../molecules/ButtonWithIcon/ButtonWithIcon';
import HandsetIcon from '../../../../assets/icons/HandsetIcon';
import EmailIcon from '../../../../assets/icons/EmailIcon';
import PenIcon from '../../../../assets/icons/PenIcon';
import TrashIcon from '../../../../assets/icons/TrashIcon';

const GuestDetailsExtraInformationSkeleton = () => {
  const { t } = useTranslation();

  return (
    <>
      {/* TAGS */}
      <div className="rounded-xs border border-brand-700 p-6">
        <span className="text-lg font-semibold">{t('messages.tags')}</span>
        <div className="mt-4 flex flex-wrap gap-2">
          <span className="h-8 w-[50px] animate-pulse rounded-xs bg-brand-700" />
          <span className="h-8 w-[166px] animate-pulse rounded-xs bg-brand-700" />
          <span className="h-8 w-[82px] animate-pulse rounded-xs bg-brand-700" />
          <span className="h-8 w-[183px] animate-pulse rounded-xs bg-brand-700" />
          <span className="h-8 w-[125px] animate-pulse rounded-xs bg-brand-700" />
          <span className="h-8 w-[187px] animate-pulse rounded-xs bg-brand-700" />
        </div>
      </div>

      {/* Notes */}
      <div className="flex flex-col gap-4 rounded-xs border border-brand-700 p-6">
        <span className="text-lg font-semibold">{t('messages.notes')}</span>
        <span className="h-6 w-[50%] animate-pulse rounded-xs bg-brand-300" />
      </div>

      {/* LANGUGAGE */}
      <div className="flex flex-col gap-4 rounded-xs border border-brand-700 p-6">
        <span className="text-lg font-semibold">
          {t('messages.guestsPreferredLanguage')}
        </span>
        <span className="h-6 w-[30%] animate-pulse rounded-xs bg-brand-300" />
      </div>

      <div className="flex w-full flex-col gap-6 xl:flex-row">
        <div className="flex w-full flex-col gap-4 rounded-xs border border-brand-700 p-6">
          <span className="text-lg font-semibold">{t('messages.contact')}</span>
          <div className="flex flex-col gap-4 sm:flex-row">
            <ButtonWithIcon
              type="button"
              text={t('messages.supportModule.call')}
              variant="primary"
              icon={HandsetIcon}
              isDisabled
              isFullWidthOnDesktop
              isFullWidthOnMobile
            />
            <ButtonWithIcon
              type="button"
              text={t('messages.sendEmail')}
              variant="primary"
              icon={EmailIcon}
              isDisabled
              isFullWidthOnDesktop
              isFullWidthOnMobile
            />
          </div>
        </div>
        <div className="flex w-full flex-col gap-4 rounded-xs border border-brand-700 p-6">
          <span className="text-lg font-semibold">
            {t('messages.profileManagement')}
          </span>
          <div className="flex flex-col gap-4 sm:flex-row">
            <ButtonWithIcon
              type="button"
              text={t('messages.edit')}
              variant="primary"
              icon={PenIcon}
              isFullWidthOnDesktop
              isFullWidthOnMobile
              isDisabled
            />
            <ButtonWithIcon
              type="button"
              text={t('messages.delete')}
              variant="primary"
              icon={TrashIcon}
              isFullWidthOnDesktop
              isFullWidthOnMobile
              isDisabled
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default GuestDetailsExtraInformationSkeleton;

import { FC } from 'react';
import clsx from 'clsx';

import { IChatMessageProps } from './ChatMessage.types';

const ChatMessage: FC<IChatMessageProps> = ({
  message,
  messageText,
  author,
  isMyMessage,
}) => {
  const messageDate = new Date(message.createdAt);
  const formattedTime = messageDate.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });

  return (
    <div className="flex gap-[5px]">
      <div
        className={clsx(
          'flex w-full flex-col gap-[5px]',
          isMyMessage ? 'items-end' : 'items-start',
        )}
      >
        {!isMyMessage ? (
          <p className="text-xs font-semibold md:text-base">{author}</p>
        ) : null}
        <div
          className={clsx(
            'max-w-[80%] rounded-xs px-5 py-4',
            isMyMessage
              ? 'rounded-tr-none bg-brand-300'
              : 'rounded-tl-none bg-brand-500',
          )}
        >
          <p className="text-base font-normal text-white">{messageText}</p>
        </div>
        <p className="text-xs font-semibold text-brand-1700">{formattedTime}</p>
      </div>
    </div>
  );
};

export default ChatMessage;

const SendIcon = () => {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.95 19.725v-5.977l7.523-1.848-7.523-1.848V4.125l18.361 7.8-18.361 7.8z"
        fill="#B3ADC1"
      />
    </svg>
  );
};

export default SendIcon;

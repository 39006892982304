import { createSlice } from '@reduxjs/toolkit';
import { isBefore } from 'date-fns';

import {
  IGuestBookSliceState,
  TClientReservation,
} from '../../types/GuestBook';
import {
  createClientThunk,
  deleteClientThunk,
  getClientThunk,
  listClientReservationsThunk,
  listClientReservationsTotalThunk,
  listClientsThunk,
  listClientsTotalThunk,
  searchClientsThunk,
  searchClientsTotalThunk,
  updateClientThunk,
} from '../thunks/guestBookThunk';
import {
  createReservationThunk,
  updateReservationThunk,
} from '../thunks/reservationThunk';

const initialState: IGuestBookSliceState = {
  guestsList: [],
  listClientsStatus: null,
  guestsListTotal: 0,
  searchTotal: 0,
  listClientsTotalStatus: null,
  createGuestStatus: null,
  updateGuestStatus: null,
  deleteGuestStatus: null,
  singleGuest: null,
  singleGuestStatus: null,
  reservationsList: [],
  listClientsReservationsStatus: null,
  reservationsListTotal: 0,
  listClientsReservationsTotalStatus: null,
};

export const guestBookSlice = createSlice({
  name: 'guestBook',
  initialState,
  reducers: {
    setSingleGuest: (state, { payload }) => {
      state.singleGuest = payload;
    },
    resetDeleteGuestStatus: (state) => {
      state.deleteGuestStatus = null;
    },
    resetChatMessageCount: (state) => {
      if (state.singleGuest?.nextReservation) {
        state.singleGuest.nextReservation.messageCount = 0;
      }
    },
  },
  extraReducers: (builder) => {
    // listGuests
    builder
      .addCase(listClientsThunk.pending, (state) => {
        state.listClientsStatus = 'loading';
      })
      .addCase(listClientsThunk.fulfilled, (state, { payload }) => {
        state.guestsList = payload;
        state.listClientsStatus = 'success';
      })
      .addCase(listClientsThunk.rejected, (state) => {
        state.listClientsStatus = 'failed';
      });

    // listGuestsTotal
    builder
      .addCase(listClientsTotalThunk.pending, (state) => {
        state.listClientsTotalStatus = 'loading';
      })
      .addCase(listClientsTotalThunk.fulfilled, (state, { payload }) => {
        state.guestsListTotal = payload;
        state.listClientsTotalStatus = 'success';
      })
      .addCase(listClientsTotalThunk.rejected, (state) => {
        state.listClientsTotalStatus = 'failed';
      });

    // searchGuests
    builder
      .addCase(searchClientsThunk.pending, (state) => {
        state.listClientsStatus = 'loading';
      })
      .addCase(searchClientsThunk.fulfilled, (state, { payload }) => {
        state.guestsList = payload;
        state.listClientsStatus = 'success';
      })
      .addCase(searchClientsThunk.rejected, (state) => {
        state.listClientsStatus = 'failed';
      });

    // searchGuestsTotal
    builder
      .addCase(searchClientsTotalThunk.pending, (state) => {
        state.listClientsTotalStatus = 'loading';
      })
      .addCase(searchClientsTotalThunk.fulfilled, (state, { payload }) => {
        state.searchTotal = payload;
        state.listClientsTotalStatus = 'success';
      })
      .addCase(searchClientsTotalThunk.rejected, (state) => {
        state.listClientsTotalStatus = 'failed';
      });

    // listGuestReservations
    builder
      .addCase(listClientReservationsThunk.pending, (state) => {
        state.listClientsReservationsStatus = 'loading';
      })
      .addCase(listClientReservationsThunk.fulfilled, (state, { payload }) => {
        state.reservationsList = payload;
        state.listClientsReservationsStatus = 'success';
      })
      .addCase(listClientReservationsThunk.rejected, (state) => {
        state.listClientsReservationsStatus = 'failed';
      });

    // listGuestReservationsTotal
    builder
      .addCase(listClientReservationsTotalThunk.pending, (state) => {
        state.listClientsReservationsTotalStatus = 'loading';
      })
      .addCase(
        listClientReservationsTotalThunk.fulfilled,
        (state, { payload }) => {
          state.reservationsListTotal = payload;
          state.listClientsReservationsTotalStatus = 'success';
        },
      )
      .addCase(listClientReservationsTotalThunk.rejected, (state) => {
        state.listClientsReservationsTotalStatus = 'failed';
      });

    builder.addCase(createReservationThunk.fulfilled, (state, { payload }) => {
      const newReservation: TClientReservation = {
        id: payload.newReservation.id,
        ReservationTable: [
          { name: payload.newReservation.formattedTableNames },
        ],
        status: 'NEW',
        countPerson: payload.newReservation.countPerson,
        dateStart: payload.newReservation.dateStart,
      };

      const newReservationsList = [...state.reservationsList, newReservation];

      newReservationsList.sort(
        (a, b) =>
          new Date(b.dateStart).getTime() - new Date(a.dateStart).getTime(),
      );

      state.reservationsList = newReservationsList;
      if (
        state.singleGuest &&
        (!state.singleGuest?.nextReservation ||
          (state.singleGuest?.nextReservation &&
            isBefore(
              new Date(newReservation.dateStart),
              new Date(state.singleGuest?.nextReservation?.dateStart),
            )))
      ) {
        state.singleGuest.nextReservation = {
          ...newReservation,
          messageCount: 0,
        };
      }
    });

    // getGuest
    builder
      .addCase(getClientThunk.pending, (state) => {
        state.singleGuestStatus = 'loading';
      })
      .addCase(getClientThunk.fulfilled, (state, { payload }) => {
        state.singleGuest = payload;
        state.singleGuestStatus = 'success';
      })
      .addCase(getClientThunk.rejected, (state) => {
        state.singleGuestStatus = 'failed';
      });

    // createGuest
    builder
      .addCase(createClientThunk.pending, (state) => {
        state.createGuestStatus = 'loading';
      })
      .addCase(createClientThunk.fulfilled, (state, { payload }) => {
        if (!payload) return;

        state.guestsList = [payload, ...state.guestsList];
        state.guestsListTotal += 1;
        state.createGuestStatus = 'success';
      })
      .addCase(createClientThunk.rejected, (state) => {
        state.createGuestStatus = 'failed';
      });

    // updateGuest
    builder
      .addCase(updateClientThunk.pending, (state) => {
        state.updateGuestStatus = 'loading';
      })
      .addCase(updateClientThunk.fulfilled, (state, { payload }) => {
        if (!payload) return;

        state.guestsList = state.guestsList.map((guest) => {
          if (guest.id === payload.id) {
            return { ...guest, ...payload };
          }
          return guest;
        });

        state.singleGuest = {
          ...state.singleGuest,
          ...payload,
          countReservations: state.singleGuest?.countReservations || {
            cancelled: 0,
            done: 0,
            planned: 0,
          },
          lastReservationDate: state.singleGuest?.lastReservationDate || null,
          firstReservation: state.singleGuest?.firstReservation || null,
          nextReservation: state.singleGuest?.nextReservation || null,
        };

        state.updateGuestStatus = 'success';
      })
      .addCase(updateClientThunk.rejected, (state) => {
        state.updateGuestStatus = 'failed';
      });

    // deleteGuest
    builder
      .addCase(deleteClientThunk.pending, (state) => {
        state.deleteGuestStatus = 'loading';
      })
      .addCase(deleteClientThunk.fulfilled, (state, { payload }) => {
        if (!payload) return;

        state.guestsList = state.guestsList.filter(
          (guest) => guest.id !== payload,
        );

        if (state.singleGuest && state.singleGuest.id === payload) {
          state.singleGuest = null;
        }

        state.guestsListTotal -= 1;
        state.deleteGuestStatus = 'success';
      })
      .addCase(deleteClientThunk.rejected, (state) => {
        state.deleteGuestStatus = 'failed';
      });

    // update next visit
    builder.addCase(updateReservationThunk.fulfilled, (state, { payload }) => {
      if (!payload) return;
      if (
        payload.updatedReservation.id === state.singleGuest?.nextReservation?.id
      ) {
        state.singleGuest.nextReservation = {
          ...payload.updatedReservation,
          messageCount: state.singleGuest.nextReservation.messageCount,
        };
      }
    });
  },
});

export const { setSingleGuest, resetDeleteGuestStatus, resetChatMessageCount } =
  guestBookSlice.actions;

export default guestBookSlice.reducer;

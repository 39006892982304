import { useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { IInputAreaProps } from './InputArea.types';
import { getVariation } from '../../../../functions/functions';
import SearchIcon from '../../../../assets/icons/SearchIcon';
import EyeClosedIcon from '../../../../assets/icons/EyeClosedIcon';
import EyeOpenIcon from '../../../../assets/icons/EyeOpenedIcon';

const InputArea = ({
  type,
  name,
  placeholder,
  value,
  variant,
  onChange,
  isError,
  onFocus,
  onBlur,
  onClick,
  isLoading,
  isDisabled,
  isDateInput,
  isSearchInput,
  isReadOnly = false,
  maxLength,
  isNumberOfPeoplePicker,
  turnOffAutocomplete,
  prefixLabel,
  isSearchInputUnrolls,
}: IInputAreaProps) => {
  const { t } = useTranslation();

  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  const [isSearchVisible, setIsSearchVisible] = useState<boolean>(false);

  const formatedValue =
    isNumberOfPeoplePicker && value
      ? `${value} ${getVariation(
          parseInt(value, 10),
          t('messages.person'),
          t('messages.persons'),
          t('messages.personsOther'),
        )}`
      : value;

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // Disable alternative view for time input that opens by clicking spacebar
    if (type === 'time' && event.key === ' ') {
      event.preventDefault();
    }
  };

  const openSearchInput = () => {
    if (isSearchInputUnrolls) {
      setIsSearchVisible(true);
    }
  };

  return (
    <div
      className={clsx(
        'relative flex w-full',
        isSearchInput && 'flex-1',
        variant === 'tertiary' &&
          'flex items-center rounded-xs border border-brand-700 focus-within:border-white',
        variant === 'tertiary' && isError && 'border-red-600',
        variant === 'quaternary' && 'border-none',
        type === 'password' &&
          'flex h-full items-center rounded-full border border-brand-700 focus-within:border-white',
        type === 'password' && isError && 'border-red-600',
      )}
    >
      {variant === 'tertiary' && (
        <span className="mr-1 flex h-full flex-col items-center justify-center pl-4 text-brand-1700">
          {prefixLabel}
        </span>
      )}
      {variant === 'quaternary' && (
        <div className="pt-2">
          <SearchIcon />
        </div>
      )}
      <input
        id={name}
        type={type === 'password' && isPasswordVisible ? 'text' : type}
        name={name}
        placeholder={placeholder}
        onClick={isSearchInputUnrolls ? openSearchInput : onClick}
        className={clsx(
          'border border-brand-700 bg-transparent text-base outline-none',
          variant === 'primary' &&
            'h-[52px] rounded-full px-5 py-2.5 xl:h-[60px] xl:text-lg',
          variant === 'secondary' && 'h-[42px] rounded-xs px-4 py-3',
          variant === 'tertiary' &&
            'h-[42px] w-full rounded-r-xs border-0 px-0 py-3',
          variant === 'quaternary' &&
            'h-[40px] w-full border-0 p-2 font-semibold',
          isError && 'border-red-600',
          isDisabled
            ? 'text-brand-1200 placeholder:text-brand-1200'
            : 'placeholder:text-brand-1700 ',
          isDateInput &&
            (isDisabled ? 'dateImageInputDisabled' : 'dateImageInput'),
          isSearchInput &&
            !isSearchInputUnrolls &&
            'searchInput pl-10 xl:max-w-[300px]',
          isSearchInput &&
            isSearchInputUnrolls &&
            !isSearchVisible &&
            'searchInput w-0 pl-[20px]',
          isSearchInput &&
            isSearchInputUnrolls &&
            isSearchVisible &&
            'searchInput min-w-[250px] pl-10',
          maxLength && value?.length > 0 && 'pr-20',
          isLoading &&
            'animate-pulse !border-brand-300 !bg-brand-300 text-brand-300',
          type === 'password' && 'border-none',
          (!isSearchInputUnrolls ||
            (isSearchInputUnrolls && isSearchVisible)) &&
            'w-full',
        )}
        value={formatedValue}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        readOnly={isReadOnly || isDisabled}
        maxLength={maxLength}
        onKeyDown={handleKeyDown}
        disabled={isDisabled}
        autoComplete={turnOffAutocomplete ? 'off' : 'on'}
      />
      {type === 'password' && (
        <span
          onClick={() => setIsPasswordVisible(!isPasswordVisible)}
          className="mr-4 cursor-pointer"
        >
          {isPasswordVisible ? <EyeClosedIcon /> : <EyeOpenIcon />}
        </span>
      )}
      {maxLength && value && !isLoading && (
        <span className="absolute bottom-3 right-4 text-sm text-brand-1700">
          {value.length}/{maxLength}
        </span>
      )}
    </div>
  );
};

export default InputArea;

import clsx from 'clsx';

import { ITableRowRoundedTagProps } from './TableRowRoundedTag.types';

const TableRowRoundedTag = ({
  children,
  isCoupon,
}: ITableRowRoundedTagProps) => {
  return (
    <div
      className={clsx(
        'flex w-fit items-center rounded-xs border-[1px] border-white px-4 py-1 text-xs font-semibold',
        isCoupon ? 'xl:py-2 xl:text-sm' : 'md:py-2 md:text-sm',
      )}
    >
      {children}
    </div>
  );
};

export default TableRowRoundedTag;

import clsx from 'clsx';
import { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

import ArrowIcon from '../../../../assets/icons/ArrowIcon';
import { ITagsDropdownProps } from './TagsDropdown.types';
import CloseIcon from '../../../../assets/icons/CloseIcon';
import TagButton from '../TagButton/TagButton';

const TagsDropdown: FC<ITagsDropdownProps> = ({
  options,
  selectedOptions,
  onChange,
}) => {
  const { t } = useTranslation();

  const wrapperRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [inputText, setInputText] = useState<string>('');

  const [dropdownPosition, setDropdownPosition] = useState<{
    top: number;
    left: number;
    width: number;
  }>({ top: 0, left: 0, width: 0 });

  const filteredOptions = options.filter((option) =>
    option.toLowerCase().startsWith(inputText.toLowerCase()),
  );

  const handleToggleOpen = () => {
    setIsOpen(!isOpen);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleCloseDropdown = () => {
    setIsOpen(false);
  };

  const updateDropdownPosition = () => {
    if (wrapperRef.current && dropdownRef.current) {
      const parentRect = wrapperRef.current.getBoundingClientRect();
      const dropdownHeight = dropdownRef.current.offsetHeight;

      const scrollContainer: Window | HTMLElement = window;

      const containerHeight =
        scrollContainer instanceof Window
          ? scrollContainer.innerHeight
          : (scrollContainer as HTMLElement).clientHeight;

      const spaceBelow = containerHeight - parentRect.bottom;
      const spaceAbove = parentRect.top;

      const MARGIN_ERROR = 20;

      const openDownwards =
        spaceBelow >= dropdownHeight + MARGIN_ERROR ||
        spaceBelow >= spaceAbove + MARGIN_ERROR;

      let top;
      if (openDownwards) {
        top = parentRect.bottom + 20;
      } else {
        top = parentRect.top - dropdownHeight - 20;
      }

      const left =
        parentRect.left +
        (scrollContainer instanceof Window
          ? scrollContainer.scrollX
          : (scrollContainer as HTMLElement).scrollLeft);

      setDropdownPosition({
        top:
          top +
          (scrollContainer instanceof Window
            ? scrollContainer.scrollY
            : (scrollContainer as HTMLElement).scrollTop),
        left,
        width: parentRect.width,
      });
    }
  };

  useLayoutEffect(() => {
    const handleScrollResize = () => {
      if (isOpen) {
        updateDropdownPosition();
      }
    };

    if (isOpen) {
      updateDropdownPosition();
      window.addEventListener('resize', handleScrollResize);
      window.addEventListener('scroll', handleScrollResize, true);
    }

    return () => {
      window.removeEventListener('resize', handleScrollResize);
      window.removeEventListener('scroll', handleScrollResize, true);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node) &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        handleCloseDropdown();
      }
    };

    if (isOpen) {
      window.addEventListener('mousedown', handleClickOutside);
    } else {
      window.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const handleOptionClick = (option: string) => {
    setInputText('');
    const isOptionSelected = selectedOptions.some(
      (selected) => selected === option,
    );

    if (isOptionSelected) {
      const updatedOptions = selectedOptions.filter(
        (selected) => selected !== option,
      );
      onChange(updatedOptions);
    } else {
      const updatedOptions = [...selectedOptions, option];
      onChange(updatedOptions);
    }
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <div
      ref={wrapperRef}
      className={clsx(
        'relative flex min-h-[50px] items-center justify-center rounded-xs border-[1px] border-solid md:min-w-[340px]',
      )}
    >
      <button
        type="button"
        className="flex w-full justify-between gap-2.5 px-4 py-3"
        onClick={handleToggleOpen}
      >
        <div className="flex">
          <div className="flex w-full flex-wrap gap-2">
            {selectedOptions.map((option, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <TagButton value={option} onDelete={handleOptionClick} key={i} />
            ))}

            <input
              ref={inputRef}
              type="text"
              name=""
              id=""
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              className="h-7 border-none bg-brand-50 text-sm text-white"
            />
          </div>
        </div>
        <span
          className={clsx(
            ' duration-100 ease-in-out',
            isOpen ? 'rotate-0' : 'rotate-180',
          )}
        >
          <ArrowIcon />
        </span>
      </button>

      {isOpen &&
        createPortal(
          <div
            ref={dropdownRef}
            className="absolute z-[400] flex max-h-[234px] flex-col gap-4 overflow-y-auto overflow-x-hidden rounded-xs border bg-brand-50 p-5"
            style={{
              top: dropdownPosition.top,
              left: dropdownPosition.left,
              width: dropdownPosition.width,
            }}
          >
            {filteredOptions.length > 0 && (
              <>
                <span className="text-sm text-brand-1700">
                  {t('messages.all')}
                </span>
                {filteredOptions.map((option, index: number) => {
                  return (
                    <span
                      className="cursor-pointer"
                      onClick={() => handleOptionClick(option)}
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${option}-${index}`}
                    >
                      {option}
                    </span>
                  );
                })}
              </>
            )}
            {inputText !== '' &&
              !options.some((option) => option === inputText) && (
                <>
                  <span className="text-sm text-brand-1700">
                    {t('messages.addNewTag')}
                  </span>
                  <span
                    className="cursor-pointer"
                    onClick={() => handleOptionClick(inputText)}
                  >
                    {inputText}
                  </span>
                </>
              )}
          </div>,
          document.body,
        )}
    </div>
  );
};

export default TagsDropdown;

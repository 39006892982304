import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { AxiosError } from 'axios';

import {
  IDeleteClientThunk,
  IListClientReservationsThunk,
  IListClientsThunk,
  ISearchClientsThunk,
  ISearchClientsTotalThunk,
  TCreateClient,
  TUpdateClient,
} from '../../types/GuestBook';
import { axiosMiddleware } from '../../configuration/axiosMiddleware';
import { REST_API_URLS } from '../../constants/constants';
import {
  CreateClientResponseSchema,
  DeleteClientResponseSchema,
  GetClientResponseSchema,
  ListClientReservationsResponseSchema,
  ListClientsResponseSchema,
  ListClientsTotalResponseSchema,
} from '../../schema/GuestBookSchemas';

export const listClientsThunk = createAsyncThunk(
  'guestBook/listClientsThunk',
  async (
    { limit, offset, sortBy, order }: IListClientsThunk,
    { rejectWithValue },
  ) => {
    try {
      let sortedField = '';
      switch (sortBy) {
        case 'guestPhoneNumber':
          sortedField = 'phoneNumber';
          break;
        case 'guestEmail':
          sortedField = 'email';
          break;
        case 'guestName':
          sortedField = 'firstName';
          break;
        default:
          sortedField = '';
          break;
      }

      const params = {
        limit,
        offset,
        ...(sortedField !== undefined && sortedField !== '' && { sortedField }),
        ...(order !== undefined && order !== '' && { order }),
      };

      const response = await axiosMiddleware({
        url: `${REST_API_URLS.listClients}`,
        method: 'GET',
        params,
      });

      const validatedResponse = ListClientsResponseSchema.parse(response);

      return validatedResponse.data;
    } catch (error) {
      toast.error(t('errorMessages.somethingWentWrongDuringFetchingClients'));
      return rejectWithValue(error);
    }
  },
);

export const listClientsTotalThunk = createAsyncThunk(
  'guestBook/listClientsTotalThunk',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosMiddleware({
        url: `${REST_API_URLS.listClientsTotal}`,
        method: 'GET',
      });

      const validatedResponse = ListClientsTotalResponseSchema.parse(response);

      return validatedResponse.data;
    } catch (error) {
      toast.error(t('errorMessages.somethingWentWrongDuringFetchingClients'));
      return rejectWithValue(error);
    }
  },
);

export const getClientThunk = createAsyncThunk(
  'guestBook/getClientThunk',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axiosMiddleware({
        url: `${REST_API_URLS.getClient}`,
        method: 'GET',
        params: { id },
      });

      const validatedResponse = GetClientResponseSchema.parse(response);

      return validatedResponse.data;
    } catch (error) {
      toast.error(t('errorMessages.somethingWentWrongDuringFetchingClient'));
      return rejectWithValue(error);
    }
  },
);

export const searchClientsThunk = createAsyncThunk(
  'guestBook/searchClientsThunk',
  async (
    { limit, offset, email, name, phoneNumber, tag }: ISearchClientsThunk,
    { rejectWithValue },
  ) => {
    try {
      const params = {
        limit,
        offset,
        ...(email !== undefined && { email }),
        ...(name !== undefined && { name }),
        ...(phoneNumber !== undefined && { phoneNumber }),
        ...(tag !== undefined && { tag }),
      };

      const response = await axiosMiddleware({
        url: `${REST_API_URLS.searchClient}`,
        method: 'GET',
        params,
      });

      const validatedResponse = ListClientsResponseSchema.parse(response);

      return validatedResponse.data;
    } catch (error) {
      toast.error(t('errorMessages.somethingWentWrongDuringFetchingClients'));
      return rejectWithValue(error);
    }
  },
);

export const searchClientsTotalThunk = createAsyncThunk(
  'guestBook/searchClientsTotalThunk',
  async (
    { email, name, phoneNumber, tag }: ISearchClientsTotalThunk,
    { rejectWithValue },
  ) => {
    try {
      const params = {
        ...(email !== undefined && { email }),
        ...(name !== undefined && { name }),
        ...(phoneNumber !== undefined && { phoneNumber }),
        ...(tag !== undefined && { tag }),
      };
      const response = await axiosMiddleware({
        url: `${REST_API_URLS.searchClientTotal}`,
        method: 'GET',
        params,
      });

      const validatedResponse = ListClientsTotalResponseSchema.parse(response);

      return validatedResponse.data;
    } catch (error) {
      toast.error(t('errorMessages.somethingWentWrongDuringFetchingClients'));
      return rejectWithValue(error);
    }
  },
);

export const createClientThunk = createAsyncThunk(
  'guestBook/createClientThunk',
  async (
    {
      firstName,
      lastName,
      phoneNumber,
      tags,
      additionalInfo,
      company,
      email,
      language,
      position,
      callback,
    }: TCreateClient,
    { rejectWithValue },
  ) => {
    try {
      const data = {
        firstName,
        lastName,
        phoneNumber,
        tags,
        additionalInfo,
        company,
        email,
        language,
        position,
      };

      const response = await axiosMiddleware({
        url: `${REST_API_URLS.createClient}`,
        method: 'POST',
        data,
      });

      const validatedResponse = CreateClientResponseSchema.parse(response);

      callback();
      return validatedResponse.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.data?.errorCode === 'PHONE_TAKEN') {
          toast.error(t('errorMessages.phoneTaken'));
        } else {
          toast.error(
            t('errorMessages.somethingWentWrongDuringCreatingClient'),
          );
        }
      }
      return rejectWithValue(error);
    }
  },
);

export const updateClientThunk = createAsyncThunk(
  'guestBook/updateClientThunk',
  async (
    {
      id,
      firstName,
      lastName,
      phoneNumber,
      tags,
      additionalInfo,
      company,
      email,
      language,
      position,
      callback,
    }: TUpdateClient,
    { rejectWithValue },
  ) => {
    try {
      const data = {
        id,
        firstName,
        lastName,
        phoneNumber,
        tags,
        additionalInfo,
        company,
        email,
        language,
        position,
      };

      const response = await axiosMiddleware({
        url: `${REST_API_URLS.updateClient}`,
        method: 'PATCH',
        data,
      });

      CreateClientResponseSchema.parse(response);

      callback();

      return data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.data?.errorCode === 'PHONE_TAKEN') {
          toast.error(t('errorMessages.phoneTaken'));
        } else {
          toast.error(
            t('errorMessages.somethingWentWrongDuringUpdatingClient'),
          );
        }
      }

      return rejectWithValue(error);
    }
  },
);

export const deleteClientThunk = createAsyncThunk(
  'guestBook/deleteClientThunk',
  async ({ id, callback }: IDeleteClientThunk, { rejectWithValue }) => {
    try {
      const response = await axiosMiddleware({
        url: `${REST_API_URLS.deleteClient}`,
        method: 'DELETE',
        data: { id },
      });

      DeleteClientResponseSchema.parse(response);

      callback();

      return id;
    } catch (error) {
      toast.error(t('errorMessages.somethingWentWrongDuringDeletingClient'));
      return rejectWithValue(error);
    }
  },
);

export const listClientReservationsThunk = createAsyncThunk(
  'guestBook/listClientReservationsThunk',
  async (
    { limit, offset, clientId }: IListClientReservationsThunk,
    { rejectWithValue },
  ) => {
    try {
      const params = { limit, offset, clientId };
      const response = await axiosMiddleware({
        url: `${REST_API_URLS.listClientReservations}`,
        method: 'GET',
        params,
      });

      const validatedResponse =
        ListClientReservationsResponseSchema.parse(response);

      return validatedResponse.data;
    } catch (error) {
      toast.error(
        t('errorMessages.somethingWentWrongDuringFetchingClientReservations'),
      );
      return rejectWithValue(error);
    }
  },
);

export const listClientReservationsTotalThunk = createAsyncThunk(
  'guestBook/listClientReservationsTotalThunk',
  async (clientId: string, { rejectWithValue }) => {
    try {
      const response = await axiosMiddleware({
        url: `${REST_API_URLS.listClientReservationsTotal}`,
        method: 'GET',
        params: { clientId },
      });

      const validatedResponse = ListClientsTotalResponseSchema.parse(response);

      return validatedResponse.data;
    } catch (error) {
      toast.error(t('errorMessages.somethingWentWrongDuringFetchingClients'));
      return rejectWithValue(error);
    }
  },
);

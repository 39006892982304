import { FC } from 'react';

import { getDateLabel } from '../../../../functions/functions';
import { IReservationBookDate } from './ReservationBookDate.types';

const ReservationBookDate: FC<IReservationBookDate> = ({ date }) => {
  const dateLabel = getDateLabel(date);

  return (
    <p className="w-full text-center text-xs font-semibold text-brand-1700">
      {dateLabel}
    </p>
  );
};

export default ReservationBookDate;

import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import GuestDetailsHeader from '../../UI/molecules/GuestDetailsHeader/GuestDetailsHeader';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import { reduxStatus, routes } from '../../../constants/constants';
import {
  getClientThunk,
  listClientReservationsThunk,
  listClientReservationsTotalThunk,
} from '../../../redux/thunks/guestBookThunk';
import PaginationSelectorCount from '../../UI/molecules/PaginationSelectorCount/PaginationSelectorCount';
import PaginationButtons from '../../UI/atoms/PaginationButtons/PaginationButtons';
import useMobile from '../../../hooks/useIsMobile';
import { TClientReservation } from '../../../types/GuestBook';
import TableRowRoundedTag from '../../UI/atoms/TableRowRoundedTag/TableRowRoundedTag';
import {
  extractHourFromIsoString,
  getZonedTimeFromIsoDateAndFormat,
} from '../../../functions/functions';
import TableRowText from '../../UI/atoms/TableRowText/TableRowText';
import Badge from '../../UI/atoms/Badge/Badge';
import { getTableReservationColors } from '../../../functions/colors';
import { getTableResevationStatus } from '../../../functions/tables';
import ReservationHistoryTable from '../../UI/molecules/ReservationHistoryTable/ReservationHistoryTable';
import TableMobile from '../../UI/atoms/TableMobile/TableMobile';
import DataEmptyState from '../../UI/atoms/DataEmptyState/DataEmptyState';
import GuestDetailsHeaderSkeleton from '../../UI/atoms/GuestDetailsHeaderSkeleton/GuestDetailsHeaderSkeleton';

const GuestReservationsPage = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { guestId } = useParams();

  const navigate = useNavigate();

  const isMobile = useMobile();

  const tableStatuses = useAppSelector((store) => store.settings.tableStatuses);

  const guest = useAppSelector((state) => state.guestBook.singleGuest);

  const guestStatus = useAppSelector(
    (state) => state.guestBook.singleGuestStatus,
  );

  const reservationsList = useAppSelector(
    (state) => state.guestBook.reservationsList,
  );

  const listClientsReservationsStatus = useAppSelector(
    (state) => state.guestBook.listClientsReservationsStatus,
  );

  const isEmptyState =
    reservationsList.length === 0 &&
    listClientsReservationsStatus !== reduxStatus.loading;

  const isReservationsLoading =
    listClientsReservationsStatus === reduxStatus.loading;

  const placeTimezone = useAppSelector((state) => state.settings.timezone);

  const reservationsListTotal = useAppSelector(
    (state) => state.guestBook.reservationsListTotal,
  );

  const years =
    reservationsList.length > 0
      ? reservationsList.map((res) => new Date(res.dateStart).getFullYear())
      : [];

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageLimit, setPageLimit] = useState<number>(10);

  const handleSetPageLimit = (newLimit: number) => {
    setPageLimit(newLimit);
    setCurrentPage(0);
  };

  const handleNextPage = () => {
    if ((currentPage + 1) * pageLimit < reservationsListTotal) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const listReservations = useMemo(() => {
    if (reservationsList.length < 1) return;

    return reservationsList
      .slice(0, pageLimit)
      .map((reservation: TClientReservation) => {
        const { ReservationTable, countPerson, dateStart, status } =
          reservation;

        const tableReservationStatus = getTableResevationStatus(
          status,
          dateStart,
        );

        return {
          date: (
            <TableRowRoundedTag>
              {getZonedTimeFromIsoDateAndFormat(dateStart, placeTimezone)}
            </TableRowRoundedTag>
          ),
          hour: (
            <span className="text-sm font-semibold">
              {extractHourFromIsoString(dateStart, placeTimezone)}
            </span>
          ),
          table: (
            <TableRowText
              text={
                ReservationTable[0]
                  ? `${
                      isMobile
                        ? ReservationTable[0].name
                        : `${t('messages.table')}: ${ReservationTable[0].name}`
                    }`
                  : '-'
              }
            />
          ),
          countPerson: (
            <TableRowText
              text={`${
                isMobile
                  ? countPerson
                  : `${t('messages.numberOfPeople')}: ${countPerson}`
              }`}
            />
          ),
          status: (
            <div className="flex w-full md:justify-end">
              <Badge
                color={
                  getTableReservationColors(
                    tableReservationStatus,
                    tableStatuses,
                  ).primaryColor
                }
                isReservationList
              >
                {t(`messages.reservationStates.${tableReservationStatus}`)}
              </Badge>
            </div>
          ),
        };
      });
  }, [isMobile, pageLimit, placeTimezone, reservationsList, t, tableStatuses]);

  const listReservationsSkeleton = useMemo(() => {
    return [...Array(5)].map(() => {
      return {
        date: (
          <span className="flex h-6 w-20 animate-pulse rounded-xs bg-brand-700 md:h-8" />
        ),
        hour: (
          <span className="flex h-6 w-12 animate-pulse rounded-xs bg-brand-700" />
        ),
        table: (
          <span className="flex h-6 w-20 animate-pulse rounded-xs bg-brand-300" />
        ),
        countPerson: (
          <span className="flex h-6 w-24 animate-pulse rounded-xs bg-brand-300" />
        ),
        status: (
          <span className="float-right flex h-6 w-32 animate-pulse rounded-full bg-brand-700 md:h-8" />
        ),
      };
    });
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: t('messages.date'),
        accessor: 'date',
      },
      {
        Header: t('messages.hour'),
        accessor: 'hour',
      },
      {
        Header: t('messages.table'),
        accessor: 'table',
      },
      {
        Header: t('messages.numberOfPeople'),
        accessor: 'countPerson',
      },
      {
        Header: t('messages.status'),
        accessor: 'status',
      },
    ],

    [t],
  );

  useEffect(() => {
    if (!guestId) {
      navigate(routes.guestBook);
      return;
    }
    if (!guest || (guest && guest.id !== guestId)) {
      dispatch(getClientThunk(guestId));
    }
  }, [dispatch, guest, guestId, navigate]);

  useEffect(() => {
    if (!guestId) {
      navigate(routes.guestBook);
      return;
    }

    dispatch(
      listClientReservationsThunk({
        clientId: guestId,
        limit: pageLimit,
        offset: currentPage * pageLimit,
      }),
    );
  }, [currentPage, dispatch, guestId, navigate, pageLimit]);

  useEffect(() => {
    if (!guestId) {
      navigate(routes.guestBook);
      return;
    }
    dispatch(listClientReservationsTotalThunk(guestId));
  }, [dispatch, guestId, navigate]);

  const isLoading = guestStatus === reduxStatus.loading || !guest;

  return (
    <div className="flex h-full w-full flex-col overflow-y-auto pr-2">
      {isLoading ? (
        <GuestDetailsHeaderSkeleton />
      ) : (
        <GuestDetailsHeader
          id={guest.id}
          name={`${guest.firstName} ${guest.lastName}`}
          phoneNumber={guest.phoneNumber}
          company={guest.company}
          email={guest.email}
          position={guest.position}
        />
      )}

      {isEmptyState ? (
        <DataEmptyState
          title={t('messages.thisClientHaveNotAnyReservationsYet')}
          description={t('messages.waitUntilReservationsWillComeFromClients')}
        />
      ) : (
        <div className="mt-6 h-full overflow-y-auto pr-2">
          {isMobile ? (
            <TableMobile
              columns={columns}
              data={
                isReservationsLoading
                  ? listReservationsSkeleton
                  : listReservations
              }
              variant="reservationHistory"
            />
          ) : (
            <ReservationHistoryTable
              columns={columns}
              data={
                isReservationsLoading
                  ? listReservationsSkeleton
                  : listReservations
              }
              years={years}
              numberOfColumns={5}
            />
          )}
        </div>
      )}
      <footer className="mt-auto flex justify-between border-t border-brand-700 pt-4">
        <PaginationSelectorCount
          total={reservationsListTotal}
          pageLimit={pageLimit}
          setPageLimit={handleSetPageLimit}
          currentPage={currentPage}
        />
        <PaginationButtons
          isLoading={false}
          nextPage={handleNextPage}
          prevPage={handlePrevPage}
          hasNextPage={pageLimit * (currentPage + 1) <= reservationsListTotal}
          hasPreviousPage={currentPage > 0}
        />
      </footer>
    </div>
  );
};

export default GuestReservationsPage;

import { useState, Fragment, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import {
  extractHourFromIsoString,
  getDateLabel,
} from '../../../../functions/functions';
import Modal from '../../molecules/Modal/Modal';
import ChatMessage from '../../molecules/ChatMessage/ChatMessage';
import ChatInput from '../../molecules/ChatInput/ChatInput';
import ConversationStartDate from '../../atoms/ConversationStartDate/ConversationStartDate';
import ChatReservationConfirmation from '../../atoms/ChatReservationConfirmation/ChatReservationConfirmation';
import ReservationBookDate from '../../atoms/ReservationBookDate/ReservationBookDate';
import { IMessage } from '../../../../types/Chat';
import { IChatModalProps } from './ChatModal.types';
import {
  listMessagesThunk,
  sendMessageThunk,
} from '../../../../redux/thunks/chatThunk';
import {
  checkIsAnyUnseenMessageThunk,
  markMessagesAsSeenThunk,
} from '../../../../redux/thunks/reservationThunk';
import { removeUnseenReservationAfterReadingMessage } from '../../../../redux/features/reservationSlice';
import { cleanMessages } from '../../../../redux/features/chatSlice';
import { routes } from '../../../../constants/constants';
import ModalContent from '../../atoms/ModalContent/ModalContent';

const ChatModal = ({ visible, onClose }: IChatModalProps) => {
  const dispatch = useAppDispatch();

  const { pathname } = useLocation();

  const chatRef = useRef<HTMLDivElement>(null);

  const initialRenderRef = useRef<boolean>(false);

  const [initialMessagesLoad, setInitialMessagesLoad] =
    useState<boolean>(false);

  const reservation = useAppSelector((state) => state.chat.reservation);
  const placeTimezone = useAppSelector((state) => state.settings.timezone);

  const reservationId = reservation?.id;
  const userFullName = reservation?.fullName;
  const tablesNames = reservation?.ReservationTable.map((table) => table.name)
    .sort()
    .join(', ');

  const reservationMessages = useAppSelector((state) => state.chat.messages);

  const listMessagesStatus = useAppSelector(
    (state) => state.chat.listMessagesStatus,
  );

  const sendMessageStatus = useAppSelector(
    (state) => state.chat.sendMessageStatus,
  );

  const hour = reservation
    ? extractHourFromIsoString(reservation?.dateStart, placeTimezone)
    : undefined;

  const [newMessage, setNewMessage] = useState('');

  const handleScrollToTheBottom = () => {
    const modalElement = chatRef?.current;
    if (modalElement) {
      modalElement.scrollTop = modalElement.scrollHeight;
    }
  };

  const closeChatModal = () => {
    if (visible && reservationId) {
      dispatch(markMessagesAsSeenThunk({ reservationId }));
      dispatch(cleanMessages());
      dispatch(checkIsAnyUnseenMessageThunk());

      if (pathname === routes.reservationsUnseen) {
        dispatch(removeUnseenReservationAfterReadingMessage({ reservationId }));
      }
    }
    onClose();
  };

  const handleSubmit = () => {
    if (newMessage.trim() !== '' && reservationId) {
      dispatch(
        sendMessageThunk({
          reservationId,
          message: newMessage,
        }),
      );
      setNewMessage('');
    }
  };

  const renderMessages = (messagesToRender: IMessage[]) => {
    let lastDateLabel: string | null = null;

    if (!Array.isArray(messagesToRender)) {
      return null;
    }

    return messagesToRender?.map((message: IMessage) => {
      const messageDate = message.createdAt;
      const dateLabel = getDateLabel(messageDate);

      const isNewDate = lastDateLabel === null || dateLabel !== lastDateLabel;
      if (isNewDate) {
        lastDateLabel = dateLabel;
      }

      return (
        <Fragment key={message.id}>
          {isNewDate && <ConversationStartDate date={dateLabel} />}
          <ChatMessage
            message={message}
            messageText={message.message}
            isMyMessage={message.sendByAdmin}
            author={userFullName}
          />
        </Fragment>
      );
    });
  };

  useEffect(() => {
    if (reservationId && reservationMessages && visible) {
      const fetchMessages = () => {
        dispatch(listMessagesThunk({ reservationId, limit: 10 }));
      };

      dispatch(markMessagesAsSeenThunk({ reservationId }));

      const intervalId = setInterval(fetchMessages, 5000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [dispatch, reservationId, visible, reservationMessages]);

  useEffect(() => {
    if (visible && !initialRenderRef.current && initialMessagesLoad) {
      setTimeout(() => {
        handleScrollToTheBottom();
      }, 500);
      initialRenderRef.current = true;
    }
  }, [visible, initialMessagesLoad]);

  useEffect(() => {
    if (!visible) {
      initialRenderRef.current = false;
      setInitialMessagesLoad(false);
    }
  }, [visible]);

  useEffect(() => {
    if (listMessagesStatus === 'success' && !initialRenderRef.current) {
      setInitialMessagesLoad(true);
    }
  }, [listMessagesStatus]);

  useEffect(() => {
    if (sendMessageStatus === 'success') {
      handleScrollToTheBottom();
    }
  }, [sendMessageStatus]);

  return (
    <Modal
      visible={visible}
      onClose={closeChatModal}
      isChatModal
      userFullName={userFullName}
      tableName={tablesNames}
    >
      <ModalContent isChatModal>
        {reservation && (
          <div
            className="flex h-full w-full flex-col gap-4 overflow-auto scroll-smooth px-2 pb-8 pt-4 md:h-[600px] md:gap-6"
            ref={chatRef}
          >
            <ReservationBookDate date={reservation.createdAt} />
            <ChatReservationConfirmation
              hour={hour}
              countPerson={reservation.countPerson}
              date={reservation.dateStart}
              userFullName={reservation.fullName}
            />
            {reservationMessages && renderMessages(reservationMessages)}
          </div>
        )}
        <div className="mb-12 w-full cursor-pointer border-t border-brand-300 px-2 pt-5 md:mb-0">
          <ChatInput
            newMessage={newMessage}
            setNewMessage={setNewMessage}
            handleSubmit={handleSubmit}
          />
        </div>
      </ModalContent>
    </Modal>
  );
};

export default ChatModal;

import React, { ChangeEvent, FC, useState } from 'react';
import {
  getCountries,
  getCountryCallingCode,
  CountryCode,
} from 'libphonenumber-js';
import clsx from 'clsx';
import pl from 'react-phone-number-input/locale/pl.json';
import { useTranslation } from 'react-i18next';

import {
  IDialingCodeSelectorProps,
  IListedCountry,
} from './DialingCodeSelector.types';
import { useDetectOutsideClick } from '../../../../hooks/useDetectOutsideClick';
import ArrowIcon from '../../../../assets/icons/ArrowIcon';
import DialingCodeSelectorSearchInput from '../../atoms/DialingCodeSelectorSearchInput/DialingCodeSelectorSearchInput';
import { getFlagCdnUrl } from '../../../../functions/functions';

const DialingCodeSelector: FC<IDialingCodeSelectorProps> = ({
  selectedCountry,
  handleCountryChange,
  isDisabled,
}) => {
  const { t } = useTranslation();

  const wrapperRef = React.useRef<HTMLDivElement | null>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [searchValue, setSearchValue] = useState<string>('');

  const toggleOpen = () => {
    if (!isDisabled) {
      setIsOpen(!isOpen);
      setSearchValue('');
    }
  };

  const changeCountry = (country: CountryCode) => {
    handleCountryChange(country);
    setIsOpen(false);
    setSearchValue('');
  };

  const handleSearchValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const compareCountryNames = (a: IListedCountry, b: IListedCountry) => {
    if (a.country < b.country) return -1;
    if (a.country > b.country) return 1;
    return 0;
  };

  const sortCountryNames = () => {
    const translatedData = getCountries().map((country) => ({
      country: pl[country],
      code: country,
    }));
    return translatedData.sort(compareCountryNames);
  };

  const sortedData = sortCountryNames();

  const filterData = () => {
    let filteredData = [];
    filteredData = sortedData.filter(
      (item) =>
        item?.country
          .toString()
          .toLowerCase()
          .includes(searchValue.toString().toLowerCase()),
    );
    return filteredData;
  };

  const dataToRender = filterData();

  useDetectOutsideClick(wrapperRef, () => setIsOpen(false));

  return (
    <div ref={wrapperRef} className="relative">
      <div
        className="flex h-[42px] w-[121px] cursor-pointer items-center justify-between rounded-xs border border-brand-700 px-4 py-1"
        onClick={toggleOpen}
      >
        <span>
          <img
            src={getFlagCdnUrl(selectedCountry.toLocaleLowerCase())}
            width="16"
            height="12"
            alt={t('alts.countryFlagOfPickedDialingCodeAlt')}
          />
        </span>
        <span className="font-medium">
          +{getCountryCallingCode(selectedCountry)}
        </span>
        <div
          className={clsx('duration-100', isOpen ? 'rotate-0' : 'rotate-180')}
        >
          <ArrowIcon />
        </div>
      </div>
      {isOpen ? (
        <div className="absolute left-0 top-14 z-50 flex max-h-[330px] w-60 flex-col rounded-xs border border-brand-700 bg-brand-50 p-5">
          <DialingCodeSelectorSearchInput
            placeholder={t('messages.typeCountry')}
            searchValue={searchValue}
            onChange={handleSearchValueChange}
          />
          <div className="overflow-y-auto overflow-x-hidden">
            {dataToRender.map(({ code, country }) => (
              <div
                key={`${code}-${country}`}
                className="py-2"
                onClick={() => changeCountry(code)}
              >
                {country}
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default DialingCodeSelector;

import { z } from 'zod';

import { RESERVATION_STATUSES } from '../constants/constants';

export const NextReservationSchema = z.object({
  ReservationTable: z.array(z.object({ name: z.string() })),
  countPerson: z.number(),
  dateStart: z.string(),
  id: z.string(),
  status: z.enum(RESERVATION_STATUSES),
  messageCount: z.number(),
});

export const CountReservationSchema = z.object({
  cancelled: z.number(),
  done: z.number(),
  planned: z.number(),
});

export const BaseClientSchema = z.object({
  firstName: z.string().trim().min(1),
  lastName: z.string().trim().min(1),
  phoneNumber: z.string().trim().min(1),
  email: z.string().optional(),
  language: z.string().optional(),
  additionalInfo: z.string().nullable().optional(),
  position: z.string().optional(),
  company: z.string().optional(),
  tags: z.array(z.string()),
});

export const ClientSchema = BaseClientSchema.extend({
  id: z.string().min(1),
  countReservations: CountReservationSchema,
  lastReservationDate: z.string().nullable().optional(),
  firstReservation: z.string().nullable(),
  firstVisit: z.string().nullable().optional(),
  nextReservation: NextReservationSchema.nullable(),
});

export const ListClientSchema = BaseClientSchema.extend({
  id: z.string().min(1),
  lastReservationDate: z.string().nullable().optional(),
  nextVisit: z.string().nullable().optional(),
  firstReservation: z.string().nullable(),
  firstVisit: z.string().nullable().optional(),
  incomingReservations: z.number(),
});

export const ListClientsResponseSchema = z.object({
  successCode: z.string(),
  data: z.array(ListClientSchema),
});

export const ListClientsTotalResponseSchema = z.object({
  successCode: z.string(),
  data: z.number(),
});

export const CreateClientResponseSchema = z.object({
  successCode: z.string(),
  data: ListClientSchema,
});

export const DeleteClientResponseSchema = z.object({
  successCode: z.string(),
  data: z.literal(true),
});

export const CreateClientSchema = BaseClientSchema.extend({
  callback: z.function(),
});

export const UpdateClientSchema = BaseClientSchema.extend({
  id: z.string(),
  callback: z.function(),
});

export const GetClientResponseSchema = z.object({
  successCode: z.string(),
  data: ClientSchema,
});

export const ClientReservationsSchema = z.object({
  id: z.string(),
  ReservationTable: z.array(z.object({ name: z.string() })),
  countPerson: z.number(),
  dateStart: z.string(),
  status: z.enum(RESERVATION_STATUSES),
});

export const ListClientReservationsResponseSchema = z.object({
  successCode: z.string(),
  data: z.array(ClientReservationsSchema),
});

export const UpdateTagsResponseSchema = z.object({
  successCode: z.string(),
  data: z.array(z.string()),
});

/* eslint-disable react/jsx-props-no-spreading */

import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useSortBy, useTable, HeaderGroup } from 'react-table';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import FilterColumnIcon from '../../../../assets/icons/FilterColumnIcon';
import { ITableProps } from './Table.types';

const Table = ({
  columns,
  data = [],
  numberOfColumns = 1,
  setSortField,
  setSortDirection,
  avoidedFieldsWhenSorting = [],
}: ITableProps) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        manualSortBy: true,
      } as any,
      useSortBy,
    );

  const actualSortedColumn = headerGroups[0]?.headers?.find(
    ({ isSorted }: any) => isSorted === true,
  );

  const { t } = useTranslation();

  const accessorFieldThatHaveToAvoid = [
    'description',
    'discount',
    'shifts',
    'isOpen',
    'interested',
    'used',
    'status',
    ...avoidedFieldsWhenSorting,
  ];

  useEffect(() => {
    if (!setSortField || !setSortDirection) return;

    if (actualSortedColumn && 'isSortedDesc' in actualSortedColumn) {
      if (accessorFieldThatHaveToAvoid.includes(actualSortedColumn?.id)) return;
      setSortField(actualSortedColumn.id);
      if (actualSortedColumn?.isSortedDesc) {
        setSortDirection('desc');
      } else {
        setSortDirection('asc');
      }
    } else {
      setSortDirection('');
      setSortField('');
    }

    // eslint-disable-next-line
  }, [
    actualSortedColumn,
    //  eslint-disable-next-line
    // @ts-ignore
    actualSortedColumn?.isSortedDesc,
    setSortDirection,
    setSortField,
  ]);

  return (
    <table
      {...getTableProps()}
      className="relative h-max w-full border-separate border-spacing-0 text-left text-sm"
    >
      <thead className="">
        {headerGroups.map((headerGroup: HeaderGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any, index: number) => {
              return (
                <th
                  className={clsx(
                    'sticky top-0 z-[1] overflow-hidden whitespace-nowrap border-b-[1px] border-solid border-brand-700 bg-brand-50 py-2 uppercase text-brand-1700',
                    !accessorFieldThatHaveToAvoid.includes(column.id)
                      ? 'cursor-pointer'
                      : 'no-cursor-th',
                  )}
                  key={column.id || `column_${index}`}
                  {...(data.length &&
                    column.getHeaderProps(
                      column.getSortByToggleProps({
                        title: !accessorFieldThatHaveToAvoid.includes(column.id)
                          ? t('messages.clickToSortSelectedColumn')
                          : '',
                      }),
                    ))}
                >
                  <div className="flex items-center">
                    <div className="mt-auto">{column.render('Header')}</div>
                    <div className={clsx('ml-2 flex')}>
                      {!accessorFieldThatHaveToAvoid.includes(column.id) && (
                        <FilterColumnIcon />
                      )}
                    </div>
                  </div>
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      {!isEmpty(data) && (
        <tbody className="before:grid before:h-4" {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell: any) => {
                  return (
                    <td
                      className={clsx(
                        'h-[52px] max-w-[200px] py-2',
                        cell.column.paddingTable &&
                          `w-0 p-[${cell.column.paddingTable}]`,
                        i + 1 === rows.length
                          ? 'border-none'
                          : `border-b-[1px] border-solid border-brand-700`,
                        numberOfColumns &&
                          `max-w-[calc((100vw - 140px)/${numberOfColumns})]`,
                      )}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      )}
    </table>
  );
};

export default Table;

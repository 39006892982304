import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import clsx from 'clsx';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { isAfter, isBefore, isEqual } from 'date-fns';
import { isEmpty } from 'lodash';

import Modal from '../../molecules/Modal/Modal';
import { IReservationModalProps } from './ReservationModal.types';
import { IReservationFormValues } from '../../../../types/IReservationFormValues';
import Input from '../../molecules/Input/Input';
import InputDropdown from '../../atoms/InputDropdown/InputDropdown';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import InputDate from '../../molecules/InputDate/InputDate';
import Button from '../../molecules/Button/Button';
import {
  createReservationThunk,
  cancelReservationThunk,
  listAvailableHoursThunk,
  listFreeTablesThunk,
  updateReservationThunk,
} from '../../../../redux/thunks/reservationThunk';
import { IDropdownOption } from '../../atoms/InputDropdown/InputDropdown.types';
import PhoneNumberInput from '../../molecules/PhoneNumberInput/PhoneNumberInput';
import {
  clearAvailableHours,
  clearCurrentReservation,
  resetCancelReservationStatus,
} from '../../../../redux/features/reservationSlice';
import ClockIcon from '../../../../assets/icons/ClockIcon';
import ButtonWithIcon from '../../molecules/ButtonWithIcon/ButtonWithIcon';
import TrashIcon from '../../../../assets/icons/TrashIcon';
import useModal from '../../../../hooks/useModal';
import ConfirmModal from '../../molecules/ConfirmModal/ConfirmModal';
import {
  addMinutesToISODateString,
  convertLocalDateToAdjustedUTC,
  convertToISODateString,
  extractHourFromIsoString,
  minutesToTimeString,
  resetTimeInUTC,
  timeStringToMinutes,
} from '../../../../functions/functions';
import InputPicker from '../../molecules/InputPicker/InputPicker';
import {
  INITIAL_TIMEZONE,
  RESERVATION_LANGUAGES,
  RESERVATION_STATUSES,
  numberOfPeopleArray,
  reduxStatus,
} from '../../../../constants/constants';
import SectionLoader from '../../atoms/SectionLoader/SectionLoader';
import ModalContent from '../../atoms/ModalContent/ModalContent';
import Checkbox from '../../molecules/Checkbox/Checkbox';
import ReservationLanguageSelector from '../../molecules/ReservationLanguageSelector/ReservationLanguageSelector';
import { fullNameRegex } from '../../../../constants/regex';
import { searchClientsThunk } from '../../../../redux/thunks/guestBookThunk';
import useMobile from '../../../../hooks/useIsMobile';
import AutocompleteDropdown from '../../atoms/AutocompleteDropdown/AutocompleteDropdown';
import useDebounce from '../../../../hooks/useDebounce';
import { TListClient } from '../../../../types/GuestBook';

const ReservationModal: FC<IReservationModalProps> = ({
  visible,
  onClose,
  isEdit,
  startDateFilter,
  endDateFilter,
  statusFilters,
  searchInput,
  disableWalkin,
  clientData,
}) => {
  const { t } = useTranslation();

  const today = new Date();

  const fullNameRef = useRef<HTMLDivElement>(null);
  const phoneNumberRef = useRef<HTMLDivElement>(null);
  const phoneAndEmailRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();

  const isMobile = useMobile();

  const user = useAppSelector((store) => store.user.user);

  const placeTimezone = useAppSelector((store) => store.settings.timezone);

  const availableHours = useAppSelector(
    (store) => store.reservation.availableHours,
  );

  const availableHoursStatus = useAppSelector(
    (store) => store.reservation.listAvailableHoursStatus,
  );

  const placeMinReservationTime = useAppSelector(
    (store) => store.settings.minReservationTime,
  );

  const placeMaxReservationTime = useAppSelector(
    (store) => store.settings.maxReservationTime,
  );

  const contractStartDate = useAppSelector(
    (state) => state.settings.contractStartDate,
  );

  const contractEndDate = useAppSelector(
    (state) => state.settings.contractEndDate,
  );

  const placeSlotInterval = useAppSelector(
    (state) => state.settings.slotInterval,
  );

  const beforeReservationTime = useAppSelector(
    (state) => state.settings.beforeReservationTime,
  );

  const openingHours = useAppSelector((state) => state.settings.openingHours);

  const {
    visible: isCancelReservationModalVisible,
    showModal: showCancelReservationModal,
    hideModal: hideCancelReservationModal,
  } = useModal();

  const createReservationLoadingStatus = useAppSelector(
    (store) => store.reservation.createReservationStatus,
  );

  const updateReservationLoadingStatus = useAppSelector(
    (store) => store.reservation.updateReservationStatus,
  );

  const cancelReservationStatus = useAppSelector(
    (store) => store.reservation.cancelReservationStatus,
  );

  const guestAutocomplete = useAppSelector(
    (store) => store.guestBook.guestsList,
  );

  const isCreateLoading = createReservationLoadingStatus === 'loading';

  const isUpdateLoading = updateReservationLoadingStatus === 'loading';

  const singleReservation = useAppSelector(
    (store) => store.reservation.reservation,
  );

  const freeTables = useAppSelector((store) => store.reservation.freeTables);

  const [isWalkIn, setIsWalkIn] = useState<boolean>(!!disableWalkin);

  const listClientsStatus = useAppSelector(
    (store) => store.guestBook.listClientsStatus,
  );

  const isGuestAutocompleteLoading = listClientsStatus === reduxStatus.loading;

  const {
    control,
    handleSubmit,
    clearErrors,
    reset,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<IReservationFormValues>();

  const formData = watch();

  const debouncedFullName = useDebounce(formData.fullName, 300);
  const debouncedEmail = useDebounce(formData.email, 300);
  const debouncedPhoneNumber = useDebounce(formData.phoneNumber, 300);

  useEffect(() => {
    if (debouncedFullName && debouncedFullName !== '') {
      dispatch(
        searchClientsThunk({
          limit: 10,
          offset: 0,
          name: debouncedFullName,
        }),
      );
    }
  }, [debouncedFullName, dispatch]);

  useEffect(() => {
    if (debouncedEmail && debouncedEmail !== '') {
      dispatch(
        searchClientsThunk({
          limit: 10,
          offset: 0,
          email: debouncedEmail,
        }),
      );
    }
  }, [debouncedEmail, dispatch]);

  useEffect(() => {
    if (debouncedPhoneNumber && debouncedPhoneNumber !== '') {
      dispatch(
        searchClientsThunk({
          limit: 10,
          offset: 0,
          phoneNumber: debouncedPhoneNumber,
        }),
      );
    }
  }, [debouncedPhoneNumber, dispatch]);

  const isDateFieldUnlocked =
    !!formData.countPerson && !!formData.reservationTime;
  const isHourFieldUnlocked = isDateFieldUnlocked && !!formData.date;
  const isTableFieldUnlocked = isHourFieldUnlocked && !!formData.hour;
  const isReservationTimeFieldUnlocked =
    (isWalkIn && !!formData.countPerson) || !isWalkIn;

  const isHourSlotsListEmpty = availableHours.length === 0;

  const onSubmit: SubmitHandler<IReservationFormValues> = ({
    countPerson,
    reservationTime,
    date,
    hour,
    tables,
    fullName,
    phoneNumber,
    email,
    additionalInfo,
    availabilityId,
    language,
  }) => {
    if (date && hour && tables && user?.placeId && placeTimezone) {
      const [h, m] = hour.split(':');

      const formattedReservationDate = resetTimeInUTC(
        date,
        parseInt(h, 10),
        parseInt(m, 10),
      ).toISOString();

      let isWithinSelectedFilters = false;

      const isReservationDateIsBetweenDateFilters =
        startDateFilter &&
        endDateFilter &&
        (!isBefore(
          new Date(formattedReservationDate),
          new Date(startDateFilter),
        ) ||
          isEqual(
            new Date(formattedReservationDate),
            new Date(startDateFilter),
          )) &&
        (!isAfter(
          new Date(formattedReservationDate),
          new Date(endDateFilter),
        ) ||
          isEqual(new Date(formattedReservationDate), new Date(endDateFilter)));

      const isReservationMatchSelectedStatusFilters =
        statusFilters &&
        ((isWalkIn && statusFilters.includes(RESERVATION_STATUSES[3])) ||
          (!isWalkIn && statusFilters.includes(RESERVATION_STATUSES[0])) ||
          (!isWalkIn && statusFilters.includes(RESERVATION_STATUSES[1])) ||
          (!isWalkIn && statusFilters.includes(RESERVATION_STATUSES[2])));

      if (isReservationDateIsBetweenDateFilters) {
        if (isReservationMatchSelectedStatusFilters) {
          if (searchInput) {
            isWithinSelectedFilters =
              fullName.includes(searchInput) ||
              phoneNumber.includes(searchInput);
          } else {
            isWithinSelectedFilters = true;
          }
        }
      }

      if (isEdit && singleReservation && availabilityId) {
        dispatch(
          updateReservationThunk({
            placeId: user.placeId,
            countPerson: Number(countPerson),
            reservationTime,
            date,
            hour,
            spacePlanName: tables[0].spacePlanName ?? '',
            tables,
            fullName,
            phoneNumber,
            email,
            additionalInfo,
            availabilityId,
            callback: onClose,
            reservationId: singleReservation.id,
            isWithinSelectedFilters,
            walkIn: isWalkIn,
            timezone: placeTimezone,
            userId: singleReservation.userId,
          }),
        );
      } else if (availabilityId) {
        dispatch(
          createReservationThunk({
            placeId: user.placeId,
            countPerson: Number(countPerson),
            reservationTime,
            date,
            hour,
            spacePlanName: tables[0].spacePlanName ?? '',
            tables,
            fullName,
            phoneNumber,
            email,
            additionalInfo,
            availabilityId,
            callback: onClose,
            isWithinSelectedFilters,
            walkIn: isWalkIn,
            timezone: placeTimezone,
            language: language.value,
          }),
        );
      }
    }
  };

  const handleFieldChange = useCallback(
    (field: keyof IReservationFormValues) => {
      switch (field) {
        case 'countPerson':
          if (isWalkIn) {
            setValue('reservationTime', '');
            setValue('hour', undefined);
            setValue('tables', undefined);
            if (isEdit) {
              setValue('date', undefined);
            }
          } else {
            setValue('reservationTime', '');
            setValue('hour', undefined);
            setValue('tables', undefined);
          }
          break;
        case 'reservationTime':
          if (!isWalkIn) {
            setValue('date', undefined);
            setValue('hour', undefined);
          }
          setValue('availabilityId', '');
          setValue('tables', undefined);
          break;
        case 'date':
          setValue('hour', undefined);
          setValue('availabilityId', '');
          setValue('tables', undefined);
          break;
        case 'hour':
          setValue('tables', undefined);
          break;
        default:
          break;
      }
    },
    [isWalkIn, isEdit, setValue],
  );

  const convertToMinutes = (timeString: string): number => {
    let totalMinutes = 0;

    const hoursMatch = timeString.match(/(\d+)\s*(godz\.|hrs\.)/i);
    if (hoursMatch) {
      totalMinutes += parseInt(hoursMatch[1], 10) * 60;
    }

    const minutesMatch = timeString.match(/(\d+)\s*min\./);
    if (minutesMatch) {
      totalMinutes += parseInt(minutesMatch[1], 10);
    }

    return totalMinutes;
  };

  const getAvailableHours = (
    stringDate: string,
    countPersonNumber: number,
    reservationTime: number,
    walkIn: boolean,
  ) => {
    if (user?.placeId && reservationTime) {
      dispatch(
        listAvailableHoursThunk({
          date: stringDate,
          personCount: countPersonNumber,
          placeId: user.placeId,
          reservationTime,
          reservationId: singleReservation?.id || null,
          walkIn,
          timezone: placeTimezone || INITIAL_TIMEZONE,
          openingHours,
          placeSlotInterval,
          beforeReservationTime,
        }),
      );
    }
  };

  const handleDateChange = (selectedDate: Date) => {
    handleFieldChange('date');
    setValue('date', selectedDate);
    const stringDate = convertLocalDateToAdjustedUTC(selectedDate);
    const countPersonNumber = Number(getValues('countPerson'));
    const reservationTime = convertToMinutes(getValues('reservationTime'));
    getAvailableHours(stringDate, countPersonNumber, reservationTime, isWalkIn);
  };

  const setReservationTime = (time: string) => {
    handleFieldChange('reservationTime');
    setValue('reservationTime', time);

    if (isWalkIn) {
      const stringDate = convertLocalDateToAdjustedUTC(today);
      const countPersonNumber = Number(getValues('countPerson'));
      const reservationTime = convertToMinutes(getValues('reservationTime'));
      getAvailableHours(
        stringDate,
        countPersonNumber,
        reservationTime,
        isWalkIn,
      );
    }
  };

  const setHour = useCallback(
    (hourInput: string, availabilityId: string) => {
      handleFieldChange('hour');
      setValue('hour', hourInput);
      setValue('availabilityId', availabilityId);
    },
    [handleFieldChange, setValue],
  );

  const handleCancelReservation = () => {
    if (!singleReservation) return;
    dispatch(
      cancelReservationThunk({
        id: singleReservation.id,
      }),
    );
  };

  const handleCloseReservationModal = () => {
    hideCancelReservationModal();
    dispatch(resetCancelReservationStatus());
    onClose();
  };

  const generateTimeList = (
    minReservationTime: number,
    maxReservationTime: number,
    intervalMinutes: number,
  ): string[] => {
    const result: string[] = [];

    for (
      let i = minReservationTime;
      i <= maxReservationTime;
      i += intervalMinutes
    ) {
      const hours = Math.floor(i / 60);
      const minutes = i % 60;
      let timeString = `${hours} ${t('messages.hoursPlaceholder')}`;

      if (minutes !== 0) {
        timeString += ` ${minutes} ${t('messages.minutesPlaceholder')}`;
      }

      result.push(timeString);
    }

    return result;
  };

  const generateReservationTimeOptions = (
    minReservationTime: number,
    maxReservationTime: number,
    intervalMinutes: number,
    setTime: (time: string) => void,
  ): IDropdownOption[] => {
    const timeList = generateTimeList(
      minReservationTime,
      maxReservationTime,
      intervalMinutes,
    );

    return timeList.map((time) => ({
      label: time,
      onClick: () => setTime(time),
    }));
  };

  const reservationTimeOptions = generateReservationTimeOptions(
    placeMinReservationTime,
    placeMaxReservationTime,
    placeSlotInterval,
    setReservationTime,
  );

  const hourDropdownOptions = availableHours.map((availableHour) => ({
    label: availableHour.slot,
    onClick: () => {
      if (availableHour.slot && availableHour.avaId) {
        setHour(availableHour.slot, availableHour.avaId);
      }
    },
  }));

  const hourValue = getValues('hour');
  const selectedHour = availableHours.find((item) => item.slot === hourValue);

  let tableDropdownOptions: IDropdownOption[] = [];

  useEffect(() => {
    if (
      !selectedHour ||
      !getValues('date') ||
      !getValues('hour') ||
      !getValues('reservationTime') ||
      !getValues('availabilityId')
    ) {
      return;
    }
    const date = getValues('date');
    const hour = getValues('hour');
    const availabilityId = getValues('availabilityId');
    const reservationTime = timeStringToMinutes(getValues('reservationTime'));
    const countPerson = getValues('countPerson');

    if (!hour || !date) return;

    const formattedStartDate = convertToISODateString(
      hour,
      date,
      placeTimezone || INITIAL_TIMEZONE,
    );

    const formattedEndDate = addMinutesToISODateString(
      formattedStartDate,
      reservationTime,
    );

    if (user?.placeId) {
      dispatch(
        listFreeTablesThunk({
          placeId: user.placeId,
          dateStart: formattedStartDate,
          dateEnd: formattedEndDate,
          countPerson,
          reservationId: singleReservation?.id || null,
          availabilityId,
        }),
      );
    }
  }, [
    dispatch,
    getValues,
    selectedHour,
    user?.placeId,
    singleReservation?.id,
    placeTimezone,
  ]);

  tableDropdownOptions = freeTables.map((table) => ({
    label: table.name,
    seats: table.maxSeats,
    spacePlan: table.spacePlanName,
    onClick: () => {
      const chosenTable = {
        id: table.id,
        name: table.name,
        spacePlanName: table.spacePlanName,
      };
      setValue('tables', [chosenTable]);
    },
  }));

  useEffect(() => {
    if (visible && clientData) {
      setValue('fullName', clientData.name);
      setValue('phoneNumber', clientData.phoneNumber);
      if (clientData.email) {
        setValue('email', clientData.email);
      }
    }
    if (!visible) {
      clearErrors();
      reset();
      dispatch(clearAvailableHours());
      dispatch(clearCurrentReservation());
      setValue('hour', undefined);
      setValue('reservationTime', '');
      setValue('date', undefined);
      setIsWalkIn(!disableWalkin);
    }
  }, [
    visible,
    clearErrors,
    reset,
    setValue,
    dispatch,
    disableWalkin,
    clientData,
  ]);

  const setGuestFromAutocomplete = (
    fullName: string,
    phoneNumber: string,
    email: string | undefined,
  ) => {
    if (visible) {
      setValue('fullName', fullName);
      setValue('phoneNumber', phoneNumber);
      if (email) {
        setValue('email', email);
      }
    }
  };

  const autocompleteItems = (
    guest: TListClient,
    onAutocompleteClick: () => void,
  ) => (
    <span
      className="flex cursor-pointer flex-wrap items-center gap-3 p-2 hover:bg-brand-300"
      key={guest.id}
      onClick={() => {
        setGuestFromAutocomplete(
          `${guest.firstName} ${guest.lastName}`,
          guest.phoneNumber,
          guest.email,
        );
        onAutocompleteClick();
      }}
    >
      <span>{`${guest.firstName} ${guest.lastName}`}</span>
      {guest.tags.length > 0 && !isMobile && (
        <span className="max-w-[200px] truncate text-sm text-brand-1800">
          {guest.tags.join(', ')}
        </span>
      )}
      <span className="text-sm text-brand-1800">{guest.phoneNumber}</span>
      <span className="text-sm text-brand-1800">{guest.email}</span>
    </span>
  );

  useEffect(() => {
    if (isWalkIn && visible && !isEdit) {
      setValue('date', today);
      setValue('reservationTime', '');
    } else if (!isWalkIn && visible && !isEdit) {
      setValue('date', undefined);
      setValue('hour', undefined);
    }
  }, [isWalkIn, setValue, visible, isEdit]);

  useEffect(() => {
    if (isWalkIn && availableHours.length > 0 && !isEdit) {
      if (availableHours.length > 1) {
        const firstAvailableHour = availableHours[1];

        if (firstAvailableHour.avaId) {
          setHour(firstAvailableHour.slot, firstAvailableHour.avaId);
        }
      } else {
        const fallbackHour = availableHours[0];

        if (fallbackHour.avaId) {
          setHour(fallbackHour.slot, fallbackHour.avaId);
        }
      }
    }
  }, [availableHours, isWalkIn, isEdit, setHour]);

  useEffect(() => {
    if (isEdit && singleReservation) {
      setValue('isWalkIn', singleReservation.walkIn);
      setIsWalkIn(singleReservation.walkIn);
      setValue('additionalInfo', singleReservation.additionalInfo || '');
      setValue('availabilityId', singleReservation.availabilityId);
      setValue('countPerson', singleReservation.countPerson.toString());
      setValue('reservationTime', minutesToTimeString(singleReservation.time));
      setValue('date', new Date(singleReservation.dateStart));
      setValue(
        'tables',
        singleReservation.ReservationTable.map((table) => ({
          ...table,
          spacePlanName: singleReservation.spacePlanName,
        })),
      );
      setValue(
        'hour',
        extractHourFromIsoString(singleReservation.dateStart, placeTimezone),
      );
      setValue(
        'fullName',
        singleReservation.walkIn
          ? `${singleReservation.firstName} `
          : `${singleReservation.firstName} ${singleReservation.lastName}`,
      );
      setValue('phoneNumber', singleReservation.phoneNumber || '');
      setValue('email', singleReservation.email || '');

      const fetchedDate = getValues('date');
      if (fetchedDate) {
        const stringDate = convertLocalDateToAdjustedUTC(fetchedDate);
        const countPersonNumber = Number(getValues('countPerson'));
        const reservationTime = convertToMinutes(getValues('reservationTime'));
        getAvailableHours(
          stringDate,
          countPersonNumber,
          reservationTime,
          singleReservation.walkIn,
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, setValue, singleReservation]);

  const minDate: Date =
    contractStartDate && isBefore(today, new Date(contractStartDate))
      ? new Date(contractStartDate)
      : today;
  const maxDate: Date = (contractEndDate && new Date(contractEndDate)) || today;

  return (
    <Modal
      title={
        !isEdit ? t('messages.addReservation') : t('messages.editReservation')
      }
      visible={visible}
      onClose={onClose}
    >
      <ConfirmModal
        visible={isCancelReservationModalVisible}
        onClose={handleCloseReservationModal}
        questionText={t('messages.areYouSureYouWantToCancelThisReservation')}
        confirmText={t('messages.successfullyCanceledReservation')}
        confirmAction={handleCancelReservation}
        loadingStatus={cancelReservationStatus}
      />
      <form
        className="flex max-w-[744px] flex-col pt-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <ModalContent>
          {!isEdit && !disableWalkin && (
            <Controller
              control={control}
              name="isWalkIn"
              defaultValue={isWalkIn}
              render={({ field: { value, name, onChange } }) => (
                <div className="flex pb-4 pt-2">
                  <Checkbox
                    name={name}
                    labelText={t('messages.tableStatusesCategories.walkIn')}
                    labelTextColor="text-white"
                    isChecked={value}
                    onChange={(e) => {
                      onChange(e);
                      setIsWalkIn(e.target.checked);
                    }}
                    isLargeIcon
                  />
                </div>
              )}
            />
          )}
          {!isWalkIn && (
            <>
              <Controller
                control={control}
                name="fullName"
                defaultValue=""
                rules={{
                  required: {
                    value: true,
                    message: t('errorMessages.thisFieldIsRequired'),
                  },
                  pattern: {
                    value: fullNameRegex,
                    message: t('errorMessages.invalidFullName'),
                  },
                }}
                render={({ field: { value, name, onChange } }) => (
                  <div
                    className="relative flex min-h-[100px] gap-2"
                    ref={fullNameRef}
                  >
                    <Input
                      name={name}
                      type="text"
                      variant="secondary"
                      labelText={t('messages.guestName')}
                      placeholder={t('messages.typeGuestName')}
                      value={value}
                      onChange={onChange}
                      error={errors.fullName?.message}
                      maxLength={70}
                      isDisabled={!!clientData}
                      turnOffAutocomplete
                    />
                    <AutocompleteDropdown
                      wrapperRef={fullNameRef}
                      isOpenRule={
                        formData.fullName !== '' && guestAutocomplete.length > 0
                      }
                      list={guestAutocomplete}
                      isLoading={isGuestAutocompleteLoading}
                      renderItem={autocompleteItems}
                    />
                  </div>
                )}
              />
              <div
                className="flex flex-col gap-4 md:flex-1 md:flex-row md:gap-4"
                ref={phoneAndEmailRef}
              >
                <Controller
                  control={control}
                  name="phoneNumber"
                  defaultValue=""
                  rules={{
                    required: {
                      value: true,
                      message: t('errorMessages.thisFieldIsRequired'),
                    },
                    validate: {
                      isValid: (value) =>
                        isValidPhoneNumber(value) ||
                        t('errorMessages.invalidPhoneNumber'),
                    },
                  }}
                  render={({ field: { value, onChange, name } }) => (
                    <div className="min-h-[100px] w-full" ref={phoneNumberRef}>
                      <PhoneNumberInput
                        name={name}
                        labelText={t('messages.phoneNumber')}
                        placeholder={t('messages.typePhoneNumber')}
                        value={value}
                        onChange={onChange}
                        isDisabled={!!clientData}
                        error={errors.phoneNumber?.message}
                      />
                      {isMobile && (
                        <AutocompleteDropdown
                          wrapperRef={phoneNumberRef}
                          isOpenRule={
                            formData.phoneNumber !== '' &&
                            guestAutocomplete.length > 0
                          }
                          list={guestAutocomplete}
                          isLoading={isGuestAutocompleteLoading}
                          renderItem={autocompleteItems}
                        />
                      )}
                    </div>
                  )}
                />
                <div className="flex gap-2 md:min-w-[338px]">
                  <Controller
                    control={control}
                    name="email"
                    defaultValue=""
                    render={({ field: { value, name, onChange } }) => (
                      <Input
                        name={name}
                        type="text"
                        variant="secondary"
                        labelText={t('messages.emailOptional')}
                        placeholder={t('messages.typeEmailAddress')}
                        value={value}
                        onChange={onChange}
                        error={errors.email?.message}
                        maxLength={256}
                        isDisabled={!!clientData?.email}
                        turnOffAutocomplete
                      />
                    )}
                  />
                </div>
                <AutocompleteDropdown
                  wrapperRef={phoneAndEmailRef}
                  isOpenRule={
                    (isMobile
                      ? formData.email !== ''
                      : formData.phoneNumber !== '' || formData.email !== '') &&
                    guestAutocomplete.length > 0
                  }
                  list={guestAutocomplete}
                  isLoading={isGuestAutocompleteLoading}
                  renderItem={autocompleteItems}
                />
              </div>
            </>
          )}
          <div className="flex flex-col gap-4 md:flex-row">
            <Controller
              control={control}
              name="countPerson"
              defaultValue=""
              rules={{
                required: {
                  value: true,
                  message: t('errorMessages.thisFieldIsRequired'),
                },
              }}
              render={({ field: { value, name, onChange } }) => (
                <InputPicker
                  name={name}
                  options={numberOfPeopleArray}
                  label={t('messages.numberOfPeople')}
                  placeholder={t('messages.enterNumberOfPeople')}
                  value={value}
                  onChange={(e) => {
                    handleFieldChange('countPerson');
                    onChange(e);
                  }}
                  error={errors.countPerson?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="reservationTime"
              defaultValue={undefined}
              rules={{
                required: {
                  value: true,
                  message: t('errorMessages.thisFieldIsRequired'),
                },
              }}
              render={({ field: { value } }) => (
                <div className="flex min-h-[100px] flex-col gap-2">
                  <span className="text-sm text-white">
                    {t('messages.duration')}
                  </span>
                  <InputDropdown
                    placeholder={t('messages.chooseDuration')}
                    value={value?.toString()}
                    options={reservationTimeOptions}
                    error={errors.reservationTime?.message}
                    variant="modal"
                    isDisabled={!isReservationTimeFieldUnlocked}
                  />
                </div>
              )}
            />
          </div>
          <div className="flex flex-col gap-4 md:flex-row">
            <Controller
              control={control}
              name="date"
              defaultValue={undefined}
              rules={{
                required: {
                  value: true,
                  message: t('errorMessages.thisFieldIsRequired'),
                },
              }}
              render={({ field: { value } }) => (
                <div className="flex flex-col md:flex-1">
                  <InputDate
                    labelText={t('messages.date')}
                    name="date"
                    placeholder={t('messages.chooseDate')}
                    value={value}
                    onChange={(date) => handleDateChange(date)}
                    error={errors.date?.message}
                    isDisabled={!isDateFieldUnlocked || isWalkIn}
                    minDate={minDate}
                    maxDate={maxDate}
                  />
                </div>
              )}
            />
            <Controller
              control={control}
              name="hour"
              defaultValue={undefined}
              rules={{
                required: {
                  value: true,
                  message: t('errorMessages.thisFieldIsRequired'),
                },
              }}
              render={({ field: { value } }) => (
                <div className="flex min-h-[100px] flex-col gap-2">
                  <span
                    className={clsx(
                      'text-sm',
                      isHourFieldUnlocked ? 'text-white' : 'text-brand-1200',
                    )}
                  >
                    {t('messages.hour')}
                  </span>
                  <InputDropdown
                    placeholder={
                      isHourSlotsListEmpty
                        ? t('messages.noAvailableHours')
                        : t('messages.chooseHour')
                    }
                    value={value}
                    options={hourDropdownOptions || []}
                    error={errors.hour?.message}
                    isDisabled={
                      !isHourFieldUnlocked ||
                      isHourSlotsListEmpty ||
                      availableHoursStatus === reduxStatus.loading
                    }
                    variant="modal"
                    isHourInput
                    additionalIcon={
                      availableHoursStatus === reduxStatus.loading ? (
                        <SectionLoader />
                      ) : (
                        <ClockIcon
                          className={clsx(
                            !isHourFieldUnlocked || isHourSlotsListEmpty
                              ? 'fill-brand-1200'
                              : 'fill-white',
                          )}
                        />
                      )
                    }
                  />
                </div>
              )}
            />
          </div>
          <div className="min-h-[100px]">
            <Controller
              control={control}
              name="tables"
              defaultValue={undefined}
              rules={{
                required: {
                  value: true,
                  message: t('errorMessages.thisFieldIsRequired'),
                },
              }}
              render={({ field: { value } }) => (
                <div className="flex min-h-[100px] flex-col gap-2">
                  <span
                    className={clsx(
                      'text-sm',
                      isTableFieldUnlocked ? 'text-white' : 'text-brand-1200',
                    )}
                  >
                    {t('messages.table')}
                  </span>
                  <InputDropdown
                    placeholder={t('messages.chooseTable')}
                    value={
                      value ? value.map((table) => table.name).join(', ') : ''
                    }
                    options={tableDropdownOptions || []}
                    error={errors.tables?.message}
                    isDisabled={!isTableFieldUnlocked || isEmpty(freeTables)}
                    variant="modal"
                  />
                </div>
              )}
            />
            {!isEdit && (
              <Controller
                control={control}
                name="language"
                defaultValue={RESERVATION_LANGUAGES[0]}
                render={({ field: { value, onChange } }) => (
                  <div className="flex min-h-[100px] flex-col gap-2">
                    <span className="text-sm text-white">
                      {t('messages.preferredLanguageOfTheBooker')}
                    </span>
                    <ReservationLanguageSelector
                      handleLanguageChange={onChange}
                      selectedLanguage={value}
                    />
                  </div>
                )}
              />
            )}
            <div className="min-h-[100px]">
              <Controller
                control={control}
                name="additionalInfo"
                defaultValue=""
                render={({ field: { value, name, onChange } }) => (
                  <Input
                    name={name}
                    type="text"
                    variant="secondary"
                    labelText={t('messages.additionalInfoOptional')}
                    placeholder={t('messages.typeAdditionalInfo')}
                    value={value}
                    onChange={onChange}
                    error={errors.additionalInfo?.message}
                    maxLength={256}
                  />
                )}
              />
            </div>
          </div>
        </ModalContent>
        <div className="mt-4 flex flex-wrap justify-end gap-4">
          {isEdit ? (
            <>
              <ButtonWithIcon
                type="button"
                text={t('messages.cancelReservation')}
                variant="secondary"
                icon={TrashIcon}
                isDeleteButton
                onClick={showCancelReservationModal}
              />
              <Button
                type="submit"
                text={
                  isEdit
                    ? t('messages.updateReservation')
                    : t('messages.addNewReservation')
                }
                variant="secondary"
                isLoading={isEdit ? isUpdateLoading : isCreateLoading}
                isDisabled={isUpdateLoading || isCreateLoading}
              />
            </>
          ) : (
            <Button
              type="submit"
              text={t('messages.addReservation')}
              variant="secondary"
              isLoading={isEdit ? isUpdateLoading : isCreateLoading}
              isDisabled={isUpdateLoading || isCreateLoading}
            />
          )}
        </div>
      </form>
    </Modal>
  );
};

export default ReservationModal;

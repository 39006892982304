import { SVGProps } from 'react';

const ChatIcon = ({ className }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.87533 11.4577H11.7503V10.2077H4.87533V11.4577ZM4.87533 8.64516H15.1253V7.3952H4.87533V8.64516ZM4.87533 5.83266H15.1253V4.5827H4.87533V5.83266ZM1.41699 18.4063V1.16602H18.5836V14.916H4.90739L1.41699 18.4063Z"
        fill="currentColor"
        className={className}
      />
    </svg>
  );
};

export default ChatIcon;

import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';

import PrimaryHeader from '../../UI/atoms/PrimaryHeader/PrimaryHeader';
import OfferListSkeleton from '../../UI/atoms/OfferListSkeleton/OfferListSkeleton';
import PageEmptyState from '../../UI/molecules/PageEmptyState/PageEmptyState';
import useModal from '../../../hooks/useModal';
import OfferModal from '../../UI/organisms/OfferModal/OfferModal';
import ButtonWithIcon from '../../UI/molecules/ButtonWithIcon/ButtonWithIcon';
import PlusIcon from '../../../assets/icons/PlusIcon';
import OfferList from '../../UI/organisms/OfferList/OfferList';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import { reduxStatus } from '../../../constants/constants';
import {
  deleteOfferThunk,
  listOffersThunk,
} from '../../../redux/thunks/offerThunk';
import ConfirmModal from '../../UI/molecules/ConfirmModal/ConfirmModal';
import { resetDeleteOfferStatus } from '../../../redux/features/offerSlice';

const OfferPage = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const offers = useAppSelector((state) => state.offer.offers);

  const offer = useAppSelector((state) => state.offer.offer);

  const user = useAppSelector((state) => state.user.user);

  const isEmployee = user?.role === 'EMPLOYEE';

  const listOffersStatus = useAppSelector(
    (state) => state.offer.listOffersStatus,
  );

  const deleteOfferStatus = useAppSelector(
    (state) => state.offer.deleteOfferStatus,
  );

  const isLoading = listOffersStatus === reduxStatus.loading;

  const {
    visible: isCreateOfferModalVisible,
    showModal: showCreateOfferyModal,
    hideModal: hideCreateOfferModal,
  } = useModal();

  const {
    visible: isEditOfferModalVisible,
    showModal: showEditOfferModal,
    hideModal: hideEditOfferModal,
  } = useModal();

  const {
    visible: isDeleteOfferModalVisible,
    showModal: showDeleteOfferModal,
    hideModal: hideDeleteOfferModal,
  } = useModal();

  const handleCloseDeleteOfferModal = () => {
    hideDeleteOfferModal();
    dispatch(resetDeleteOfferStatus());
  };

  const handleDeleteOffer = () => {
    if (!offers || !offer) return;
    const newOffers = offers.filter((item) => item.id !== offer.id);
    dispatch(
      deleteOfferThunk({ offers: newOffers, callback: hideEditOfferModal }),
    );
  };

  useEffect(() => {
    dispatch(listOffersThunk());
  }, [dispatch]);

  return (
    <div className="flex h-full w-full flex-col">
      <OfferModal
        visible={isCreateOfferModalVisible}
        onClose={hideCreateOfferModal}
        showDeleteOfferModal={showDeleteOfferModal}
      />
      <OfferModal
        visible={isEditOfferModalVisible}
        onClose={hideEditOfferModal}
        showDeleteOfferModal={showDeleteOfferModal}
        isEdit
      />
      <ConfirmModal
        visible={isDeleteOfferModalVisible}
        onClose={handleCloseDeleteOfferModal}
        questionText={t('messages.areYouSureYouWantToDeleteThisItem')}
        confirmText={t('messages.itemDeletedSuccessfully')}
        confirmAction={handleDeleteOffer}
        loadingStatus={deleteOfferStatus}
      />
      <div className="flex w-full flex-row justify-between gap-4 md:h-[42px]">
        <div className="flex flex-col flex-wrap justify-center gap-4">
          <PrimaryHeader text={t('messages.offers')} />
        </div>
        {!isEmpty(offers) ? (
          <div className="flex flex-wrap justify-end gap-4">
            <ButtonWithIcon
              type="button"
              text={t('messages.addOffer')}
              icon={PlusIcon}
              variant="primary"
              onClick={showCreateOfferyModal}
              isDisabled={isEmployee}
              isJustIconOnMobile
            />
          </div>
        ) : null}
      </div>
      <p className="mt-4 md:mt-3">{t('messages.dragDropDescription')}</p>
      <div className="flex h-[calc(100%-64px)] w-full">
        {isLoading && <OfferListSkeleton />}
        {(isEmpty(offers) || offers == null) && !isLoading && (
          <PageEmptyState
            headerText={t('messages.createOfferTitleEmptyState')}
            paragraphText={t('messages.createOfferDescriptionEmptyState')}
            buttonText={t('messages.addNewOffer')}
            onButtonClick={showCreateOfferyModal}
            isButtonDisabled={isEmployee}
          />
        )}
        {!isEmpty(offers) && offers != null && !isLoading && (
          <OfferList
            offers={offers}
            showDeleteModal={showDeleteOfferModal}
            showEditModal={showEditOfferModal}
          />
        )}
      </div>
    </div>
  );
};

export default OfferPage;

import { FC } from 'react';

import { IPrimaryHeaderProps } from './PrimaryHeader.types';

const PrimaryHeader: FC<IPrimaryHeaderProps> = ({ text, breakWords }) => {
  return (
    <h1
      className="text-2xl font-semibold md:text-3xl"
      style={{ overflowWrap: breakWords ? 'anywhere' : 'normal' }}
    >
      {text}
    </h1>
  );
};

export default PrimaryHeader;

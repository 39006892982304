import { TTableStatuses } from '../types/Place';
import { TTableReservationStatus } from '../types/Reservation';

const hexToRgb = (hex: string): { r: number; g: number; b: number } => {
  const bigint = parseInt(hex.slice(1), 16);

  const r = (bigint >> 16) & 255;

  const g = (bigint >> 8) & 255;

  const b = bigint & 255;

  return { r, g, b };
};

const rgbToHex = (r: number, g: number, b: number): string => {
  r = Math.min(255, Math.max(0, r));
  g = Math.min(255, Math.max(0, g));
  b = Math.min(255, Math.max(0, b));

  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
};

const rgbToHsl = (
  r: number,
  g: number,
  b: number,
): { h: number; s: number; l: number } => {
  // Convert RGB to the range [0, 1]
  r /= 255;
  g /= 255;
  b /= 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h = 0;
  let s = 0;
  const l = (max + min) / 2;

  if (max !== min) {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
      default:
        break;
    }
    h /= 6;
  }
  return { h: h * 360, s: s * 100, l: l * 100 };
};

const hslToRgb = (
  h: number,
  s: number,
  l: number,
): { r: number; g: number; b: number } => {
  // Convert HSL values to the range [0, 1]
  h /= 360;
  s /= 100;
  l /= 100;

  let r = 0;
  let g = 0;
  let b = 0;

  if (s === 0) {
    // Achromatic (grey)
    r = 1;
    g = 1;
    b = 1;
  } else {
    // Convert hue to RGB
    const hue2rgb = (p: number, q: number, t: number) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 3) return q;
      if (t < 1 / 2) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return {
    r: Math.round(r * 255),
    g: Math.round(g * 255),
    b: Math.round(b * 255),
  };
};

export const getContrastingShade = (hex: string): string => {
  const { r, g, b } = hexToRgb(hex);
  const { h, s, l } = rgbToHsl(r, g, b);

  // Adjust lightness: if it's light, make it darker; if it's dark, make it lighter
  const newL = l > 50 ? Math.max(l - 30, 0) : Math.min(l + 30, 100);

  const { r: newR, g: newG, b: newB } = hslToRgb(h, s, newL);

  return rgbToHex(newR, newG, newB);
};

export const getTableReservationColors = (
  tableStatus: TTableReservationStatus,
  tableStatusesColors: TTableStatuses,
): { primaryColor: string; secondaryColor: string } => {
  switch (tableStatus) {
    case 'noSmsConfirmation':
      return {
        primaryColor: tableStatusesColors.reservationWithoutSms,
        secondaryColor: getContrastingShade(
          tableStatusesColors.reservationWithoutSms,
        ),
      };
    case 'late':
      return {
        primaryColor: tableStatusesColors.reservationDelayed,
        secondaryColor: getContrastingShade(
          tableStatusesColors.reservationDelayed,
        ),
      };
    case 'started':
      return {
        primaryColor: tableStatusesColors.reservationInProgress,
        secondaryColor: getContrastingShade(
          tableStatusesColors.reservationInProgress,
        ),
      };
    case 'past':
      return {
        primaryColor: tableStatusesColors.freeTable,
        secondaryColor: getContrastingShade(tableStatusesColors.freeTable),
      };
    case 'cancelled':
      return {
        primaryColor: tableStatusesColors.reservationCanceled,
        secondaryColor: getContrastingShade(
          tableStatusesColors.reservationCanceled,
        ),
      };
    case 'smsConfirmed':
      return {
        primaryColor: tableStatusesColors.reservationWithSms,
        secondaryColor: getContrastingShade(
          tableStatusesColors.reservationWithSms,
        ),
      };
    case null:
      return {
        primaryColor: tableStatusesColors.freeTable,
        secondaryColor: getContrastingShade(tableStatusesColors.freeTable),
      };
    default:
      tableStatus satisfies never;
      return {
        primaryColor: tableStatusesColors.freeTable,
        secondaryColor: getContrastingShade(tableStatusesColors.freeTable),
      };
  }
};

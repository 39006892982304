import { FC } from 'react';
import clsx from 'clsx';

import { IModalContentProps } from './ModalContent.types';
import { useCustomScrollbar } from '../../../../hooks/useCustomScrollbar';

const ModalContent: FC<IModalContentProps> = ({
  children,
  modalContentRef,
  isChatModal,
}) => {
  const { isScrolling, handleScroll } = useCustomScrollbar();

  return (
    <div
      ref={modalContentRef}
      className={clsx(
        'custom-scrollbar flex h-full  w-full flex-col gap-2 overflow-x-auto pb-1 pr-2',
        isScrolling && 'scrolling',
        !isChatModal && 'max-h-[calc(100dvh-250px)]',
      )}
      onScroll={handleScroll}
    >
      {children}
    </div>
  );
};

export default ModalContent;

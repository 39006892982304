import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import GuestDetailsHeader from '../../UI/molecules/GuestDetailsHeader/GuestDetailsHeader';
import GuestDetailsVisits from '../../UI/molecules/GuestDetailsVisits/GuestDetailsVisits';
import GuestDetailsExtraInformation from '../../UI/molecules/GuestDetailsExtraInformation/GuestDetailsExtraInformation';
import GuestDetailsContact from '../../UI/molecules/GuestDetailsContact/GuestDetailsContact';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import { getClientThunk } from '../../../redux/thunks/guestBookThunk';
import { reduxStatus, routes } from '../../../constants/constants';
import GuestDetailsHeaderSkeleton from '../../UI/atoms/GuestDetailsHeaderSkeleton/GuestDetailsHeaderSkeleton';
import GuestDetailsVisitsSkeleton from '../../UI/atoms/GuestDetailsVisitsSkeleton/GuestDetailsVisitsSkeleton';
import GuestDetailsExtraInformationSkeleton from '../../UI/atoms/GuestDetailsExtraInformationSkeleton/GuestDetailsExtraInformationSkeleton';

const GuestDetailsPage = () => {
  const dispatch = useAppDispatch();

  const { guestId } = useParams();

  const navigate = useNavigate();

  const guest = useAppSelector((state) => state.guestBook.singleGuest);

  const guestStatus = useAppSelector(
    (state) => state.guestBook.singleGuestStatus,
  );

  const isLoading = guestStatus === reduxStatus.loading || !guest;

  useEffect(() => {
    if (!guestId) {
      navigate(routes.guestBook);
      return;
    }
    if (!guest || (guest && guest.id !== guestId)) {
      dispatch(getClientThunk(guestId));
    }
  }, [dispatch, guest, guestId, navigate]);

  if (isLoading) {
    return (
      <div className="flex h-full w-full flex-col overflow-y-auto pr-2">
        <GuestDetailsHeaderSkeleton />
        <div className="mt-6 flex flex-col gap-6">
          <GuestDetailsVisitsSkeleton />
          <GuestDetailsExtraInformationSkeleton />
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-full w-full flex-col overflow-y-auto pr-2">
      <GuestDetailsHeader
        id={guest.id}
        name={`${guest.firstName} ${guest.lastName}`}
        phoneNumber={guest.phoneNumber}
        company={guest.company}
        email={guest.email}
        position={guest.position}
      />

      <div className="mt-6 flex flex-col gap-6">
        <GuestDetailsVisits
          clientId={guest.id}
          fullName={`${guest.firstName} ${guest.lastName}`}
          firstReservation={guest.firstVisit}
          lastReservation={guest.lastReservationDate}
          nextReservation={guest.nextReservation}
          countReservations={guest.countReservations}
        />
        <GuestDetailsExtraInformation
          language={guest.language || 'pl'}
          notes={guest.additionalInfo || undefined}
          tags={guest.tags}
        />
        <GuestDetailsContact
          phoneNumber={guest.phoneNumber}
          email={guest.email}
        />
      </div>
    </div>
  );
};

export default GuestDetailsPage;

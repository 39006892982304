import { z } from 'zod';

import { RESERVATION_STATUSES } from '../constants/constants';

export const ReservationTableSchema = z.object({
  id: z.string(),
  maxSeats: z.number(),
  minSeats: z.number(),
  name: z.string(),
  spacePlanId: z.string(),
  spacePlanName: z.string(),
});

export const ReservationTablesGroupSchema = z.object({
  groupId: z.string(),
  tablesGroup: z.array(ReservationTableSchema),
});

const HourSlotSchema = z.object({
  free: z.number(),
  avaId: z.string(),
});

const HourSlotsSchema = z.record(z.string(), HourSlotSchema).or(z.object({}));

export const ListAvailableHoursDataSchema = z.object({
  maxCapacity: z.number(),
  slotInterval: z.number().optional(),
  slots: HourSlotsSchema,
});

export const ListAvailableHoursSchema = z.object({
  data: ListAvailableHoursDataSchema,
  nextDayAvailability: HourSlotsSchema.optional(),
});

export const ReservationTableItemSchema = z.object({
  id: z.string(),
  name: z.string(),
});

export const ReservationSchema = z.object({
  id: z.string(),
  ReservationTable: z.array(ReservationTableItemSchema),
  spacePlanName: z.string().nullable(),
  availabilityId: z.string().nullable(),
  countPerson: z.number(),
  dateEnd: z.string(),
  dateStart: z.string(),
  email: z.string().nullable(),
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
  status: z.enum(RESERVATION_STATUSES),
  phoneNumber: z.string().nullable(),
  additionalInfo: z.string().nullable(),
  createdAt: z.string(),
  messageCount: z.number().optional(),
  userId: z.string().nullable().optional(),
});

export const ListSummaryPageReservationsSchema = z.object({
  data: z.array(ReservationSchema),
  successCode: z.string(),
});

export const ListReservationsSchema = z.object({
  data: z.array(ReservationSchema),
  successCode: z.string(),
});

export const CreatedNewReservationSchema = z.object({
  id: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  userId: z.string().nullable(),
});

export const ReservationTablesSchema = z.array(ReservationTableSchema);

export const ListFreeReservationTablesSchema = z.object({
  data: ReservationTablesSchema,
  successCode: z.string(),
});

export const ReservationSocketStatusChangeSchema = z.object({
  reservationId: z.string().uuid(),
});

export const GetReservationResponseSchema = z.object({
  successCode: z.string(),
  data: ReservationSchema,
});

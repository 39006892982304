import { FC } from 'react';
import clsx from 'clsx';

import CloseIcon from '../../../../assets/icons/CloseIcon';
import { ITagButtonProps } from './TagButton.types';

const TagButton: FC<ITagButtonProps> = ({ value, onDelete }) => {
  return (
    <span
      className={clsx(
        'flex w-fit items-center gap-2 rounded-xs px-3 py-1 text-sm',
        onDelete ? 'bg-brand-700' : 'bg-brand-300',
      )}
    >
      {value}
      {onDelete && (
        <button
          type="button"
          className="flex scale-75"
          onClick={() => onDelete(value)}
        >
          <CloseIcon />
        </button>
      )}
    </span>
  );
};

export default TagButton;

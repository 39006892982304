import clsx from 'clsx';

import { ITableProps } from './Table.types';

const EightSeatedCircleTable = ({
  primaryColor = '#3F394D',
  secondaryColor = '#B3ADC1',
  disabled,
}: ITableProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={76}
      height={75}
      fill="none"
      className={clsx(disabled && 'opacity-50')}
    >
      <path
        fill={secondaryColor}
        fillRule="evenodd"
        d="M43.168 65.143q-.938.24-1.898.422c-1.89.359-3.839.546-5.833.546a31.3 31.3 0 0 1-7.73-.968 31 31 0 0 1-1.87-.541q-.03.117-.053.239a3.89 3.89 0 0 0 3.097 4.545c2.126.404 4.319.614 6.557.614 2.237 0 4.43-.21 6.557-.614a3.89 3.89 0 0 0 3.043-4.784 31 31 0 0 1-1.87.541m6.413-2.425q.06.104.13.205a3.89 3.89 0 0 0 5.404 1.026 35.2 35.2 0 0 0 9.271-9.272 3.89 3.89 0 0 0-1.23-5.534 31 31 0 0 1-1.984 3.345 31.3 31.3 0 0 1-11.591 10.23M65.039 44.6q.118.03.24.053c2.11.4 4.144-.986 4.545-3.096.403-2.127.614-4.32.614-6.557s-.211-4.43-.614-6.557a3.89 3.89 0 0 0-4.785-3.043 31 31 0 0 1 .964 3.768c.358 1.889.546 3.839.546 5.832a31.3 31.3 0 0 1-.968 7.73 31 31 0 0 1-.542 1.87m-1.884-23.743q.105-.062.205-.13a3.89 3.89 0 0 0 1.026-5.404 35.2 35.2 0 0 0-9.271-9.272 3.89 3.89 0 0 0-5.534 1.231 31 31 0 0 1 3.345 1.984 31.3 31.3 0 0 1 10.23 11.59M45.038 5.398q.03-.117.053-.239A3.89 3.89 0 0 0 41.995.614 35 35 0 0 0 35.438 0c-2.238 0-4.43.21-6.558.614a3.89 3.89 0 0 0-3.043 4.784 31 31 0 0 1 3.768-.963 31.3 31.3 0 0 1 5.833-.546 31.3 31.3 0 0 1 7.73.968q.947.242 1.87.541M21.294 7.282a3.889 3.889 0 0 0-5.534-1.23 35.2 35.2 0 0 0-9.271 9.27 3.89 3.89 0 0 0 1.23 5.535 31 31 0 0 1 1.984-3.345 31.3 31.3 0 0 1 11.591-10.23M5.836 25.4a3.889 3.889 0 0 0-4.784 3.043A35 35 0 0 0 .436 35c0 2.238.211 4.43.614 6.557A3.89 3.89 0 0 0 5.836 44.6a31 31 0 0 1-.964-3.768A31.3 31.3 0 0 1 4.326 35a31.3 31.3 0 0 1 .968-7.73q.242-.948.542-1.87M7.72 49.143q-.105.062-.205.13a3.89 3.89 0 0 0-1.026 5.404 35.2 35.2 0 0 0 9.271 9.272 3.89 3.89 0 0 0 5.534-1.231 31 31 0 0 1-3.345-1.984 31.3 31.3 0 0 1-10.23-11.59"
        clipRule="evenodd"
      />
      <path
        fill={primaryColor}
        stroke={secondaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M3.438 35c0-17.673 14.326-32 32-32 17.673 0 32 14.327 32 32s-14.327 32-32 32-32-14.327-32-32"
      />
    </svg>
  );
};

export default EightSeatedCircleTable;

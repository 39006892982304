import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { v4 as uuid } from 'uuid';

import { getTableReservationColors } from '../../../../functions/colors';
import ChatIcon from '../../../../assets/icons/ChatIcon';
import MoreOptionsDropdown from '../../atoms/MoreOptionsDropdown/MoreOptionsDropdown';
import Badge from '../../atoms/Badge/Badge';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { getTableResevationStatus } from '../../../../functions/tables';
import { IDropdownOption } from '../../atoms/MoreOptionsDropdown/MoreOptionsDropdown.types';
import useMobile from '../../../../hooks/useIsMobile';
import PenIcon from '../../../../assets/icons/PenIcon';
import TrashIcon from '../../../../assets/icons/TrashIcon';
import { IGuestDetailsVisitsProps } from './GuestDetailsVisits.types';
import {
  extractHourFromIsoString,
  getZonedTimeFromIsoDateAndFormat,
} from '../../../../functions/functions';
import { setChatModalVisible } from '../../../../redux/features/chatSlice';
import { TChatReservation } from '../../../../types/Chat';
import { listMessagesThunk } from '../../../../redux/thunks/chatThunk';
import ChatModal from '../../organisms/ChatModal/ChatModal';
import useModal from '../../../../hooks/useModal';
import {
  cancelReservationThunk,
  getReservationThunk,
} from '../../../../redux/thunks/reservationThunk';
import ReservationModal from '../../organisms/ReservationModal/ReservationModal';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import { resetCancelReservationStatus } from '../../../../redux/features/reservationSlice';
import { getClientThunk } from '../../../../redux/thunks/guestBookThunk';
import { resetChatMessageCount } from '../../../../redux/features/GuestBookSlice';
import { reduxStatus } from '../../../../constants/constants';

const GuestDetailsVisits: FC<IGuestDetailsVisitsProps> = ({
  clientId,
  fullName,
  firstReservation,
  lastReservation,
  nextReservation,
  countReservations,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const tables = nextReservation?.ReservationTable
    ? nextReservation.ReservationTable.map((table) => table.name)
    : null;

  const tableStatuses = useAppSelector((store) => store.settings.tableStatuses);

  const user = useAppSelector((state) => state.user.user);

  const placeTimezone = useAppSelector((state) => state.settings.timezone);

  const cancelReservationStatus = useAppSelector(
    (state) => state.reservation.cancelReservationStatus,
  );

  const tableReservationStatus = nextReservation
    ? getTableResevationStatus(
        nextReservation.status,
        nextReservation.dateStart,
      )
    : null;

  const isEmployee = user?.role === 'EMPLOYEE';

  const isMobile = useMobile();

  const {
    visible: isChatModalVisible,
    showModal: showChatModal,
    hideModal: hideChatModal,
  } = useModal();

  const {
    visible: isReservationEditModalVisible,
    showModal: showReservationEditModal,
    hideModal: hideReservationEditModal,
  } = useModal();

  const {
    visible: isCancelReservationModalVisible,
    showModal: showCancelReservationModal,
    hideModal: hideCancelReservationModal,
  } = useModal();

  const handleEditReservation = () => {
    if (!nextReservation) return;
    dispatch(
      getReservationThunk({
        id: nextReservation.id,
        timezone: placeTimezone,
      }),
    );
    showReservationEditModal();
  };

  const dropdownOptions: IDropdownOption[] = [
    {
      label: t('messages.edit'),
      icon: PenIcon,
      onClick: handleEditReservation,
    },
    {
      label: t('messages.delete'),
      icon: TrashIcon,
      onClick: showCancelReservationModal,
      isDelete: true,
    },
  ];

  const handleOpenChatModal = () => {
    if (!nextReservation || !fullName) return;
    const reservationData: TChatReservation = {
      id: nextReservation.id,
      countPerson: nextReservation.countPerson,
      createdAt: nextReservation.dateStart,
      dateStart: nextReservation.dateStart,
      fullName,
      ReservationTable: nextReservation.ReservationTable.map((table) => {
        return { name: table.name, id: uuid() };
      }),
    };
    showChatModal();
    dispatch(
      setChatModalVisible({
        reservation: reservationData,
      }),
    );
    dispatch(
      listMessagesThunk({ reservationId: reservationData.id, limit: 10 }),
    );
    dispatch(resetChatMessageCount());
  };

  const handleCloseReservationModal = () => {
    hideCancelReservationModal();
    if (clientId && cancelReservationStatus === reduxStatus.success) {
      dispatch(resetCancelReservationStatus());
      dispatch(getClientThunk(clientId));
    }
  };

  const handleCancelReservation = () => {
    if (!nextReservation || !clientId) return;
    dispatch(
      cancelReservationThunk({
        id: nextReservation.id,
      }),
    );
  };

  return (
    <>
      <ReservationModal
        visible={isReservationEditModalVisible}
        onClose={hideReservationEditModal}
        isEdit
        disableWalkin
      />
      <ConfirmModal
        visible={isCancelReservationModalVisible}
        onClose={handleCloseReservationModal}
        questionText={t('messages.areYouSureYouWantToCancelThisReservation')}
        confirmText={t('messages.successfullyCanceledReservation')}
        confirmAction={handleCancelReservation}
        loadingStatus={cancelReservationStatus}
      />

      <ChatModal visible={isChatModalVisible} onClose={hideChatModal} />
      <div className="flex flex-wrap gap-2 text-center md:gap-4">
        <div className="flex flex-1 flex-col rounded-xs bg-brand-300 p-3 md:px-6 md:py-4">
          <span className="text-[36px] font-bold leading-[45px]">
            {countReservations.planned}
          </span>
          <span className="text-xs font-medium md:text-sm">
            {t('messages.upcomingReservations')}
          </span>
        </div>
        <div className="flex flex-1 flex-col rounded-xs bg-brand-300 px-6 py-4">
          <span className="text-brand-green text-[36px] font-bold leading-[45px]">
            {countReservations.done}
          </span>
          <span className="text-xs font-medium md:text-sm">
            {t('messages.completedReservations')}
          </span>
        </div>
        <div className="flex flex-1 flex-col rounded-xs bg-brand-300 px-6 py-4">
          <span className="text-brand-red text-[36px] font-bold leading-[45px]">
            {countReservations.cancelled}
          </span>
          <span className="text-xs font-medium md:text-sm">
            {t('messages.cancelledReservations')}
          </span>
        </div>
      </div>
      {/* LAST AND FIRST VISIT */}
      <div className="flex flex-col gap-6 sm:flex-row">
        <div className="flex flex-1 flex-col gap-4 rounded-xs border border-brand-700 p-6">
          <span className="text-lg font-semibold">
            {t('messages.lastVisit')}
          </span>
          <span className="text-base font-medium">
            {lastReservation
              ? getZonedTimeFromIsoDateAndFormat(lastReservation, placeTimezone)
              : '-'}
          </span>
        </div>
        <div className="flex flex-1 flex-col gap-4 rounded-xs border border-brand-700 p-6">
          <span className="w-full text-lg font-semibold">
            {t('messages.firstVisit')}
          </span>
          <span className="text-base font-medium">
            {firstReservation
              ? getZonedTimeFromIsoDateAndFormat(
                  firstReservation,
                  placeTimezone,
                )
              : '-'}
          </span>
        </div>
      </div>

      {/* NEXT VISIT */}
      <div className="flex flex-col rounded-sm border border-brand-700 p-6">
        <span className="text-lg font-semibold">{t('messages.nextVisit')}</span>

        {nextReservation ? (
          <div className="mt-6 flex justify-between">
            <div className="flex w-full items-center gap-4 md:justify-between">
              <span className="rounded-xs border border-white px-4 py-1.5 text-sm">
                {extractHourFromIsoString(
                  nextReservation.dateStart,
                  placeTimezone,
                )}
              </span>
              <span className="text-base font-medium">
                {getZonedTimeFromIsoDateAndFormat(
                  nextReservation.dateStart,
                  placeTimezone,
                )}
              </span>
              {!isMobile && (
                <>
                  <span className="text-sm font-semibold text-brand-1700">
                    {t('messages.table')}: {tables?.join(', ') || '-'}
                  </span>
                  <span className="text-sm font-semibold text-brand-1700">
                    {t('messages.numberOfPeople')}:{' '}
                    {nextReservation?.countPerson}
                  </span>
                  <Badge
                    color={
                      getTableReservationColors(
                        tableReservationStatus,
                        tableStatuses,
                      ).primaryColor
                    }
                    isReservationList
                  >
                    {t(`messages.reservationStates.${tableReservationStatus}`)}
                  </Badge>
                </>
              )}
            </div>
            <div className="relative flex gap-4 md:ml-8">
              <button
                type="button"
                className="relative"
                onClick={handleOpenChatModal}
              >
                <ChatIcon />
                {nextReservation.messageCount > 0 && (
                  <div className="absolute -right-1.5 -top-0 flex h-3 w-3 items-center justify-center rounded-full bg-red-700" />
                )}
              </button>
              {!isEmployee && <MoreOptionsDropdown options={dropdownOptions} />}
            </div>
          </div>
        ) : (
          '-'
        )}

        {isMobile && nextReservation && (
          <div className="mt-6 flex justify-between text-xs font-semibold text-brand-1700">
            <div className="flex flex-col gap-1 uppercase">
              <span className="flex h-[26px] items-center">
                {t('messages.table')}
              </span>
              <span className="flex h-[26px] items-center">
                {t('messages.numberOfPeople')}
              </span>
              <span className="flex h-[26px] items-center">
                {t('messages.status')}
              </span>
            </div>
            <div className="flex flex-col gap-1 uppercase">
              <span className="flex h-[26px] items-center">
                {tables?.join(', ') || '-'}
              </span>
              <span className="flex h-[26px] items-center">
                {nextReservation?.countPerson}
              </span>
              <span className="text-xs text-white">
                <Badge
                  color={
                    getTableReservationColors(
                      tableReservationStatus,
                      tableStatuses,
                    ).primaryColor
                  }
                  isReservationList
                >
                  {t(`messages.reservationStates.${tableReservationStatus}`)}
                </Badge>
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default GuestDetailsVisits;

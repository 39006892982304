import { useTranslation } from 'react-i18next';
import { FC } from 'react';

import { getFlagCdnCode, getFlagCdnUrl } from '../../../../functions/functions';
import { IGuestDetailsExtraInformationProps } from './GuestDetailsExtraInformation.types';
import { RESERVATION_LANGUAGES } from '../../../../constants/constants';

const GuestDetailsExtraInformation: FC<IGuestDetailsExtraInformationProps> = ({
  language,
  notes,
  tags,
}) => {
  const { t } = useTranslation();

  const languageTranslation = language
    ? RESERVATION_LANGUAGES.find((lang) => lang.value === language)
    : RESERVATION_LANGUAGES[0];

  return (
    <>
      {/* TAGS */}
      <div className="rounded-xs border border-brand-700 p-6">
        <span className="text-lg font-semibold">{t('messages.tags')}</span>
        <div className="mt-4 flex flex-wrap gap-2">
          {tags && tags.length > 0
            ? tags.map((tag, index) => {
                return (
                  <span
                    className="rounded-xs bg-brand-300 px-3 py-1 font-semibold"
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${tag}-${index}`}
                  >
                    {tag}
                  </span>
                );
              })
            : '-'}
        </div>
      </div>

      {/* Notes */}
      <div className="flex flex-col gap-4 rounded-xs border border-brand-700 p-6">
        <span className="text-lg font-semibold">{t('messages.notes')}</span>
        <span className="text-base font-medium">{notes || '-'}</span>
      </div>

      {/* LANGUGAGE */}
      <div className="flex flex-col gap-4 rounded-xs border border-brand-700 p-6">
        <span className="text-lg font-semibold">
          {t('messages.guestsPreferredLanguage')}
        </span>
        <div className="flex gap-2.5">
          <span className="mt-1.5">
            <img
              src={getFlagCdnUrl(language)}
              width="16"
              height="12"
              alt={t('alts.countryFlagOfPickedDialingCodeAlt')}
            />
          </span>
          <span className="font-medium">{languageTranslation?.label}</span>
        </div>
      </div>
    </>
  );
};

export default GuestDetailsExtraInformation;

import { Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useEffect, useMemo, useRef, useState } from 'react';
import { groupBy } from 'lodash';
import { addDays, format, isAfter } from 'date-fns';

import PaginationSelectorCount from '../../UI/molecules/PaginationSelectorCount/PaginationSelectorCount';
import PaginationButtons from '../../UI/atoms/PaginationButtons/PaginationButtons';
import DropdownCheckboxes from '../../UI/atoms/DropdownCheckboxes/DropdownCheckboxes';
import {
  reduxStatus,
  RESERVATION_STATUSES,
  routes,
} from '../../../constants/constants';
import InputDate from '../../UI/molecules/InputDate/InputDate';
import SearchBar from '../../UI/molecules/SearchBar/SearchBar';
import ReservationsTabs from '../../UI/molecules/ReservationsTabs/ReservationsTabs';
import ButtonWithIcon from '../../UI/molecules/ButtonWithIcon/ButtonWithIcon';
import PrimaryHeader from '../../UI/atoms/PrimaryHeader/PrimaryHeader';
import PageEmptyState from '../../UI/molecules/PageEmptyState/PageEmptyState';
import ConfirmModal from '../../UI/molecules/ConfirmModal/ConfirmModal';
import ReservationModal from '../../UI/organisms/ReservationModal/ReservationModal';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import useModal from '../../../hooks/useModal';
import PlusIcon from '../../../assets/icons/PlusIcon';
import useMobile from '../../../hooks/useIsMobile';
import { IOption } from '../../UI/organisms/ReservationsPanel/ReservationsPanel.types';
import { resetCancelReservationStatus } from '../../../redux/features/reservationSlice';
import {
  cancelReservationThunk,
  hasPlaceAnyReervationThunk,
  listReservationsThunk,
  listReservationsWithUnseenMessagesThunk,
} from '../../../redux/thunks/reservationThunk';
import useScrollListener from '../../../hooks/useScrollListener';
import useTablet from '../../../hooks/useIsTablet';
import FilterColumnIcon from '../../../assets/icons/FilterColumnIcon';
import {
  IListReservationsWithUnseenMessagesThunk,
  IListReservationThunk,
  TReservationsTableColumn,
} from '../../../types/Reservation';
import {
  IReservationContext,
  SortOrder,
} from '../../../types/IReservationContext';
import useDebounce from '../../../hooks/useDebounce';
import { resetTimeInUTC } from '../../../functions/functions';
import WarningModal from '../../UI/molecules/WarningModal/WarningModal';
import ArrowIcon from '../../../assets/icons/ArrowIcon';
import DropdownArrowButton from '../../UI/atoms/DropdownArrowButton/DropdownArrowButton';

const ReservationsTemplate = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { pathname } = useLocation();

  const today = new Date();

  const sevenDaysLater = new Date(today);
  sevenDaysLater.setDate(today.getDate() + 7);

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const scroll = useScrollListener(scrollContainerRef);

  const [searchInput, setSearchInput] = useState<string>('');

  const [pageLimit, setPageLimit] = useState<number>(10);

  const [currentPage, setCurrentPage] = useState<number>(0);

  const [startDate, setStartDate] = useState<Date>(today);

  const [endDate, setEndDate] = useState<Date>(sevenDaysLater);

  const [searchBarHideClass, setSearchBarHideClass] = useState<string[]>([]);

  const [isFiltersVisible, setIsFiltersVisible] = useState<boolean>(false);

  const [sortField, setSortField] =
    useState<TReservationsTableColumn['accessor']>('hour');

  const [sortOrder, setSortOrder] = useState<SortOrder>('ASC');

  const statusOptions: IOption[] = RESERVATION_STATUSES.map((status, i) => ({
    id: i,
    name: status.toLowerCase(),
    option: status,
  }));

  const [selectedStatuses, setSelectedStatuses] = useState<IOption[]>([
    statusOptions[0],
    statusOptions[1],
    statusOptions[2],
    statusOptions[3],
  ]);

  const isMobile = useMobile();

  const isTablet = useTablet();

  const {
    visible: isAddReservationModalVisible,
    showModal: showAddReservationModal,
    hideModal: hideAddReservationModal,
  } = useModal();

  const {
    visible: isEditReservationModalVisible,
    showModal: showEditReservationModal,
    hideModal: hideEditReservationModal,
  } = useModal();

  const {
    visible: isCancelReservationModalVisible,
    showModal: showCancelReservationModal,
    hideModal: hideCancelReservationModal,
  } = useModal();

  const {
    visible: smsLimitModalVisible,
    showModal: showSmsLimitModal,
    hideModal: hideSmsLimitModal,
  } = useModal();

  const user = useAppSelector((state) => state.user.user);

  const reservations = useAppSelector(
    (store) => store.reservation.reservations,
  );

  const listReservationsStatus = useAppSelector(
    (store) => store.reservation.listReservationStatus,
  );

  const listReservationsWithUnseenMessagesStatus = useAppSelector(
    (store) => store.reservation.listReservationsWithUnseenMessagesStatus,
  );

  const isChatAvailable = useAppSelector(
    (state) => state.settings.isChatAvailable,
  );

  const isSchedule = useAppSelector((state) => state.settings.isSchedule);

  const hasPlaceAnyReservation = useAppSelector(
    (state) => state.reservation.hasPlaceAnyReservation,
  );

  const hasPlaceAnyReservationStatus = useAppSelector(
    (state) => state.reservation.hasPlaceAnyReservationStatus,
  );

  const isAnyUnseenMessage = useAppSelector(
    (state) => state.reservation.isAnyMessageUnseen,
  );

  const isEmptyState =
    !hasPlaceAnyReservation && hasPlaceAnyReservationStatus !== 'loading';

  const currentReservationId = useAppSelector(
    (state) => state.reservation.currentReservationId,
  );

  const cancelReservationStatus = useAppSelector(
    (state) => state.reservation.cancelReservationStatus,
  );

  const placeId = useAppSelector((store) => store.user.user?.placeId);

  const timezone = useAppSelector((state) => state.settings.timezone);

  const columns: TReservationsTableColumn[] = useMemo(
    () => [
      {
        Header: t('messages.hour'),
        accessor: 'hour',
        columnStyles: 'min-w-[90px] max-w-[calc(100%/7)]',
        type: 'hour',
      },
      {
        Header: t('messages.clientName'),
        accessor: 'fullName',
        columnStyles: 'min-w-[148px] max-w-[calc(100%/7)]',
        type: 'text',
        color: 'white',
      },
      {
        Header: t('messages.phoneNumber'),
        accessor: 'phoneNumber',
        columnStyles: 'min-w-[148px] max-w-[calc(100%/7)]',
        type: 'text',
        color: 'text-brand-1700',
      },
      {
        Header: t('messages.table'),
        accessor: 'formattedTableNames',
        columnStyles: 'min-w-[90px] max-w-[calc(100%/7)]',
        type: 'table',
        color: 'text-brand-1700',
      },
      {
        Header: isTablet
          ? t('messages.shortNumberOfPeople')
          : t('messages.numberOfPeople'),
        accessor: 'countPerson',
        columnStyles: 'min-w-[112px] max-w-[calc(100%/7)]',
        type: 'number',
        color: 'text-brand-1700',
      },
      {
        Header: t('messages.status'),
        accessor: 'status',
        columnStyles: 'min-w-[124px] max-w-[calc(100%/7)]',
        type: 'status',
      },
      {
        Header: '',
        accessor: 'actions',
        columnStyles: 'min-w-[114px] max-w-[calc(100%/7)]',
        type: 'actions',
      },
    ],
    [t, isTablet],
  );

  const columnsMobile = useMemo(
    () => [
      {
        Header: t('messages.hour'),
        accessor: 'hour',
      },
      {
        Header: t('messages.clientName'),
        accessor: 'fullName',
      },
      {
        Header: t('messages.phoneNumber'),
        accessor: 'phoneNumber',
      },
      {
        Header: t('messages.table'),
        accessor: 'formattedTableNames',
      },
      {
        Header: t('messages.numberOfPeople'),
        accessor: 'countPerson',
      },
      {
        Header: t('messages.status'),
        accessor: 'status',
      },
    ],
    [t],
  );

  const handleTabChange = () => {
    setCurrentPage(0);
    setStartDate(today);
    setEndDate(sevenDaysLater);
    setSortField('hour');
    setSortOrder('ASC');
    setSelectedStatuses([
      statusOptions[0],
      statusOptions[1],
      statusOptions[2],
      statusOptions[3],
    ]);
  };

  const debouncedSelectedStatuses = useDebounce(selectedStatuses, 300);

  const handleStartDateChange = (date: Date) => {
    setStartDate(date);
    setCurrentPage(0);
  };

  const handleEndDateChange = (date: Date) => {
    setEndDate(date);
    setCurrentPage(0);
  };

  const handleLimitChange = (newLimit: number) => {
    setPageLimit(newLimit);
    setCurrentPage(0);
  };

  const handleDropdownChange = (newSelectedStatuses: IOption[]) => {
    if (newSelectedStatuses.length === 0) {
      setSelectedStatuses([statusOptions[1]]);
    } else {
      setSelectedStatuses(newSelectedStatuses);
    }
    setCurrentPage(0);
  };

  const handleCancelReservation = () => {
    if (!currentReservationId) return;

    const containsIsCancelInFilter = selectedStatuses.some((status) =>
      status.name.includes(RESERVATION_STATUSES[4]),
    );

    dispatch(
      cancelReservationThunk({
        id: currentReservationId,
        containsIsCancelInFilter,
      }),
    );
  };

  const handleCloseReservationModal = () => {
    hideCancelReservationModal();
    dispatch(resetCancelReservationStatus());
  };

  const handleSearch = (query: string) => {
    if (query !== searchInput) {
      setSearchInput(query);
      setCurrentPage(0);
    }
  };

  const groupedReservations = groupBy(reservations.items, (res) =>
    new Date(res.dateStart).toLocaleDateString(),
  );

  const getEmptyStateTitle = () => {
    // if (place?.openingHours === undefined && specialDaysCount === 0) {
    //   return t('messages.noOpeningHoursReservationsEmptyStateTitle');
    // }
    // TODO uncomment when adapting space plan
    // if (isSpacePlansEmpty(spacePlans) || !place?.isSpacePlanPublished) {
    //   return t('messages.noSpacePlanReservationsEmptyStateTitle');
    // }
    return t('messages.youHaveNotAnyReservationsYet');
  };

  const getEmptyStateDescription = () => {
    // if (place?.openingHours === undefined && specialDaysCount === 0) {
    //   return t('messages.noOpeningHoursReservationsEmptyStateDescription');
    // }
    // TODO uncomment when adapting space plan and schedule
    // if (isSpacePlansEmpty(spacePlans) || !place?.isSpacePlanPublished) {
    //   return t('messages.noSpacePlanReservationsEmptyStateDescription');
    // }

    // if (place.schedule.length === 0) {
    //   return t('messages.noSchedulePublishedEmptyStateDescription');
    // }
    return t('messages.waitUntilReservationsWillComeFromClients');
  };

  const getSortFieldFromAccessor = (
    field: TReservationsTableColumn['accessor'],
  ) => {
    switch (field) {
      case 'fullName':
        return 'firstName';
      case 'formattedTableNames':
        return 'table';
      case 'phoneNumber':
        return 'phoneNumber';
      case 'countPerson':
        return 'countPerson';
      case 'status':
        return 'status';
      case 'hour':
        return 'dateStart';
      default:
        return 'createdAt';
    }
  };

  const handleSortChange = (field: TReservationsTableColumn['accessor']) => {
    if (!timezone || !placeId) return;

    let newOrder: 'ASC' | 'DESC' = 'ASC';

    if (sortField === field) {
      newOrder = sortOrder === 'ASC' ? 'DESC' : 'ASC';
      setSortOrder(newOrder);
    } else {
      setSortField(field);
      setSortOrder('ASC');
      newOrder = 'ASC';
    }

    if (pathname === routes.reservationsUnseen) {
      const listReservationsWithUnseenMessagesInput: IListReservationsWithUnseenMessagesThunk =
        {
          placeId,
          searchInput,
          limit: pageLimit,
          offset: pageLimit * currentPage,
          timezone,
          sortBy: getSortFieldFromAccessor(field),
          order: newOrder,
        };
      dispatch(
        listReservationsWithUnseenMessagesThunk(
          listReservationsWithUnseenMessagesInput,
        ),
      );
    } else {
      const listReservationsInput: IListReservationThunk = {
        placeId,
        searchInput,
        limit: pageLimit,
        offset: pageLimit * currentPage,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        timezone,
        sortBy: getSortFieldFromAccessor(field),
        order: newOrder,
        filters: selectedStatuses.map((status) => status.option),
      };

      dispatch(listReservationsThunk(listReservationsInput));
    }
  };

  const formattedStartDate = resetTimeInUTC(new Date(startDate)).toISOString();
  const formattedEndDate = resetTimeInUTC(
    new Date(endDate),
    23,
    59,
    59,
  ).toISOString();

  const outletContext = {
    groupedReservations,
    columns,
    columnsMobile,
    currentPage,
    pageLimit,
    searchInput,
    startDate: formattedStartDate,
    endDate: formattedEndDate,
    selectedStatuses: debouncedSelectedStatuses,
    showEditReservationModal,
    showCancelReservationModal,
    showSmsLimitModal,
  } satisfies IReservationContext;

  const handleNextPage = () => {
    if (!user?.placeId || !timezone) return;
    const newPageNumber = currentPage + 1;
    if (pathname === routes.reservations) {
      dispatch(
        listReservationsThunk({
          placeId: user.placeId,
          searchInput,
          limit: pageLimit,
          offset: pageLimit * newPageNumber,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          sortBy: getSortFieldFromAccessor(sortField),
          order: sortOrder,
          timezone,
          filters: debouncedSelectedStatuses.map((status) => status.option),
        }),
      );
    } else if (pathname === routes.reservationsUnseen) {
      dispatch(
        listReservationsWithUnseenMessagesThunk({
          placeId: user.placeId,
          limit: pageLimit,
          offset: pageLimit * newPageNumber,
          sortBy: getSortFieldFromAccessor(sortField),
          order: sortOrder,
          timezone,
        }),
      );
    }
    setCurrentPage(newPageNumber);
  };

  const handlePrevPage = () => {
    if (!user?.placeId || !timezone) return;
    const newPageNumber = currentPage - 1;
    if (pathname === routes.reservations) {
      dispatch(
        listReservationsThunk({
          placeId: user.placeId,
          searchInput,
          limit: pageLimit,
          offset: pageLimit * newPageNumber,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          sortBy: getSortFieldFromAccessor(sortField),
          order: sortOrder,
          timezone,
          filters: debouncedSelectedStatuses.map((status) => status.option),
        }),
      );
    } else if (pathname === routes.reservationsUnseen) {
      dispatch(
        listReservationsWithUnseenMessagesThunk({
          placeId: user.placeId,
          limit: pageLimit,
          offset: pageLimit * newPageNumber,
          sortBy: getSortFieldFromAccessor(sortField),
          order: sortOrder,
          timezone,
        }),
      );
    }
    setCurrentPage(newPageNumber);
  };

  const goToSupport = () => {
    // TODO: add support navigation
  };

  // This checks if there are any reservations in the place
  // In the past, now or in the future
  // If there are no reservations, it will show the empty state
  useEffect(() => {
    dispatch(hasPlaceAnyReervationThunk());
  }, [dispatch]);

  useEffect(() => {
    const shouldHide = scroll.y > 99 && scroll.y - scroll.lastY > 0;

    setSearchBarHideClass((prev) => {
      const isHidden = prev.includes('searchBarHidden');
      if (shouldHide && !isHidden) {
        return ['searchBarHidden'];
      }
      if (!shouldHide && isHidden) {
        return [];
      }
      return prev;
    });
  }, [scroll.y, scroll.lastY]);

  useEffect(() => {
    if (!startDate || !endDate) return;
    if (isAfter(startDate, endDate)) {
      const oneWeekLater = addDays(startDate, 7);
      setEndDate(oneWeekLater);
    }
  }, [startDate, endDate]);

  const mobileFiltersText = `${searchInput}${searchInput && ', '} ${format(
    Number(startDate),
    'dd.MM.yy',
  )} - ${format(Number(endDate), 'dd.MM.yy')}, ${selectedStatuses
    .map((status) => t(`messages.${status.name}`))
    .join(', ')}`;

  return (
    <div className="flex h-full w-full flex-col gap-6 md:justify-between">
      <ReservationModal
        visible={isAddReservationModalVisible}
        onClose={hideAddReservationModal}
        startDateFilter={formattedStartDate}
        endDateFilter={formattedEndDate}
        statusFilters={selectedStatuses.map((status) => status.option)}
        searchInput={searchInput}
      />
      <ReservationModal
        visible={isEditReservationModalVisible}
        onClose={hideEditReservationModal}
        startDateFilter={formattedStartDate}
        endDateFilter={formattedEndDate}
        statusFilters={selectedStatuses.map((status) => status.option)}
        searchInput={searchInput}
        isEdit
      />
      <ConfirmModal
        visible={isCancelReservationModalVisible}
        onClose={handleCloseReservationModal}
        questionText={t('messages.areYouSureYouWantToCancelThisReservation')}
        confirmText={t('messages.successfullyCanceledReservation')}
        confirmAction={handleCancelReservation}
        loadingStatus={cancelReservationStatus}
      />
      <WarningModal
        visible={smsLimitModalVisible}
        type="warning"
        warningTitle={t('messages.smsUsageLimitReached')}
        warningDescription={t('messages.smsLimitDescription')}
        cancelText={t('messages.cancel')}
        confirmText={t('messages.goToSupport')}
        confirmAction={goToSupport}
        onClose={hideSmsLimitModal}
        actionStatus={null}
      />
      {isEmptyState ? (
        <>
          <PrimaryHeader text={t('messages.reservations')} />
          <div className="align-center flex h-full justify-center">
            <PageEmptyState
              headerText={getEmptyStateTitle()}
              paragraphText={getEmptyStateDescription()}
              buttonText={t('messages.addReservation')}
              onButtonClick={showAddReservationModal}
              isButtonDisabled={
                !isSchedule
                // TODO delete this false and uncomment below if space plans and settings will be provided
                // isSpacePlansEmpty(spacePlans) ||
                // !place?.isSpacePlanPublished ||
                // (place?.openingHours === undefined && specialDaysCount === 0) ||
                // place.schedule.length === 0
              }
            />
          </div>
        </>
      ) : null}
      {!isEmptyState && (
        <>
          <div className="flex items-center justify-between gap-2">
            <PrimaryHeader text={t('messages.reservations')} />
            <div className="flex items-center">
              <ButtonWithIcon
                type="button"
                text={t('messages.addReservation')}
                variant="primary"
                icon={PlusIcon}
                onClick={showAddReservationModal}
                isJustIconOnMobile
                isDisabled={
                  !isSchedule
                  // TODO delete this false and uncomment below if space plans and settings will be provided
                  // isSpacePlansEmpty(spacePlans) ||
                  // !place?.isSpacePlanPublished ||
                  // (place?.openingHours === undefined &&
                  //   specialDaysCount === 0) ||
                  // place.schedule.length === 0
                }
              />
            </div>
          </div>

          <div>
            <ReservationsTabs
              isChatAvailable={isChatAvailable}
              isAnyUnseenMessage={isAnyUnseenMessage}
              handleTabChange={handleTabChange}
            />
          </div>

          <div
            ref={scrollContainerRef}
            className="flex h-full flex-col gap-6 overflow-y-auto md:overflow-y-hidden"
          >
            {isMobile && !isFiltersVisible && (
              <div className="flex flex-1 gap-4">
                <div className="h-[42px] min-h-[42px] w-full truncate rounded-xs border border-white px-4 pb-1 pt-2">
                  {mobileFiltersText}
                </div>
                <DropdownArrowButton
                  isOpen={isFiltersVisible}
                  onClick={() => setIsFiltersVisible(!isFiltersVisible)}
                />
              </div>
            )}
            <div
              className={clsx(
                'flex flex-col gap-4 transition duration-1000 lg:flex-row',
                (isFiltersVisible && isMobile) || !isMobile ? 'flex' : 'hidden',
                isMobile && searchBarHideClass,
                'sticky top-0 z-50 bg-brand-50 pb-2 xl:pb-0',
              )}
            >
              <div
                className={clsx(
                  'flex flex-col gap-4 md:max-h-[42px] md:flex-row',
                )}
              >
                <div className="flex flex-1 items-center gap-4">
                  <SearchBar
                    onSearch={handleSearch}
                    placeholder={t('messages.searchReservations')}
                  />
                  {isMobile && isFiltersVisible && (
                    <DropdownArrowButton
                      isOpen={isFiltersVisible}
                      onClick={() => setIsFiltersVisible(!isFiltersVisible)}
                    />
                  )}
                </div>

                {pathname !== routes.reservationsUnseen &&
                pathname !== routes.reservationsNeedAction ? (
                  <div className="flex flex-wrap gap-4 xs:flex-row">
                    <InputDate
                      inputSubText={t('messages.from')}
                      name="startDate"
                      value={startDate}
                      onChange={handleStartDateChange}
                      isCollapse
                      shortFormatYear
                    />
                    <InputDate
                      inputSubText={t('messages.to')}
                      name="endDate"
                      value={endDate}
                      onChange={handleEndDateChange}
                      minDate={startDate}
                      isCollapse
                      shortFormatYear
                    />
                  </div>
                ) : null}
              </div>
              {pathname !== routes.reservationsUnseen &&
                pathname !== routes.reservationsNeedAction && (
                  <DropdownCheckboxes
                    options={statusOptions}
                    selectedOptions={selectedStatuses}
                    onChange={handleDropdownChange}
                    emptyStateText={t('messages.status')}
                    isTranslation
                  />
                )}
            </div>
            {!isMobile && (
              <div className="sticky flex w-full flex-row justify-between gap-4 border-b-[1px] border-solid border-brand-700 pb-4 font-semibold uppercase text-brand-1700 md:pb-2 md:pt-2 md:text-sm xl:text-sm">
                {columns.map((col) => {
                  return (
                    <div
                      key={col.Header}
                      onClick={() => handleSortChange(col.accessor)}
                      className={clsx(
                        'flex w-full cursor-pointer truncate',
                        col.type === 'text' && 'md:w-[110%] xl:w-full',
                        col.type === 'table' && 'md:ml-2 md:mr-2 xl:m-0',
                        col.type === 'number' && 'md:-ml-8 md:-mr-2 xl:m-0',
                        col.type === 'hour' && 'md:w-1/2 md:min-w-[90px]',
                      )}
                    >
                      <div key={col.Header} className={clsx('truncate')}>
                        {col.Header}
                      </div>
                      {col.type !== 'actions' && (
                        <div className="ml-1 cursor-pointer">
                          <FilterColumnIcon />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            )}

            <Outlet context={outletContext} />
          </div>

          <footer className="mt-auto flex justify-between border-t border-brand-700 pt-4">
            <PaginationSelectorCount
              total={reservations.total}
              pageLimit={pageLimit}
              setPageLimit={handleLimitChange}
              currentPage={currentPage}
            />
            <PaginationButtons
              isLoading={
                listReservationsStatus === reduxStatus.loading ||
                listReservationsWithUnseenMessagesStatus === reduxStatus.loading
              }
              nextPage={handleNextPage}
              prevPage={handlePrevPage}
              hasNextPage={pageLimit * (currentPage + 1) <= reservations.total}
              hasPreviousPage={currentPage > 0}
            />
          </footer>
        </>
      )}
    </div>
  );
};

export default ReservationsTemplate;
